import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_farmer($filter: farmer_filter_input) {
    get_farmer(filter: $filter) {
      id
      name
      photo
      address
      mobile
      onboard_date
      last_active_date
      email
      bank_name
      block
      pan_number
      type
      logistic_name
      total_natural_farming_area
      is_field_visit
      is_organic_certification
      certificate_name
      total_farming_area
      payment_cycle
      preferred_payment_method
      account_number
      alternate_name
      category_list {
        id
        name
      }
      town
      ifsc_code
      gst_number
      id_proof
      logistic_mode
      district {
        name
        id
      }
      state {
        name
        id
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.FarmerState = {
  result: null,
  error: null,
  loading: false,
};

export const get_farmer_request = createAsyncThunk<
  TYPES.Farmer,
  { filter?: TYPES.FarmerFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "farmer/get_farmer",
  async (params: { filter?: TYPES.FarmerFilterInput }, { extra: client }) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_farmer;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_farmer_slice = createSlice({
  name: "get_farmer",
  initialState: initial_state,
  reducers: {
    get_farmer_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_farmer_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_farmer_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_farmer_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_farmer_clear } = get_farmer_slice.actions;

export const get_farmer = createSelector(
  (state: RootState) => state.farmer.get_farmer.result,
  (state: RootState) => state.farmer.get_farmer.error,
  (state: RootState) => state.farmer.get_farmer.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_farmer_slice.reducer;
