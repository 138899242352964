import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_customer($filter: customer_filter_input) {
    get_customer(filter: $filter) {
      id
      system_user_id
      name
      mobile
      email
      gender
      wallet_amount
      due_amount
      is_cod_allowed
      type
      payment_method
      order_list {
        id
        order_reference
        ordered_datetime
        customer_payment_order {
          amount
        }
      }
      system_user {
        password
        username
        name
        id
      }
      customer_address_list {
        zone {
          id
          name
          pincode
        }
        order_list {
          id
          order_reference
          ordered_datetime
          customer_payment_order {
            amount
          }
        }
        pincode
        address_line_2
        address_line_1
        house_no
        district_id
        zone_id
        is_default
        name
        id
      }
      customer_review_list {
        follow_up_datetime
        follow_up_status
        cons
        pros
        rating
        id
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.CustomerState = {
  result: null,
  error: null,
  loading: false,
};

export const get_customer_request = createAsyncThunk<
  TYPES.Customer,
  { filter?: TYPES.CustomerFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "customer/get_customer",
  async (params: { filter?: TYPES.CustomerFilterInput }, { extra: client }) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_customer;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_customer_slice = createSlice({
  name: "get_customer",
  initialState: initial_state,
  reducers: {
    get_customer_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_customer_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_customer_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_customer_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_customer_clear } = get_customer_slice.actions;

export const get_customer = createSelector(
  (state: RootState) => state.customer.get_customer.result,
  (state: RootState) => state.customer.get_customer.error,
  (state: RootState) => state.customer.get_customer.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_customer_slice.reducer;
