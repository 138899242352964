import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_warehouse_required_quantity(
    $filter: product_stock_transfer_request_filter_input!
  ) {
    get_warehouse_required_quantity(filter: $filter) {
      items {
        product {
          id
          name
        }
        id
        to_warehouse_id
        product_id
        product_variant_id
        quantity
        product_variant_id
        product_id
        id
        product_variant {
          id
          name
          measurement_type
          quantity
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.ProductStockTransferRequestListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_warehouse_required_quantity_request = createAsyncThunk<
  TYPES.ProductStockTransferRequestList,
  { filter: TYPES.ProductStockTransferRequestFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "product_stock_transfer_request/get_warehouse_required_quantity",
  async (
    params: { filter: TYPES.ProductStockTransferRequestFilterInput },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_warehouse_required_quantity;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_warehouse_required_quantity_slice = createSlice({
  name: "get_warehouse_required_quantity",
  initialState: initial_state,
  reducers: {
    get_warehouse_required_quantity_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_warehouse_required_quantity_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        get_warehouse_required_quantity_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        get_warehouse_required_quantity_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: "Failed to fetch",
            status_code: "500",
          };
          state.loading = false;
        }
      );
  },
});

export const { get_warehouse_required_quantity_clear } =
  get_warehouse_required_quantity_slice.actions;

export const get_warehouse_required_quantity = createSelector(
  (state: RootState) =>
    state.product_stock_transfer_request.get_warehouse_required_quantity.result,
  (state: RootState) =>
    state.product_stock_transfer_request.get_warehouse_required_quantity.error,
  (state: RootState) =>
    state.product_stock_transfer_request.get_warehouse_required_quantity
      .loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_warehouse_required_quantity_slice.reducer;
