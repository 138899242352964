import { combineReducers } from '@reduxjs/toolkit';
import create_gst_reducer from './create_gst';
import delete_gst_reducer from './delete_gst';
import get_gst_reducer from './get_gst';
import get_gst_list_reducer from './get_gst_list';
import update_gst_reducer from './update_gst';

const gst_reducer = combineReducers({
  create_gst: create_gst_reducer,
  delete_gst: delete_gst_reducer,
  get_gst: get_gst_reducer,
  get_gst_list: get_gst_list_reducer,
  update_gst: update_gst_reducer,
});

export default gst_reducer;
