import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_product_stock_transfer_request_list(
    $filter: product_stock_transfer_request_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_product_stock_transfer_request_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        product {
          id
          name
          image_list
          splittable
          is_ripening
          primary_measurement_type
          secondary_measurement_type
        }
        product_variant {
          id
          name
          measurement_type
        }
        transfer_request_data
        current_stock_unusable_quantity
        current_stock_unusable_quantity_in_secondary_unit
        transferred_data
        expected_stock_quantity_in_secondary_unit
        expected_stock_quantity
        current_stock_quantity_in_secondary_unit
        current_stock_quantity
        received_unusable_quantity_in_secondary_unit
        received_unusable_quantity
        unusable_quantity_in_secondary_unit
        unusable_quantity
        received_quantity_in_secondary_unit
        received_quantity
        quantity_in_secondary_unit
        quantity
        product_variant_id
        product_id
        to_warehouse_id
        from_warehouse_id
        id
      }
      pagination {
        total_count
        page_limit
        page_number
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.ProductStockTransferRequestListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_product_stock_transfer_request_list_request = createAsyncThunk<
  TYPES.ProductStockTransferRequestList,
  {
    filter?: TYPES.ProductStockTransferRequestFilterInput;
    pagination?: TYPES.PaginationInput;
    sort?: TYPES.SortInput[];
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'product_stock_transfer_request/get_product_stock_transfer_request_list',
  async (
    params: {
      filter?: TYPES.ProductStockTransferRequestFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: client },
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_product_stock_transfer_request_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_product_stock_transfer_request_list_slice = createSlice({
  name: 'get_product_stock_transfer_request_list',
  initialState: initial_state,
  reducers: {
    get_product_stock_transfer_request_list_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        get_product_stock_transfer_request_list_request.pending,
        state => {
          state.result = null;
          state.error = null;
          state.loading = true;
        },
      )
      .addCase(
        get_product_stock_transfer_request_list_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        get_product_stock_transfer_request_list_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: 'Failed to fetch',
            status_code: '500',
          };
          state.loading = false;
        },
      );
  },
});

export const { get_product_stock_transfer_request_list_clear } =
  get_product_stock_transfer_request_list_slice.actions;

export const get_product_stock_transfer_request_list = createSelector(
  (state: RootState) =>
    state.product_stock_transfer_request.get_product_stock_transfer_request_list
      .result,
  (state: RootState) =>
    state.product_stock_transfer_request.get_product_stock_transfer_request_list
      .error,
  (state: RootState) =>
    state.product_stock_transfer_request.get_product_stock_transfer_request_list
      .loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_product_stock_transfer_request_list_slice.reducer;
