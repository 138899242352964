import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  mutation delete_customer_cart_line_item($id: String!) {
    delete_customer_cart_line_item(id: $id) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result: null,
  error: null,
  loading: false,
};

export const delete_customer_cart_line_item_request = createAsyncThunk<
  TYPES.MutationResponse,
  { id: String },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'customer_cart_line_item/delete_customer_cart_line_item',
  async (params: { id: String }, { extra: client }) => {
    try {
      const response = await client.mutate({
        mutation: GRAPHQL,
        variables: params,
      });
      return response.data.delete_customer_cart_line_item;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const delete_customer_cart_line_item_slice = createSlice({
  name: 'delete_customer_cart_line_item',
  initialState: initial_state,
  reducers: {
    delete_customer_cart_line_item_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(delete_customer_cart_line_item_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        delete_customer_cart_line_item_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        delete_customer_cart_line_item_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: 'Failed to fetch',
            status_code: '500',
          };
          state.loading = false;
        },
      );
  },
});

export const { delete_customer_cart_line_item_clear } =
  delete_customer_cart_line_item_slice.actions;

export const delete_customer_cart_line_item = createSelector(
  (state: RootState) =>
    state.customer_cart_line_item.delete_customer_cart_line_item.result,
  (state: RootState) =>
    state.customer_cart_line_item.delete_customer_cart_line_item.error,
  (state: RootState) =>
    state.customer_cart_line_item.delete_customer_cart_line_item.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default delete_customer_cart_line_item_slice.reducer;
