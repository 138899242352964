import { EMPTY_PRODUCT } from "@src/helpers/images_constants";
import {
  AppDispatch,
  Category,
  get_category,
  get_category_clear,
  get_category_list_for_select,
  get_category_list_for_select_clear,
  get_category_list_for_select_request,
  get_category_request,
  get_sub_category_list,
  get_sub_category_list_clear,
  get_sub_category_list_request,
} from "@src/redux";
import BreadCrumb from "@src/views/components/common/bread_crumb";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

type Props = {};

const CustomerCategory = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { result: categories, loading: categories_loading } = useSelector(
    get_category_list_for_select
  );
  const { result: sub_categories, loading: sub_categories_loading } =
    useSelector(get_sub_category_list);
  const { result: get_one_category } = useSelector(get_category);
  const { id } = useParams();
  const navigate = useNavigate();
  const bread_crumb = [
    {
      title: t("home"),
      path: "/",
    },
    {
      title: t("product_category"),
      path: `/categories`,
    },
    ...(get_one_category?.name
      ? [
          {
            title: get_one_category?.name,
            path: "",
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (!id) {
      dispatch(
        get_category_list_for_select_request({
          filter: { show_combo: false },
          sort: [{ field: "home_display_order", order: "asc" }],
        })
      );
      dispatch(get_sub_category_list_clear());
      dispatch(get_category_clear());
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(get_category_list_for_select_clear());
      dispatch(get_category_request({ filter: { perma_link: id || "" } }));
      dispatch(
        get_sub_category_list_request({
          filter: { category: { perma_link: id } },
        })
      );
    }
  }, [id]);

  const handle_click = (category: Category) => () => {
    if ((category.sub_category_list || []).length > 0) {
      navigate(`/categories/${category.perma_link}`);
    } else {
      if (get_one_category?.id) {
        navigate(
          `/product-category/${get_one_category?.perma_link}/${category.perma_link}`,
          {
            state: {
              is_sub_category: true,
            },
          }
        );
      } else {
        navigate(`/product-category/${category.perma_link}`);
      }
    }
  };
  const CategoryGrid = () => {
    return (
      <div className="customer-category-container">
        <div className="customer-category-grid">
          {(categories?.items || sub_categories?.items || []).map(
            (category, index) => (
              <div
                key={index}
                className="customer-category-card"
                onClick={handle_click(category)}
              >
                <img
                  src={category.image||EMPTY_PRODUCT}
                  alt={category.name}
                  className="customer-category-image"
                />
                <p className="customer-category-name">{category.name}</p>
              </div>
            )
          )}
        </div>
      </div>
    );
  };
  return (
    <div>
      <div
        className="customer-category-container"
        style={{ padding: "0 10px" }}
      >
        <BreadCrumb items={bread_crumb.filter((item) => item?.title)} />
      </div>
      <div className="customer-category-container">
        <div className="customer-category-grid">
          {(categories_loading || sub_categories_loading) &&
            [...Array(7)].map((_, index) => (
              <div
                key={index}
                className="skeleton-customer-category-card"
              ></div>
            ))}
        </div>
      </div>
      {!(categories_loading || sub_categories_loading) ? (
        <CategoryGrid />
      ) : null}
    </div>
  );
};

export default CustomerCategory;
