import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormInstance } from "antd/es/form";

interface FarmerReviewFormReviewProps {
  form: FormInstance;
}

const DriverReviewFormReview: React.FC<FarmerReviewFormReviewProps> = ({
  form,
}) => {
  const { t } = useTranslation();
  const type = Form.useWatch("type", form);

  return (
    <Form.Item
      name={type}
      label={t("review")}
      rules={[
        {
          required: true,
          message: t("error:review_is_required"),
        },
      ]}
    >
      <Input.TextArea rows={6} />
    </Form.Item>
  );
};

export default DriverReviewFormReview;
