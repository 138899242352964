import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_testimonial($filter: testimonial_filter_input) {
    get_testimonial(filter: $filter) {
      id
      customer_id
      name
      designation
      image
      title
      description
      customer {
        id
        name
        mobile
        email
      }
      error {
        message
      }
    }
  }
`;

const initial_state: TYPES.TestimonialState = {
  result: null,
  error: null,
  loading: false,
};

export const get_testimonial_request = createAsyncThunk<
  TYPES.Testimonial,
  { filter?: TYPES.TestimonialFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'testimonial/get_testimonial',
  async (
    params: { filter?: TYPES.TestimonialFilterInput },
    { extra: client },
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_testimonial;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_testimonial_slice = createSlice({
  name: 'get_testimonial',
  initialState: initial_state,
  reducers: {
    get_testimonial_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(get_testimonial_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_testimonial_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_testimonial_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { get_testimonial_clear } = get_testimonial_slice.actions;

export const get_testimonial = createSelector(
  (state: RootState) => state.testimonial.get_testimonial.result,
  (state: RootState) => state.testimonial.get_testimonial.error,
  (state: RootState) => state.testimonial.get_testimonial.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_testimonial_slice.reducer;
