import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_customer_order_list_without_pagination(
    $filter: customer_order_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_customer_order_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        id
        order_reference
        customer_id
        customer_address_id
        ordered_datetime
        delivery_date_id
        updated_datetime
        status
        received_datetime
        actual_total_cost
        final_total_cost
        delivery_remarks
        payment_link
        total_items
        total_weight
        customer_remarks
        customer {
          id
          name
          mobile
          email
        }
        customer_order_continuous_number {
          id
          customer_order_id
        }
        bulk_payment_link_log_list {
          id
          order_ref_number
          customer_order_id
          total_amount
          invoice_datetime
          link_method
        }
        order_line_item_list {
          id
          quantity
          actual_cost
          customer_order {
            total_items
            customer_address {
              id
              name
              mobile
              house_no
              address_line_1
              address_line_2
              landmark
              pincode
              building_type
              lat_lng
              zone {
                id
                name
                pincode
                zone_group_list {
                  id
                  name
                }
              }
              district {
                id
                name
              }
              state {
                id
                name
              }
            }
          }
          product {
            id
            name
            ta_name
          }
          product_variant {
            id
            name
            quantity
            measurement_type
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.CustomerOrderListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_customer_order_list_without_pagination_request =
  createAsyncThunk<
    TYPES.CustomerOrderList,
    {
      filter?: TYPES.CustomerOrderFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: ApolloClient<NormalizedCacheObject> }
  >(
    "customer_order/get_customer_order_list_without_pagination",
    async (
      params: {
        filter?: TYPES.CustomerOrderFilterInput;
        pagination?: TYPES.PaginationInput;
        sort?: TYPES.SortInput[];
      },
      { extra: client }
    ) => {
      try {
        const response = await client.query({
          query: GRAPHQL,
          variables: params,
        });
        return response.data.get_customer_order_list;
      } catch (error) {
        const err = error as { message?: String; status_code?: String };
        return Promise.reject({
          message: err.message || "An error occurred",
          status_code: err.status_code || "500",
        });
      }
    }
  );

const get_customer_order_list_without_pagination_slice = createSlice({
  name: "get_customer_order_list_without_pagination",
  initialState: initial_state,
  reducers: {
    get_customer_order_list_without_pagination_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        get_customer_order_list_without_pagination_request.pending,
        (state) => {
          state.result = null;
          state.error = null;
          state.loading = true;
        }
      )
      .addCase(
        get_customer_order_list_without_pagination_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        get_customer_order_list_without_pagination_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: "Failed to fetch",
            status_code: "500",
          };
          state.loading = false;
        }
      );
  },
});

export const { get_customer_order_list_without_pagination_clear } =
  get_customer_order_list_without_pagination_slice.actions;

export const get_customer_order_list_without_pagination = createSelector(
  (state: RootState) =>
    state.customer_order.get_customer_order_list_without_pagination.result,
  (state: RootState) =>
    state.customer_order.get_customer_order_list_without_pagination.error,
  (state: RootState) =>
    state.customer_order.get_customer_order_list_without_pagination.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_customer_order_list_without_pagination_slice.reducer;
