import React from "react";

const ShippingPolicy: React.FC = () => {
  return (
    <div className="faq-container">
      <h1 className="faq-title">Shipping Policy</h1>

      <div className="faq-section">
        <div className="faq-item">
          <ul className="faq-list">
            <li>
              We are committed to delivering your order accurately, in good
              condition and always on time that has been pre intimated. We have
              our own network of Delivery Partners and also partner with reputed
              national courier companies to ship your orders.
            </li>
            <li>
              Delivery is free for all Orders within Chennai having Order Value
              above Rs 500. However for Orders with order value less than 500
              and within Chennai, we charge a nominal delivery fee of Rs.50 .
              The company can decide on waiving off these delivery charges for
              customers who have high patronage
            </li>
            <li>
              For all Orders outside Chennai, a weight based shipping charge of
              Rs 100 per kg will be applicable.
            </li>
            <li>
              We ship your order within 48-72 hours of receiving your order. You
              can expect your order to be delivered to you within 2 to 3 days if
              you are from Tamilnadu, 4-7 working days if you are from outside
              Tamilnadu. However, this could differ based on access to your
              location. There could be delays if there are any operational
              constraints from the courier's end or during a natural calamity
              which could be beyond our control.
            </li>
            <li>
              You can track your order online once it is dispatched. If you have
              shared your email Id , then your tracking details will be e-mailed
              to you.
            </li>
            <li>
              For any queries, you can write to us at{" "}
              <a
                href="mailto:community@myharvestfarms.com"
                className="email-link"
              >
                community@myharvestfarms.com.
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ShippingPolicy;
