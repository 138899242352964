import { combineReducers } from '@reduxjs/toolkit';
import create_forgot_password_log_reducer from './create_forgot_password_log';
import reset_forgot_password_reducer from './reset_forgot_password';

const forgot_password_log_reducer = combineReducers({
  create_forgot_password_log: create_forgot_password_log_reducer,
  reset_forgot_password: reset_forgot_password_reducer,
});

export default forgot_password_log_reducer;
