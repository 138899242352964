import { combineReducers } from '@reduxjs/toolkit';
import create_courier_transport_reducer from './create_courier_transport';
import delete_courier_transport_reducer from './delete_courier_transport';
import get_courier_transport_reducer from './get_courier_transport';
import get_courier_transport_list_reducer from './get_courier_transport_list';
import update_courier_transport_reducer from './update_courier_transport';

const courier_transport_reducer = combineReducers({
  create_courier_transport: create_courier_transport_reducer,
  delete_courier_transport: delete_courier_transport_reducer,
  get_courier_transport: get_courier_transport_reducer,
  get_courier_transport_list: get_courier_transport_list_reducer,
  update_courier_transport: update_courier_transport_reducer,
});

export default courier_transport_reducer;
