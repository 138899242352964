// Logo and Profile Images
export const LOGO_WIDTH_TITLE = require("@src/assets/icons/logo.png");
export const LOGO_MY_HARVEST = require("@src/assets/icons/MHF trans logo.png");

export const VERTACE_LOGO = require("@src/assets/images/naduvan_logo.png");
export const PROFILE = require("@src/assets/images/profile_img.jpg");
export const SIDEBAR_IMAGE = require("@src/assets/images/sidebar_image.png");
export const NO_PRODUCT = require("@src/assets/icons/no_product.gif");
export const LOGO = require("@src/assets/icons/My harvest logo.png");
export const PENDING = require("@src/assets/icons/pending.png");

// Menu Icons
export const FARMER_ORDER_ICON = require("@src/assets/icons/farmer_order.png");
export const FARMER_ORDER_ACTIVE_ICON = require("@src/assets/icons/farmer_order_active.png");
export const PRODUCT_DEMAND_ACTIVE_ICON = require("@src/assets/icons/product_demand_active.png");
export const PRODUCT_DEMAND = require("@src/assets/icons/product_demand.png");

// Order Status ImagesP
export const instagram = require("@src/assets/images/instagram.png");

export const facebook = require("@src/assets/images/facebook.png");

export const whats_app = require("@src/assets/images/whats_app.png");

// Customer Home Page Images
export const customer_home_header_img = require("@src/assets/images/customer_home/home_slider_img.png");
export const customer_home_farms_img = require("@src/assets/images/customer_home/my_harvest_forms_img.png");
export const shopping_logo_home = require("@src/assets/images/customer_home/shopping_logo.png");
export const pay_home_logo = require("@src/assets/images/customer_home/pay_logo.png");
export const location_home_logo = require("@src/assets/images/customer_home/location_logo.png");
export const plantation_home_logo = require("@src/assets/images/customer_home/plant_logo.png");
export const show_now_img1 = require("@src/assets/images/customer_home/fresh_option_img1.png");
export const show_now_img2 = require("@src/assets/images/customer_home/fresh_option_img2.png");
export const show_now_img3 = require("@src/assets/images/customer_home/fresh_option_img3.png");
export const why_myharvest_img = require("@src/assets/images/customer_home/why_myharvest_img.png");
export const all_product_img = require("@src/assets/images/all_product.png");
export const SHOP_NOW_IMG1 = require("@src/assets/images/customer_home/Family_Banner.png");
export const SHOP_NOW_IMG2 = require("@src/assets/images/customer_home/Farmer_Banner.png");
export const SHOP_NOW_IMG3 = require("@src/assets/images/customer_home/Products_Banner.png");

// Footer Images
export const footer_background_image = require("@src/assets/images/image.png");

// Social Media Icons
export const WHATSAPP = require("@src/assets/icons/what's_app_logo.png");
export const FACEBOOK = require("@src/assets/icons/facebook.png");
export const INSTAGRAM = require("@src/assets/icons/instagram.png");
export const YOUTUBE = require("@src/assets/icons/youtube.png");

// Login Background
export const CUSTOMER_LOGIN_BACKGROUND = require("@src/assets/images/customer_login_background.jpg");

//Cart Images
export const empty_cart_img = require("../assets/images/empty_cart_img.png");

//Wishlist Images
export const empty_wishlist_img = require("../assets/images/empty_wishlist_img.png");

//Order Images
export const empty_order_img = require("../assets/images/empty_orders_img.png");

//Address Images
export const empty_address_img = require("../assets/images/empty_address_img.png");

// cluster
export const TruckImage = require("../assets/images/isometric-truck-icon.png");
export const OrderBoxImage = require("../assets/images/logistics.png");
export const EMPTY_PRODUCT = require("../assets/images/empty_product.jpg");

// product
export const DIRECT_FROM_FARMERS = require("../assets/images/direct_from_farmers.png");
export const CHEMICAL_FREE = require("../assets/images/chemical_free_product.png");
export const REAL_NUTRITION = require("../assets/images/real_nutrition.png");
export const SAFE_FOOD = require("../assets/images/safe_food.png");
export const HEALTHY_CHOICE = require("../assets/images/healthy_choice.png");
export const HIGH_QUALITY = require("../assets/images/quality_product.png");
export const NO_PRESERVATIVES = require("../assets/images/no_preservatives.png");
export const ORDER_PROCESSING = require("../assets/images/order_processing.gif");

// awards_and_recognition
export const awards_and_recognition = require("../assets/awards_and_recognition/Recogonisartion1.png");
export const awards_and_recognition1 = require("../assets/awards_and_recognition/Recogonisartion2.png");
export const awards_and_recognition2 = require("../assets/awards_and_recognition/Recogonisartion4.png");
export const awards_and_recognition3 = require("../assets/awards_and_recognition/Recogonisartion5.png");
export const awards_and_recognition4 = require("../assets/awards_and_recognition/we-got-featured1.png");
export const awards_and_recognition5 = require("../assets/awards_and_recognition/we-got-featured2.png");
export const awards_and_recognition6 = require("../assets/awards_and_recognition/we-got-featured3.png");
export const awards_and_recognition7 = require("../assets/awards_and_recognition/we-got-featured4.png");
export const awards_and_recognition8 = require("../assets/awards_and_recognition/we-got-featured5.png");
export const awards_and_recognition9 = require("../assets/awards_and_recognition/we-got-featured6.png");
export const awards_and_recognition10 = require("../assets/awards_and_recognition/we-got-featured7.png");

export const community_section_bg = require("../assets/images/community-section-backgroundimg.png");
export const stalin_pumpkn = require("../assets/images/stalin pumpkn.png");
export const home_our_story_image =
  "https://v-myharvest.blr1.vultrobjects.com/2025/03/a4f823fb-8de3-49fd-b6b1-bf2d2fc721c7.webp";

export const EMPTY_PRODUCT_LIST = require("../assets/gif/not-available.gif");
