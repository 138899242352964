import { get_product, get_sub_category } from "@src/redux";
import { Col, Form, Input } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface Props {
  form: any;
}

const SubcategoryPermaLink: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const { result: product } = useSelector(get_sub_category);

  useEffect(() => {
    if (product?.perma_link) {
      form.setFieldsValue({ perma_link: product.perma_link });
    }
  }, [product, form]);

  const handle_change = (event: any) => {
    const value = event.target.value.replace(/\s+/g, "-");
    form.setFieldsValue({ perma_link: value });
  };

  return (
    <Col span={24}>
      <Form.Item
        name="perma_link"
        label={t("perma_link")}
        rules={[{ required: true, message: t("please_enter_perma_link") }]}
      >
        <Input onChange={handle_change} />
      </Form.Item>
    </Col>
  );
};

export default SubcategoryPermaLink;
