import axios from "axios";
import { get_uuid } from "./functions";
import { client } from "@src/redux";
import { gql } from "@apollo/client";
import imageCompression from "browser-image-compression";
import toast from "react-hot-toast";

interface File {
  status?: "removed" | "uploading" | "done";
  url?: string;
  message?: string;
  type?: string;
  name?: string;
  size: number;
}

export const file_query = gql`
  mutation get_signed_url($data: signed_url_input!) {
    get_signed_url(data: $data) {
      url
      filename
      status
      error {
        message
        status_code
      }
    }
  }
`;
let file_upload_response: any | null = null;
export let file_list: any = [];

export const action =
  file_upload_response?.data?.get_upload_url?.url ||
  file_upload_response?.data?.get_signed_url?.url ||
  "";

export const handle_file_changed = ({ file }: { file: any }) => {
  file_list = [];
  if (file?.status === "removed") {
    file_list = [];
  } else if (file?.status === "uploading") {
    file = {
      ...file,
      status: "done",
      url:
        file_upload_response?.data?.get_upload_url?.url ||
        file_upload_response?.data?.get_signed_url?.url?.split("?")[0] ||
        file_upload_response?.data?.get_signed_url?.url,
      message: file_upload_response?.data?.get_upload_url?.message,
    };

    file_list = [file];
    return file;
  } else if (file?.status === "done") {
    file_list = [...file_list];
    return file;
  }
};

export const handle_before_upload = async (
  file: any,
  allowed_file_format: Array<
    | "image/png"
    | "image/jpg"
    | "image/jpeg"
    | "image/webp"
    | "image/avif"
    | "image/jfif"
    | "image/pdf"
  > = []
) => {
  const { name, type, size } = file;

  const allowed_types = allowed_file_format || [
    "image/pdf",
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/webp",
    "image/avif",
    "image/jfif",
  ];
  const valid_extensions = [
    ".png",
    ".jpg",
    ".jpeg",
    ".webp",
    "avif",
    "jfif",
    "pdf",
  ];

  const max_size_in_mb = 1; // Maximum allowed size in MB
  const is_valid_extension = valid_extensions.some((ext) =>
    name?.endsWith(ext)
  );
  const is_valid_type =
    allowed_types.length > 0
      ? allowed_types.includes(
          type as
            | "image/png"
            | "image/jpg"
            | "image/jpeg"
            | "image/webp"
            | "image/avif"
            | "image/jfif"
            | "image/pdf"
        )
      : false;
  const is_valid_size = size / 1024 / 1024 <= max_size_in_mb;
  let options;
  let file_upload;
  try {
    if (file.type !== "image/webp" && !file.type.includes("pdf")) {
      options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        fileType: "image/webp",
      };

      const compressedFile = await imageCompression(file, options);
      file_upload = compressedFile;
    }
  } catch (error) {
    console.error("Image conversion error:", error);
    toast.error("Image conversion to WebP failed");
    return;
  }
  if ((is_valid_extension || is_valid_type) && is_valid_size) {
    file_upload = {
      type: "document",
      filename: `${get_uuid()}.${options ? options?.fileType?.split("/")?.[1] : type?.split("/")?.[1]}`,
    };

    file_upload_response = await client.mutate({
      mutation: file_query,
      variables: { data: file_upload },
    });
  } else {
    let message = "Allow only PNG, JPG, WEBP, AVIF, JFIF and JPEG images";
    if (!is_valid_size) {
      message = "File size should not exceed 1MB";
      toast.error("File size should not exceed 1MB");
    }

    file_upload_response = {
      data: {
        get_upload_url: {
          url: get_uuid(),
          message: message,
        },
      },
    };
  }
};

export const handle_upload = ({ onSuccess, onError, file }: any) => {
  if (!file.type.includes("pdf")) {
    const newFileName = file.name.replace(/\.\w+$/, ".webp");
    let fileToUpload;
    try {
      fileToUpload = new File([file], newFileName, {
        type: "image/webp", // Assuming you want to upload as webp
      });
    } catch (error) {
      console.error("File constructor failed:", error);
      const blob = new Blob([file], { type: "image/webp" });
      fileToUpload = new Blob([blob], { type: "image/webp" });
    }

    const uploadUrl = file_list?.[0]?.url; // Ensure the correct upload URL

    axios
      .put(uploadUrl, fileToUpload, {
        headers: { "Content-Type": fileToUpload.type }, // Use the new file's type
      })
      .then((response) => {
        file.url = uploadUrl.split("?")[0];
        onSuccess(null, file);
      })
      .catch((error) => {
        onError(error);
      });
  } else if (
    file_upload_response?.data?.get_signed_url?.url &&
    file.type.includes("pdf")
  ) {
    axios
      .put(file_upload_response?.data?.get_signed_url?.url, file as any, {
        headers: { "Content-Type": file.type },
      })
      .then((response: any) => {
        file.url =
          file_upload_response?.data?.get_signed_url?.url.split("?")[0];
        onSuccess?.(null, file);
      })
      .catch((error: any) => {
        onError?.(error, file);
      });
  } else {
    onError?.(null, file_list?.[0]?.message);
  }
};
