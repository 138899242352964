import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_inventory_summary($data: inventory_summary_filter_input!) {
    get_inventory_summary(data: $data) {
      error {
        status_code
        message
      }
      items {
        po_unusable_quantity
        po_quantity_in_secondary_unit
        po_quantity
        current_stock_quantity_in_secondary_unit
        current_stock_quantity
        previous_day_closing_stock_in_secondary_unit
        previous_day_closing_stock
        product_variant_id
        product_id
        product {
          id
          name
          image_list
          is_ripening
          primary_measurement_type
          secondary_measurement_type
        }
        product_variant {
          id
          name
          measurement_type
        }
        received_unusable_quantity
        received_quantity_in_secondary_unit
        received_quantity
        unusable_quantity_in_secondary_unit
        unusable_quantity
        quantity_in_secondary_unit
        quantity
        physical_quantity_in_secondary_unit
        physical_quantity
        expiry_wastage_quantity_in_secondary_unit
        expiry_wastage_quantity
        storage_wastage_quantity_in_secondary_unit
        storage_wastage_quantity
        packing_wastage_quantity
        packing_wastage_quantity_in_secondary_unit
        customer_ordered_quantity
        opening_stock_in_secondary_unit
        opening_stock
        po_company_wastage_quantity_in_secondary_unit
        po_company_wastage_quantity
        po_farmer_wastage_quantity_in_secondary_unit
        po_farmer_wastage_quantity
        po_wastage_quantity_in_secondary_unit
        po_wastage_quantity
        po_received_unusable_quantity_in_secondary_unit
        po_received_unusable_quantity
        po_received_quantity_in_secondary_unit
        po_received_quantity
        expected_stock_unusable_quantity_in_secondary_unit
        expected_stock_unusable_quantity
        po_unusable_quantity_in_secondary_unit
        closing_stock_quantity
        closing_stock_quantity_in_secondary_unit
        customer_sold_quantity
        customer_returned_received_quantity
        customer_returned_usable_quantity
        customer_returned_usable_quantity_in_secondary_unit
        customer_returned_wastage_quantity
        customer_returned_wastage_quantity_in_secondary_unit
        pest_wastage_quantity
        pest_wastage_quantity_in_secondary_unit
      }
    }
  }
`;

const initial_state: TYPES.ProductInventorySummaryListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_inventory_summary_request = createAsyncThunk<
  TYPES.ProductInventorySummaryList,
  { data: TYPES.InventorySummaryFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "warehouse_hub/get_inventory_summary",
  async (
    params: { data: TYPES.InventorySummaryFilterInput },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_inventory_summary;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_inventory_summary_slice = createSlice({
  name: "get_inventory_summary",
  initialState: initial_state,
  reducers: {
    get_inventory_summary_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_inventory_summary_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_inventory_summary_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_inventory_summary_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_inventory_summary_clear } =
  get_inventory_summary_slice.actions;

export const get_inventory_summary = createSelector(
  (state: RootState) => state.warehouse_hub.get_inventory_summary.result,
  (state: RootState) => state.warehouse_hub.get_inventory_summary.error,
  (state: RootState) => state.warehouse_hub.get_inventory_summary.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_inventory_summary_slice.reducer;
