import React, { Suspense, memo } from "react";
import { BrowserRouter, useRoutes, RouteObject } from "react-router-dom";

import MyRoute from "@src/routes/my_route";
import RouteScrollToTop from "@src/routes/route_scroll_to_top";
import my_routes from "@src/routes/my_routes";
import NotFound from "@src/views/components/auth/not_found";
import NotAllowed from "@src/views/components/auth/not_allowed";
import Loading from "@src/views/components/general/loading";
import { Route } from "@src/redux";

const get_route = (route: Route): RouteObject => {
  if (route.children) {
    return {
      path: route.path,
      element: (
        <MyRoute name={route.name} authenticate={route.authenticate || false}>
          <Suspense fallback={<Loading />}>{route.component}</Suspense>
        </MyRoute>
      ),
      children: route.children.map(get_route),
    };
  } else {
    return {
      path: route.path,
      element: (
        <MyRoute name={route.name} authenticate={route.authenticate || false}>
          <Suspense fallback={<div />}>{route.component}</Suspense>
        </MyRoute>
      ),
    };
  }
};

const MyRouter = () => {
  const routes: RouteObject[] = [
    ...my_routes.map(get_route),
    { path: "*", element: <NotFound /> },
    { path: "not-allowed", element: <NotAllowed /> },
  ];

  return useRoutes(routes);
};

export const Router = memo(() => (
  <BrowserRouter>
    <RouteScrollToTop />
    <MyRouter />
  </BrowserRouter>
));
