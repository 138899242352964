import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_coupon($filter: coupon_filter_input) {
    get_coupon(filter: $filter) {
      id
      name
      code
      valid_from
      valid_till
      district_type
      type
      minimum_amount
      discount_type
      discount_value
      is_active
      zone_group_list {
        zone_list {
          id
        }
        id
      }
      product_list {
        id
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.CouponState = {
  result: null,
  error: null,
  loading: false,
};

export const get_coupon_request = createAsyncThunk<
  TYPES.Coupon,
  { filter?: TYPES.CouponFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "coupon/get_coupon",
  async (params: { filter?: TYPES.CouponFilterInput }, { extra: client }) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_coupon;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_coupon_slice = createSlice({
  name: "get_coupon",
  initialState: initial_state,
  reducers: {
    get_coupon_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_coupon_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_coupon_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_coupon_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_coupon_clear } = get_coupon_slice.actions;

export const get_coupon = createSelector(
  (state: RootState) => state.coupon.get_coupon.result,
  (state: RootState) => state.coupon.get_coupon.error,
  (state: RootState) => state.coupon.get_coupon.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_coupon_slice.reducer;
