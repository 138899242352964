import { combineReducers } from '@reduxjs/toolkit';
import auto_assign_picking_assignment_item_reducer from './auto_assign_picking_assignment_item';
import update_picking_assignment_item_reducer from './update_picking_assignment_item';
import update_picking_assignment_item_list_reducer from './update_picking_assignment_item_list';

const picking_assignment_item_reducer = combineReducers({
  auto_assign_picking_assignment_item:
    auto_assign_picking_assignment_item_reducer,
  update_picking_assignment_item: update_picking_assignment_item_reducer,
  update_picking_assignment_item_list:
    update_picking_assignment_item_list_reducer,
});

export default picking_assignment_item_reducer;
