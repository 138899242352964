import { combineReducers } from '@reduxjs/toolkit';
import get_customer_order_line_item_list_reducer from './get_customer_order_line_item_list';
import get_customer_order_ratings_reducer from './get_customer_order_ratings';
import get_ratings_for_customer_order_line_item_reducer from './get_ratings_for_customer_order_line_item';

const customer_order_line_item_reducer = combineReducers({
  get_customer_order_line_item_list: get_customer_order_line_item_list_reducer,
  get_customer_order_ratings: get_customer_order_ratings_reducer,
  get_ratings_for_customer_order_line_item:
    get_ratings_for_customer_order_line_item_reducer,
});

export default customer_order_line_item_reducer;
