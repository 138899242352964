import { combineReducers } from '@reduxjs/toolkit';
import create_cluster_reducer from './create_cluster';
import create_cluster_list_iterative_reducer from './create_cluster_list_iterative';
import get_cluster_reducer from './get_cluster';
import get_cluster_list_reducer from './get_cluster_list';

const cluster_reducer = combineReducers({
  create_cluster: create_cluster_reducer,
  create_cluster_list_iterative: create_cluster_list_iterative_reducer,
  get_cluster: get_cluster_reducer,
  get_cluster_list: get_cluster_list_reducer,
});

export default cluster_reducer;
