import { useCheckPageComponentAccess } from "@src/hooks";
import { Col, Row } from "antd";
import React from "react";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";

interface Review {
  type: "pros" | "cons";
  review?: any;
}

interface ReviewCardProps {
  review: Review;
  handle_edit: (review: Review) => void;
  handle_delete?: (review: Review) => void;
}

const DriverReviewCard: React.FC<ReviewCardProps> = ({
  review,
  handle_edit,
  handle_delete,
}) => (
  <Col span={12}>
    <div className="farmer_review_card_container">
      <div
        className="farmer_review_card"
        style={{
          borderColor: review.type === "pros" ? "#194e08" : "#db1919",
        }}
      >
        <div
          className="review_thumb_icon"
          style={{
            color: review.type === "pros" ? "#194e08" : "#db1919",
            borderColor: review.type === "pros" ? "#194e08" : "#db1919",
          }}
        >
          {review.type === "pros" ? (
            <FaThumbsUp size={35} />
          ) : (
            <FaThumbsDown size={35} />
          )}
        </div>
        <Row align="bottom" justify="end" gutter={10} className="icon-row">
          <Col className="icon-col">
            {useCheckPageComponentAccess("update_farmer_rating_action") && (
            <MdModeEdit
              size={18}
              onClick={() => handle_edit(review)}
              className="edit-icon"
            />
             )}
          </Col>
          {/* Uncomment the following block to enable the delete button */}
          {/* <Col className="icon-col">
            <MdDelete
              size={18}
              onClick={() => handle_delete?.(review)}
              className="delete-icon"
            />
          </Col> */}
        </Row>

        <div className="farmer-product-details">
          <h3 className="farmer-product-name">{review.type}</h3>
          <p>{review.review}</p>
        </div>
      </div>
    </div>
  </Col>
);

export default DriverReviewCard;
