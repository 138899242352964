import { combineReducers } from '@reduxjs/toolkit';
import create_farmer_product_reducer from './create_farmer_product';
import create_farmer_product_list_iterative_reducer from './create_farmer_product_list_iterative';
import delete_farmer_product_reducer from './delete_farmer_product';
import get_farmer_product_reducer from './get_farmer_product';
import get_farmer_product_list_reducer from './get_farmer_product_list';
import update_farmer_product_reducer from './update_farmer_product';
import update_farmer_product_list_iterative_reducer from './update_farmer_product_list_iterative';

const farmer_product_reducer = combineReducers({
  create_farmer_product: create_farmer_product_reducer,
  create_farmer_product_list_iterative:
    create_farmer_product_list_iterative_reducer,
  delete_farmer_product: delete_farmer_product_reducer,
  get_farmer_product: get_farmer_product_reducer,
  get_farmer_product_list: get_farmer_product_list_reducer,
  update_farmer_product: update_farmer_product_reducer,
  update_farmer_product_list_iterative:
    update_farmer_product_list_iterative_reducer,
});

export default farmer_product_reducer;
