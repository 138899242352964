import { combineReducers } from '@reduxjs/toolkit';
import create_testimonial_reducer from './create_testimonial';
import delete_testimonial_reducer from './delete_testimonial';
import get_testimonial_reducer from './get_testimonial';
import get_testimonial_list_reducer from './get_testimonial_list';
import update_testimonial_reducer from './update_testimonial';

const testimonial_reducer = combineReducers({
  create_testimonial: create_testimonial_reducer,
  delete_testimonial: delete_testimonial_reducer,
  get_testimonial: get_testimonial_reducer,
  get_testimonial_list: get_testimonial_list_reducer,
  update_testimonial: update_testimonial_reducer,
});

export default testimonial_reducer;
