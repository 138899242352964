import { combineReducers } from '@reduxjs/toolkit';
import cancel_customer_order_reducer from './cancel_customer_order';
import create_customer_order_reducer from './create_customer_order';
import create_non_delivery_date_customer_order_reducer from './create_non_delivery_date_customer_order';
import get_customer_order_reducer from './get_customer_order';
import get_customer_order_list_reducer from './get_customer_order_list';
import get_customer_order_list_excel_export_reducer from './get_customer_order_list_excel_export';
import get_customer_order_list_map_view_reducer from './get_customer_order_list_map_view';
import get_customer_order_list_without_pagination_reducer from './get_customer_order_list_without_pagination';
import get_customer_order_details_reducer from './get_customer_order_details';
import get_customer_orders_reducer from './get_customer_orders';
import get_picking_assigned_customer_order_list_reducer from './get_picking_assigned_customer_order_list';
import get_picking_un_assigned_customer_order_list_reducer from './get_picking_un_assigned_customer_order_list';
import get_zone_wise_customer_order_reducer from './get_zone_wise_customer_order';
import update_customer_order_reducer from './update_customer_order';
import update_customer_order_rating_reducer from './update_customer_order_rating';
import upsert_customer_order_reducer from './upsert_customer_order';

const customer_order_reducer = combineReducers({
  cancel_customer_order: cancel_customer_order_reducer,
  create_customer_order: create_customer_order_reducer,
  create_non_delivery_date_customer_order:
    create_non_delivery_date_customer_order_reducer,
  get_customer_order: get_customer_order_reducer,
  get_customer_order_list: get_customer_order_list_reducer,
  get_customer_order_list_excel_export:
    get_customer_order_list_excel_export_reducer,
  get_customer_order_list_map_view: get_customer_order_list_map_view_reducer,
  get_customer_order_list_without_pagination:
    get_customer_order_list_without_pagination_reducer,
  get_customer_order_details: get_customer_order_details_reducer,
  get_customer_orders: get_customer_orders_reducer,
  get_picking_assigned_customer_order_list:
    get_picking_assigned_customer_order_list_reducer,
  get_picking_un_assigned_customer_order_list:
    get_picking_un_assigned_customer_order_list_reducer,
  get_zone_wise_customer_order: get_zone_wise_customer_order_reducer,
  update_customer_order: update_customer_order_reducer,
  update_customer_order_rating: update_customer_order_rating_reducer,
  upsert_customer_order: upsert_customer_order_reducer,
});

export default customer_order_reducer;
