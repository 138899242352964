import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_sub_category_list(
    $filter: sub_category_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_sub_category_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        id
        name
        perma_link
        image
        parent_id
        perma_link
      }
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.SubCategoryListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_sub_category_list_request = createAsyncThunk<
  TYPES.SubCategoryList,
  {
    filter?: TYPES.SubCategoryFilterInput;
    pagination?: TYPES.PaginationInput;
    sort?: TYPES.SortInput[];
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "sub_category/get_sub_category_list",
  async (
    params: {
      filter?: TYPES.SubCategoryFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_sub_category_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_sub_category_list_slice = createSlice({
  name: "get_sub_category_list",
  initialState: initial_state,
  reducers: {
    get_sub_category_list_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_sub_category_list_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_sub_category_list_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_sub_category_list_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_sub_category_list_clear } =
  get_sub_category_list_slice.actions;

export const get_sub_category_list = createSelector(
  (state: RootState) => state.sub_category.get_sub_category_list.result,
  (state: RootState) => state.sub_category.get_sub_category_list.error,
  (state: RootState) => state.sub_category.get_sub_category_list.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_sub_category_list_slice.reducer;
