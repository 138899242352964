import { combineReducers } from '@reduxjs/toolkit';
import create_farmer_order_line_item_reducer from './create_farmer_order_line_item';
import delete_farmer_order_line_item_reducer from './delete_farmer_order_line_item';
import get_farmer_order_line_item_reducer from './get_farmer_order_line_item';
import get_farmer_order_line_item_list_reducer from './get_farmer_order_line_item_list';
import update_farmer_order_line_item_reducer from './update_farmer_order_line_item';
import update_farmer_order_line_item_list_reducer from './update_farmer_order_line_item_list';
import update_farmer_order_line_item_list_iterative_reducer from './update_farmer_order_line_item_list_iterative';

const farmer_order_line_item_reducer = combineReducers({
  create_farmer_order_line_item: create_farmer_order_line_item_reducer,
  delete_farmer_order_line_item: delete_farmer_order_line_item_reducer,
  get_farmer_order_line_item: get_farmer_order_line_item_reducer,
  get_farmer_order_line_item_list: get_farmer_order_line_item_list_reducer,
  update_farmer_order_line_item: update_farmer_order_line_item_reducer,
  update_farmer_order_line_item_list:
    update_farmer_order_line_item_list_reducer,
  update_farmer_order_line_item_list_iterative:
    update_farmer_order_line_item_list_iterative_reducer,
});

export default farmer_order_line_item_reducer;
