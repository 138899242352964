import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_bulk_payment_link_log_list(
    $filter: bulk_payment_link_log_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_bulk_payment_link_log_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        id
        order_ref_number
        customer_order_id
        total_amount
        invoice_datetime
        is_payment_link_sent
        link_method
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.BulkPaymentLinkLogListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_bulk_payment_link_log_list_request = createAsyncThunk<
  TYPES.BulkPaymentLinkLogList,
  {
    filter?: TYPES.BulkPaymentLinkLogFilterInput;
    pagination?: TYPES.PaginationInput;
    sort?: TYPES.SortInput[];
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'bulk_payment_link_log/get_bulk_payment_link_log_list',
  async (
    params: {
      filter?: TYPES.BulkPaymentLinkLogFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: client },
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_bulk_payment_link_log_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_bulk_payment_link_log_list_slice = createSlice({
  name: 'get_bulk_payment_link_log_list',
  initialState: initial_state,
  reducers: {
    get_bulk_payment_link_log_list_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(get_bulk_payment_link_log_list_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        get_bulk_payment_link_log_list_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        get_bulk_payment_link_log_list_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: 'Failed to fetch',
            status_code: '500',
          };
          state.loading = false;
        },
      );
  },
});

export const { get_bulk_payment_link_log_list_clear } =
  get_bulk_payment_link_log_list_slice.actions;

export const get_bulk_payment_link_log_list = createSelector(
  (state: RootState) =>
    state.bulk_payment_link_log.get_bulk_payment_link_log_list.result,
  (state: RootState) =>
    state.bulk_payment_link_log.get_bulk_payment_link_log_list.error,
  (state: RootState) =>
    state.bulk_payment_link_log.get_bulk_payment_link_log_list.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_bulk_payment_link_log_list_slice.reducer;
