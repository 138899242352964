import { combineReducers } from '@reduxjs/toolkit';
import create_customer_verification_reducer from './create_customer_verification';
import verify_register_otp_reducer from './verify_register_otp';

const customer_verification_reducer = combineReducers({
  create_customer_verification: create_customer_verification_reducer,
  verify_register_otp: verify_register_otp_reducer,
});

export default customer_verification_reducer;
