import { combineReducers } from '@reduxjs/toolkit';
import create_packing_assignment_reducer from './create_packing_assignment';
import create_packing_assignment_list_iterative_reducer from './create_packing_assignment_list_iterative';
import get_packing_assignment_list_reducer from './get_packing_assignment_list';
import update_packing_assignment_reducer from './update_packing_assignment';

const packing_assignment_reducer = combineReducers({
  create_packing_assignment: create_packing_assignment_reducer,
  create_packing_assignment_list_iterative:
    create_packing_assignment_list_iterative_reducer,
  get_packing_assignment_list: get_packing_assignment_list_reducer,
  update_packing_assignment: update_packing_assignment_reducer,
});

export default packing_assignment_reducer;
