import { combineReducers } from "@reduxjs/toolkit";
import create_delivery_date_reducer from "./create_delivery_date";
import delete_delivery_date_reducer from "./delete_delivery_date";
import get_delivery_date_reducer from "./get_delivery_date";
import get_delivery_date_list_reducer from "./get_delivery_date_list";
import get_delivery_date_list_for_select_reducer from "./get_delivery_date_list_for_select";
import get_delivery_date_by_pincode_reducer from "./get_delivery_date_by_pincode";
import get_management_date_list_reducer from "./get_management_date_list";
import get_stock_status_list_reducer from "./get_stock_status_list";
import update_delivery_date_reducer from "./update_delivery_date";

const delivery_date_reducer = combineReducers({
  create_delivery_date: create_delivery_date_reducer,
  delete_delivery_date: delete_delivery_date_reducer,
  get_delivery_date: get_delivery_date_reducer,
  get_delivery_date_list: get_delivery_date_list_reducer,
  get_delivery_date_list_for_select: get_delivery_date_list_for_select_reducer,
  get_delivery_date_by_pincode: get_delivery_date_by_pincode_reducer,
  get_management_date_list: get_management_date_list_reducer,
  get_stock_status_list: get_stock_status_list_reducer,
  update_delivery_date: update_delivery_date_reducer,
});

export default delivery_date_reducer;
