import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Modal,
  Select,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { FaEye } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  get_farmer_list,
  get_farmer_list_request,
  get_farmer_order_line_item_list_clear,
  get_farmer_order_list,
  get_farmer_order_list_request,
} from "@src/redux";
import { formatStatus } from "@src/helpers/status_constants";
import { useCheckPageComponentAccess } from "@src/hooks";
import {
  count_format,
  count_format_without_zero,
  indian_currency_format,
  status_container,
} from "@src/helpers/functions";
import PageTitle from "../common/page_title";
import SearchBox from "../common/search_box";

interface FarmerOrder {
  id: string;
  order_reference: string;
  ordered_datetime: string;
  farmer: {
    name: string;
    mobile: string;
  };
  status: string;
  grading_status: string;
}

const DriverOrderTab: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();
  const [demand_date, set_demand_date] = useState<dayjs.Dayjs | null>(null);
  const [search_value, set_search_value] = useState<string | undefined>();
  const [filtered_status, set_filtered_status] = useState<string | undefined>();
  const [filtered_farmer, set_filtered_farmer] = useState<string | undefined>();
  const [farmer_order_list, set_farmer_order_list] = useState<any | []>([]);
  const [farmer_list, set_farmer_list] = useState<any | []>([]);

  const { result: farmers_order, loading: get_farmer_order_loading } =
    useSelector(get_farmer_order_list);
  const { result: farmers } = useSelector(get_farmer_list);
  const { id } = useParams();

  const get_farmer_orders = (
    page_number: number = 1,
    page_limit: number = 10
  ) => {
    dispatch(
      get_farmer_order_list_request({
        filter: {
          order_reference: search_value,
          status: filtered_status,
          farmer: {
            id: id,
          },
        },
        pagination: {
          page_limit:
            page_limit || Number(farmers_order?.pagination?.page_limit),
          page_number:
            page_number || Number(farmers_order?.pagination?.page_number),
        },
        sort: [{ field: "ordered_datetime", order: "desc" }],
      })
    );
  };

  useEffect(() => {
    get_farmer_orders();
  }, [search_value, filtered_status, filtered_farmer]);

  useEffect(() => {
    dispatch(get_farmer_list_request({}));
    dispatch(get_farmer_order_line_item_list_clear());
  }, []);

  useEffect(() => {
    set_farmer_order_list(farmers_order?.items || []);
    if (farmers?.items) {
      set_farmer_list(farmers.items);
    }
  }, [farmers_order, farmers]);

  const handle_view = (record: FarmerOrder) => {
    navigate(`/admin/farmer-order/${record?.order_reference}/${record?.id}`);
  };

  const columns = [
    {
      title: t("cluster_id"),
      dataIndex: "cluster_id",
      key: "orderId",
    },
    {
      title: t("vehicle_no"),
      key: "date",
      dataIndex: "vehicle_no",
    },
    {
      title: t("no_of_deliveries"),
      key: "no_of_deliveries",
      dataIndex: "no_of_deliveries",
    },
    {
      title: " ",
      key: "farmerName",
      render: (record: FarmerOrder) => {
        return record?.farmer?.name || "-";
      },
    },
    {
      title: t("amount"),
      key: "amount",
      render: (record: FarmerOrder) => {
        return record?.farmer?.mobile || "-";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: FarmerOrder) => {
        if (useCheckPageComponentAccess("farmer_order_view_action")) {
          return (
            <FaEye
              className="view-icon"
              onClick={() => {
                handle_view(record);
              }}
            />
          );
        }
        return null;
      },
    },
  ];

  const handle_change = (date: any) => {
    date = date?.map((date) => dayjs(date));
    set_demand_date(date);
  };
  const on_change_status = (value: string) => {
    set_filtered_status(value);
  };
  const handle_table_change = (pagination: TablePaginationConfig) => {
    get_farmer_orders(pagination.current, pagination.pageSize);
  };

  useEffect(() => {
    if (state?.farmer_id) {
      set_filtered_farmer(state.farmer_id);
    } else {
      set_filtered_farmer(undefined);
    }
  }, [state]);

  const status = ["ordered", "not_received", "not_updated", "paid", "unpaid"];

  return (
    <div>
      <PageTitle title={"Farmer Order"} is_color={true} />

      <div className="farmer-list-filter-container">
        <div className="farmer-body-filter-container">
          <DatePicker onChange={handle_change} format={"DD-MM-YYYY"} />
          <Select
            options={status_container.farmer_order_status}
            placeholder={"Status"}
            style={{ width: "200px" }}
            onChange={on_change_status}
            allowClear
            showSearch
            filterOption={(input: any, option: any) =>
              option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
            }
          />
        </div>
        <div style={{ width: "300px" }}>
          <SearchBox set_search_value={set_search_value} />
        </div>
      </div>
      <div className="status_items">
        {status.map((item, index) => (
          <div key={index} className="status_items_individual">
            {formatStatus(item, "dot with text")}
          </div>
        ))}
      </div>

      <Table
        dataSource={farmer_order_list}
        showSorterTooltip={false}
        columns={columns}
        loading={get_farmer_order_loading}
        className="admin-table"
        onChange={handle_table_change}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
          total: farmers_order?.pagination?.total_count || 0,
          showTotal: (total: any) =>
            t("total_farmer_orders", {
              total: count_format_without_zero(total),
            }),
          style: { marginTop: "20px" },
        }}
      />
    </div>
  );
};

export default DriverOrderTab;
