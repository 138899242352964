import React, { useEffect, useState } from "react";
import { Card, Col, Row, Avatar, Typography, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  get_farmer,
  get_farmer_request,
  get_staff,
  get_staff_request,
  update_farmer,
} from "@src/redux";
import { EMPTY_PRODUCT, PROFILE } from "@src/helpers/images_constants";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCheckPageComponentAccess } from "@src/hooks";
import LabelValuePair from "../common/label_value_pair";
import { indian_currency_format } from "@src/helpers/functions";
import DriverAboutTab from "./driver_about_tab";
import DriverRatingTab from "./driver_rating_tab";
import DriverOrderTab from "./driver_order_tab";
import { startCase } from "lodash";

const { Title, Text } = Typography;

const DriverDetails: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [farmerUpdateModal, setFarmerUpdateModal] = useState<boolean>(false);

  const { result: farmer_update_status, error: farmer_update_error } =
    useSelector(update_farmer);

  const { id } = useParams();

  const driver = useSelector(get_staff);

  useEffect(() => {
    if (id) {
      get_one_driver();
    }
  }, [id]);

  useEffect(() => {
    if (farmer_update_status?.status === "success") {
      setFarmerUpdateModal(false);
      get_one_driver();
    } else if (farmer_update_error) {
      setFarmerUpdateModal(false);
      get_one_driver();
    }
  }, [farmer_update_status, farmer_update_error]);

  const get_one_driver = () => {
    dispatch(get_staff_request({ filter: { id } }));
  };

  const tab_items = [
    {
      key: "farmer_about",
      page_component_key: "driver_about_tab",
      label: t("farmer_about"),
      children: (
        <DriverAboutTab
          farmerUpdateModal={farmerUpdateModal}
          setFarmerUpdateModal={setFarmerUpdateModal}
        />
      ),
    },

    {
      key: "product_rating",
      page_component_key: "driver_rating_tab",
      label: t("Ratings"),
      children: <DriverRatingTab />,
    },
    {
      key: "farmer_order",
      page_component_key: "driver_orders_tab",
      label: t("deliveries"),
      children: <DriverOrderTab />,
    },
  ];

  const filter_tab_items = tab_items.filter((tab) =>
    useCheckPageComponentAccess(tab.page_component_key)
  );

  return (
    <div style={{ padding: "20px" }}>
      {!driver?.loading && (
        <Card>
          <Row gutter={16}>
            <Col span={3}>
              <Avatar
                style={{ marginTop: "40px" }}
                size={100}
                src={EMPTY_PRODUCT}
              />
            </Col>
            <Col span={16} style={{ padding: "10px" }}>
              <LabelValuePair
                label={t("name")}
                value={driver?.result?.name || "-"}
              />
              <LabelValuePair
                label={t("Mobile No")}
                value={driver?.result?.mobile || "-"}
              />
              <LabelValuePair
                label={t("email")}
                value={driver?.result?.email || "-"}
              />
              <LabelValuePair
                label={t("Vehicle")}
                value={startCase(driver?.result?.vehicle_list?.[0]?.name)}
              />
              <br />
            </Col>
          </Row>
        </Card>
      )}
      <Tabs
        items={filter_tab_items}
        defaultActiveKey="farmer_about"
        style={{ marginTop: "20px" }}
        className="stock-keeping-unit-tab-list"
        type="card"
      />
    </div>
  );
};

export default DriverDetails;
