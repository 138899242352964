import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Title, Paragraph } = Typography;

const TermsAndCondition: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "1200px",
        margin: "0 auto",
        fontFamily: "Montserrat",
      }}
    >
      <Title level={2} className="term_title">
        {t("terms_and_contion")}
      </Title>
      <Paragraph className="paragraph_terms">{t("paragraph1")}</Paragraph>
      <Paragraph className="paragraph_terms_title">
        {t("Introduction")}
      </Paragraph>

      <Paragraph className="paragraph_terms">{t("paragraph2")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph3")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph4")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph5")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph6")}</Paragraph>
      <Paragraph className="paragraph_terms_title">
        {t("personal_info")}
      </Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph7")}</Paragraph>
      <Paragraph className="paragraph_terms_title">{t("obligation")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph8")}</Paragraph>
      <Paragraph className="paragraph_terms_title">{t("conduct")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph9")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph10")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph11")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph12")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph13")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph14")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph15")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph16")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph17")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph18")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph19")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph20")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph21")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph22")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph23")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph24")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph25")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph26")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph27")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph28")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph29")}</Paragraph>
      <Paragraph className="paragraph_terms_title">{t("web_sites")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph30")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph31")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph32")}</Paragraph>
      <Paragraph className="paragraph_terms_title">
        {t("products_service")}
      </Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph33")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph34")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph35")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph36")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph37")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph38")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph39")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph40")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph41")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph42")}</Paragraph>
      <Paragraph className="paragraph_terms_title">
        {t("optional_tools")}
      </Paragraph>

      <Paragraph className="paragraph_terms">{t("paragraph43")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph44")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph45")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph46")}</Paragraph>

      <Paragraph className="paragraph_terms_title">{t("ommisions")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph47")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph48")}</Paragraph>
      <Paragraph className="paragraph_terms_title">
        {t("prohibited_uses")}
      </Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph49")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph50")}</Paragraph>
      <Paragraph className="paragraph_terms_title">{t("disclaimer")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph51")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph52")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph53")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph54")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph55")}</Paragraph>
      <Paragraph className="paragraph_terms_title">
        {t("indemnification")}
      </Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph56")}</Paragraph>
      <Paragraph className="paragraph_terms_title">
        {t("severability")}
      </Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph57")}</Paragraph>
      <Paragraph className="paragraph_terms_title">
        {t("termination")}
      </Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph58")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph59")}</Paragraph>
      <Paragraph className="paragraph_terms">{t("paragraph60")}</Paragraph>

      <Paragraph className="paragraph_terms_title">
        {t("entire_agreement")}
      </Paragraph>
      <Paragraph className="paragraph_terms">
        <Paragraph className="paragraph_terms">{t("paragraph61")}</Paragraph>
        <Paragraph className="paragraph_terms">{t("paragraph62")}</Paragraph>
        <Paragraph className="paragraph_terms">{t("paragraph63")}</Paragraph>
      </Paragraph>
      <Paragraph className="paragraph_terms_title">
        {t("governing_law")}
      </Paragraph>
      <Paragraph className="paragraph_terms">
        <Paragraph className="paragraph_terms">{t("paragraph64")}</Paragraph>
      </Paragraph>
      <Paragraph className="paragraph_terms_title">
        {t("changes_terms")}
      </Paragraph>
      <Paragraph className="paragraph_terms">
        <Paragraph className="paragraph_terms">{t("paragraph65")}</Paragraph>
      </Paragraph>
      <Paragraph className="paragraph_terms_title">
        {t("contact_info")}
      </Paragraph>
      <Paragraph className="paragraph_terms">
        <Paragraph className="paragraph_terms">{t("paragraph66")}</Paragraph>
      </Paragraph>
    </div>
  );
};

export default TermsAndCondition;
