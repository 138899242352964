import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_product_list_for_export(
    $filter: product_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_product_list(filter: $filter, pagination: $pagination, sort: $sort) {
      items {
        id
        name
        perma_link
        ta_name
        category_id
        is_trending
        gst_id
        code
        hsn_code
        category {
          id
          name
        }
        variant_list {
          id
          name
          price
          measurement_type
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.ProductListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_product_list_for_export_request = createAsyncThunk<
  TYPES.ProductList,
  {
    filter?: TYPES.ProductFilterInput;
    pagination?: TYPES.PaginationInput;
    sort?: TYPES.SortInput[];
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "product/get_product_list_for_export",
  async (
    params: {
      filter?: TYPES.ProductFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_product_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_product_list_for_export_slice = createSlice({
  name: "get_product_list_for_export",
  initialState: initial_state,
  reducers: {
    get_product_list_for_export_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_product_list_for_export_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        get_product_list_for_export_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        get_product_list_for_export_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: "Failed to fetch",
            status_code: "500",
          };
          state.loading = false;
        }
      );
  },
});

export const { get_product_list_for_export_clear } =
  get_product_list_for_export_slice.actions;

export const get_product_list_for_export = createSelector(
  (state: RootState) => state.product.get_product_list_for_export.result,
  (state: RootState) => state.product.get_product_list_for_export.error,
  (state: RootState) => state.product.get_product_list_for_export.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_product_list_for_export_slice.reducer;
