import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  mutation accept_product_stock_transfer_request(
    $data: [create_product_stock_transfer_request_input]!
  ) {
    accept_product_stock_transfer_request(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result: null,
  error: null,
  loading: false,
};

export const accept_product_stock_transfer_request_request = createAsyncThunk<
  TYPES.MutationResponse,
  { data: TYPES.CreateProductStockTransferRequestInput[] },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'product_stock_transfer_request/accept_product_stock_transfer_request',
  async (
    params: { data: TYPES.CreateProductStockTransferRequestInput[] },
    { extra: client },
  ) => {
    try {
      const response = await client.mutate({
        mutation: GRAPHQL,
        variables: params,
      });
      return response.data.accept_product_stock_transfer_request;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const accept_product_stock_transfer_request_slice = createSlice({
  name: 'accept_product_stock_transfer_request',
  initialState: initial_state,
  reducers: {
    accept_product_stock_transfer_request_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(accept_product_stock_transfer_request_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        accept_product_stock_transfer_request_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        accept_product_stock_transfer_request_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: 'Failed to fetch',
            status_code: '500',
          };
          state.loading = false;
        },
      );
  },
});

export const { accept_product_stock_transfer_request_clear } =
  accept_product_stock_transfer_request_slice.actions;

export const accept_product_stock_transfer_request = createSelector(
  (state: RootState) =>
    state.product_stock_transfer_request.accept_product_stock_transfer_request
      .result,
  (state: RootState) =>
    state.product_stock_transfer_request.accept_product_stock_transfer_request
      .error,
  (state: RootState) =>
    state.product_stock_transfer_request.accept_product_stock_transfer_request
      .loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default accept_product_stock_transfer_request_slice.reducer;
