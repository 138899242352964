import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import store from "./redux/store";
import App from "./App";
import "@src/styles/index.css";
import { Toaster } from "react-hot-toast";
// import '../node_modules/react-pro-sidebar/dist/scss/styles.scss';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <ConfigProvider>
      <App />
      <Toaster
        position="top-center"
        reverseOrder={false}
        // toastOptions={{
        //   style: {
        //     border: "1px solid #8EB486",
        //     padding: "10px",
        //     color: "black",
        //     backgroundColor: "#8EB486",
        //   },
        // }}
      />
    </ConfigProvider>
  </Provider>
);
