import { combineReducers } from '@reduxjs/toolkit';
import get_customer_order_payment_list_reducer from './get_customer_order_payment_list';
import get_customer_order_payment_list_without_pagination_reducer from './get_customer_order_payment_list_without_pagination';

const customer_order_payment_reducer = combineReducers({
  get_customer_order_payment_list: get_customer_order_payment_list_reducer,
  get_customer_order_payment_list_without_pagination:
    get_customer_order_payment_list_without_pagination_reducer,
});

export default customer_order_payment_reducer;
