import { combineReducers } from '@reduxjs/toolkit';
import accept_product_stock_transfer_request_reducer from './accept_product_stock_transfer_request';
import create_product_stock_transfer_request_list_iterative_reducer from './create_product_stock_transfer_request_list_iterative';
import get_product_stock_transfer_request_list_reducer from './get_product_stock_transfer_request_list';
import get_warehouse_required_quantity_reducer from './get_warehouse_required_quantity';
import get_warehouse_suggestion_required_quantity__reducer from './get_warehouse_suggestion_required_quantity_';

const product_stock_transfer_request_reducer = combineReducers({
  accept_product_stock_transfer_request:
    accept_product_stock_transfer_request_reducer,
  create_product_stock_transfer_request_list_iterative:
    create_product_stock_transfer_request_list_iterative_reducer,
  get_product_stock_transfer_request_list:
    get_product_stock_transfer_request_list_reducer,
  get_warehouse_required_quantity: get_warehouse_required_quantity_reducer,
  get_warehouse_suggestion_required_quantity_:
    get_warehouse_suggestion_required_quantity__reducer,
});

export default product_stock_transfer_request_reducer;
