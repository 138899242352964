import React from "react";

interface ModalTitleProps {
  title?: any;
  icon?: React.ReactNode;
}

const ModalTitle: React.FC<ModalTitleProps> = ({ title, icon }) => {
  return (
    <div className="modal-title">
      {icon} <h4>{title}</h4>
    </div>
  );
};

export default ModalTitle;
