import { Form, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface FarmerReviewFormTypeProps {
  action_type: String;
}

const DriverReviewFormType: React.FC<FarmerReviewFormTypeProps> = (props) => {
  const { action_type } = props;
  const { t } = useTranslation();
  const review_type = [
    {
      value: "pros",
      label: "Pros",
    },
    {
      value: "cons",
      label: "Cons",
    },
  ];

  return (
    <Form.Item
      name={"type"}
      label={t("type")}
      rules={[
        {
          required: true,
          message: t("error:type_is_required"),
        },
      ]}
      hidden={action_type === "edit"}
    >
      <Select
        options={review_type}
        showSearch={true}
        filterOption={(input: any, option: any) =>
          option?.label?.toLowerCase()?.includes(input?.toLowerCase())
        }
      />
    </Form.Item>
  );
};

export default DriverReviewFormType;
