import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_product($filter: product_filter_input) {
    get_product(filter: $filter) {
      id
      name
      ta_name
      hsn_code
      code
      price
      image_list
      category_id
      sub_category_id
      measurement_type
      minimum_stock_quantity
      total_stock_quantity
      is_trending
      gst_id
      health_benefits
      storage
      description
      farm_facts
      recipes
      perma_link
      splittable
      is_ripening
      instruction
      primary_measurement_type
      out_of_stock
      is_direct_from_farmers
      is_chemical_free
      is_real_nutrition
      is_safe_food
      is_healthy_choice
      is_high_quality
      is_no_preservatives
      primary_measurement_type
      secondary_measurement_type
      health_benefits
      storage
      farm_facts
      recipes
      threshold
      instruction
      product_stock {
        available_quantity_for_customer_order
        product_variant_id
        delivery_date_id
        delivery_date {
          date
        }
      }
      sub_category {
        name
        perma_link
        id
      }
      category {
        type
        name
        perma_link
        id
      }
      gst {
        percentage
        name
        id
      }
      variant_list {
        product {
          name
          id
        }
        price_tag
        weight
        measurement_type
        price
        product_id
        quantity
        description
        code
        name
        id
      }
      product_stock {
        delivery_date_id
        available_quantity_for_customer_order
        product_variant_id
      }
      why_choose
      discount_list {
        percentage
      }
      available_days
      combo_product_variant_list {
        product {
          name
          ta_name
          id
        }
        measurement_type
        price
        product_id
        quantity
        description
        code
        name
        id
      }
      discount_list {
        percentage
      }
      discount_percentage
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.ProductState = {
  result: null,
  error: null,
  loading: false,
};

export const get_product_request = createAsyncThunk<
  TYPES.Product,
  { filter?: TYPES.ProductFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "product/get_product",
  async (params: { filter?: TYPES.ProductFilterInput }, { extra: client }) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_product;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_product_slice = createSlice({
  name: "get_product",
  initialState: initial_state,
  reducers: {
    get_product_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_product_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_product_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_product_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_product_clear } = get_product_slice.actions;

export const get_product = createSelector(
  (state: RootState) => state.product.get_product.result,
  (state: RootState) => state.product.get_product.error,
  (state: RootState) => state.product.get_product.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_product_slice.reducer;
