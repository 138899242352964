import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query search_result_product_list(
    $filter: product_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_product_list(filter: $filter, pagination: $pagination, sort: $sort) {
      items {
        id
        name
        ta_name
        perma_link
        image_list
        measurement_type
        delivery_date_id
        warehouse_id
        splittable
        out_of_stock
        total_stock_quantity
        category {
          id
          name
          type
        }
        variant_list {
          id
          name
          quantity
          price
          measurement_type
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.ProductListState = {
  result: null,
  error: null,
  loading: false,
};

export const search_result_product_list_request = createAsyncThunk<
  TYPES.ProductList,
  {
    filter?: TYPES.ProductFilterInput;
    pagination?: TYPES.PaginationInput;
    sort?: TYPES.SortInput[];
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "product/search_result_product_list",
  async (
    params: {
      filter?: TYPES.ProductFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_product_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const search_result_product_list_slice = createSlice({
  name: "search_result_product_list",
  initialState: initial_state,
  reducers: {
    search_result_product_list_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search_result_product_list_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        search_result_product_list_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        }
      )
      .addCase(search_result_product_list_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { search_result_product_list_clear } =
  search_result_product_list_slice.actions;

export const search_result_product_list = createSelector(
  (state: RootState) => state.product.search_result_product_list.result,
  (state: RootState) => state.product.search_result_product_list.error,
  (state: RootState) => state.product.search_result_product_list.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default search_result_product_list_slice.reducer;
