import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_customer_order_payment_list_without_pagination(
    $filter: customer_order_payment_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_customer_order_payment_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        id
        customer_order_id
        amount
        payment_mode
        status
        created_datetime
        customer_order {
          order_reference
          customer {
            id
            name
            mobile
          }
        }
      }
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.CustomerOrderPaymentListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_customer_order_payment_list_without_pagination_request =
  createAsyncThunk<
    TYPES.CustomerOrderPaymentList,
    {
      filter?: TYPES.CustomerOrderPaymentFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: ApolloClient<NormalizedCacheObject> }
  >(
    'customer_order_payment/get_customer_order_payment_list_without_pagination',
    async (
      params: {
        filter?: TYPES.CustomerOrderPaymentFilterInput;
        pagination?: TYPES.PaginationInput;
        sort?: TYPES.SortInput[];
      },
      { extra: client },
    ) => {
      try {
        const response = await client.query({
          query: GRAPHQL,
          variables: params,
        });
        return response.data.get_customer_order_payment_list;
      } catch (error) {
        const err = error as { message?: String; status_code?: String };
        return Promise.reject({
          message: err.message || 'An error occurred',
          status_code: err.status_code || '500',
        });
      }
    },
  );

const get_customer_order_payment_list_without_pagination_slice = createSlice({
  name: 'get_customer_order_payment_list_without_pagination',
  initialState: initial_state,
  reducers: {
    get_customer_order_payment_list_without_pagination_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        get_customer_order_payment_list_without_pagination_request.pending,
        state => {
          state.result = null;
          state.error = null;
          state.loading = true;
        },
      )
      .addCase(
        get_customer_order_payment_list_without_pagination_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        get_customer_order_payment_list_without_pagination_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: 'Failed to fetch',
            status_code: '500',
          };
          state.loading = false;
        },
      );
  },
});

export const { get_customer_order_payment_list_without_pagination_clear } =
  get_customer_order_payment_list_without_pagination_slice.actions;

export const get_customer_order_payment_list_without_pagination =
  createSelector(
    (state: RootState) =>
      state.customer_order_payment
        .get_customer_order_payment_list_without_pagination.result,
    (state: RootState) =>
      state.customer_order_payment
        .get_customer_order_payment_list_without_pagination.error,
    (state: RootState) =>
      state.customer_order_payment
        .get_customer_order_payment_list_without_pagination.loading,
    (result, error, loading) => ({
      result,
      error,
      loading,
    }),
  );

export default get_customer_order_payment_list_without_pagination_slice.reducer;
