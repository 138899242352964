import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query check_customer_cart_line_item_list(
    $filter: customer_cart_line_item_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_customer_cart_line_item_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        id
        product_id
        product_variant_id
        quantity
        product {
          primary_measurement_type
        }
        customer_cart_id
        product_variant {
          weight
          quantity
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.CustomerCartLineItemListState = {
  result: null,
  error: null,
  loading: false,
};

export const check_customer_cart_line_item_list_request = createAsyncThunk<
  TYPES.CustomerCartLineItemList,
  {
    filter?: TYPES.CustomerCartLineItemFilterInput;
    pagination?: TYPES.PaginationInput;
    sort?: TYPES.SortInput[];
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "customer_cart_line_item/check_customer_cart_line_item_list",
  async (
    params: {
      filter?: TYPES.CustomerCartLineItemFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_customer_cart_line_item_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const check_customer_cart_line_item_list_slice = createSlice({
  name: "check_customer_cart_line_item_list",
  initialState: initial_state,
  reducers: {
    check_customer_cart_line_item_list_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(check_customer_cart_line_item_list_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        check_customer_cart_line_item_list_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        check_customer_cart_line_item_list_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: "Failed to fetch",
            status_code: "500",
          };
          state.loading = false;
        }
      );
  },
});

export const { check_customer_cart_line_item_list_clear } =
  check_customer_cart_line_item_list_slice.actions;

export const check_customer_cart_line_item_list = createSelector(
  (state: RootState) =>
    state.customer_cart_line_item.check_customer_cart_line_item_list.result,
  (state: RootState) =>
    state.customer_cart_line_item.check_customer_cart_line_item_list.error,
  (state: RootState) =>
    state.customer_cart_line_item.check_customer_cart_line_item_list.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default check_customer_cart_line_item_list_slice.reducer;
