import React from "react";

const RefundAndCancellation: React.FC = () => {
  return (
    <div className="faq-container">
      <h1 className="faq-title">Refund & Cancellation Policy</h1>

      <div className="faq-section">
        <div className="faq-item">
          <p className="faq-answer">
            You as a customer can cancel your order anytime up to the cut-off
            time for the day for which you have placed an order by calling or
            messaging our customer success team. In such a case we will refund
            any payments already made by you for the order.
          </p>
          <p className="faq-answer">
            If we suspect any fraudulent transaction by any customer or any
            transaction which defies the terms & conditions of using the
            website, we at our sole discretion could cancel such orders.
          </p>
          <p className="faq-answer">
            We will maintain a negative list of all fraudulent transactions and
            customers and would deny access to them or cancel any orders placed
            by them.
          </p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">Refund / Return Policy</h3>
          <ul className="faq-list">
            <li>
              If you have any issue(s) with the product(s) delivered, you can
              contact our Customer Success team for a proper resolution.
            </li>
            <li>
              Complaints related to perishables (Fruits & Vegetables) can be
              raised upto 48 Hours from time of delivery with proper image or
              video. We might issue a refund for the item(s) or replace them in
              the next 48 hours.
            </li>
            <li>
              For Groceries, Sweets, Snacks and other non perishables, Issue(s)
              should be reported within 48 Hours from time of delivery with
              proper image. We might issue a refund for the item(s) or replace
              them in the next 48 hours.
            </li>
            <li>
              If any item is missing from your order during delivery, It should
              be reported within 24 Hours from time of delivery. Any issues
              reported after 24 hours from time of delivery will not be
              entertained.
            </li>
            <li>
              Please note that mere submission of issue or complaint does not
              guarantee a replacement or refund. The issues raised by you will
              be thoroughly discussed and verified with the image provided by
              you as evidence and a solution will be provided as per the terms
              mentioned in this Refund & Cancellation Policy
            </li>
            <li>
              All refunds for cancelled order, returned items or quality
              concerns will be processed back to the source account only. In
              case of COD payments, you are requested to send the details of
              your bank account via email to community@myharvestfarms.com for
              processing the refund. Refunds will not be provided as Cash for
              any reason. In other cases, from our registered official number,
              we will communicate and explore if UPI can be an alternate mode
              for refunds.
            </li>
            <li>
              We may also process a refund as credits to your account which can
              be used later for subsequent purchases in the site.
            </li>
            <li>
              Products that are sold as Packs or Combos cannot be returned as
              individual items.
            </li>
            <li>
              While returning a product that was part of an offer or promotional
              campaign, you agree to forego the benefits of the offer or
              promotional campaign.
            </li>
            <li>
              While making a purchase at myHarvest Farms through online or
              offline mode, you agree to all the above mentioned terms.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RefundAndCancellation;
