import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  # Write your query or mutation here
  query get_packing_suggestion_list(
    $filter: packing_suggestion_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_packing_suggestion_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        id
        product_id
        product_variant_id
        delivery_date_id
        warehouse_id
        quantity
        remarks
        is_assigned
        product {
          id
          name
          image_list
        }
        product_variant {
          id
          name
          code
        }
        delivery_date {
          id
          date
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.PackingSuggestionListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_packing_suggestion_list_request = createAsyncThunk<
  TYPES.PackingSuggestionList,
  {
    filter?: TYPES.PackingSuggestionFilterInput;
    pagination?: TYPES.PaginationInput;
    sort?: TYPES.SortInput[];
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "packing_suggestion/get_packing_suggestion_list",
  async (
    params: {
      filter?: TYPES.PackingSuggestionFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_packing_suggestion_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_packing_suggestion_list_slice = createSlice({
  name: "get_packing_suggestion_list",
  initialState: initial_state,
  reducers: {
    get_packing_suggestion_list_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_packing_suggestion_list_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        get_packing_suggestion_list_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        get_packing_suggestion_list_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: "Failed to fetch",
            status_code: "500",
          };
          state.loading = false;
        }
      );
  },
});

export const { get_packing_suggestion_list_clear } =
  get_packing_suggestion_list_slice.actions;

export const get_packing_suggestion_list = createSelector(
  (state: RootState) =>
    state.packing_suggestion.get_packing_suggestion_list.result,
  (state: RootState) =>
    state.packing_suggestion.get_packing_suggestion_list.error,
  (state: RootState) =>
    state.packing_suggestion.get_packing_suggestion_list.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_packing_suggestion_list_slice.reducer;
