import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_customer_order_list_map_view(
    $filter: customer_order_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_customer_order_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        id
        order_reference
        customer_id
        customer {
          name
        }
        customer_address {
          name
          mobile
          lat_lng
        }
      }
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.CustomerOrderListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_customer_order_list_map_view_request = createAsyncThunk<
  TYPES.CustomerOrderList,
  {
    filter?: TYPES.CustomerOrderFilterInput;
    pagination?: TYPES.PaginationInput;
    sort?: TYPES.SortInput[];
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "customer_order/get_customer_order_list_map_view",
  async (
    params: {
      filter?: TYPES.CustomerOrderFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_customer_order_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_customer_order_list_map_view_slice = createSlice({
  name: "get_customer_order_list_map_view",
  initialState: initial_state,
  reducers: {
    get_customer_order_list_map_view_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_customer_order_list_map_view_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        get_customer_order_list_map_view_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        get_customer_order_list_map_view_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: "Failed to fetch",
            status_code: "500",
          };
          state.loading = false;
        }
      );
  },
});

export const { get_customer_order_list_map_view_clear } =
  get_customer_order_list_map_view_slice.actions;

export const get_customer_order_list_map_view = createSelector(
  (state: RootState) =>
    state.customer_order.get_customer_order_list_map_view.result,
  (state: RootState) =>
    state.customer_order.get_customer_order_list_map_view.error,
  (state: RootState) =>
    state.customer_order.get_customer_order_list_map_view.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_customer_order_list_map_view_slice.reducer;
