import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_farmer_order($filter: farmer_order_filter_input) {
    get_farmer_order(filter: $filter) {
      id
      order_reference
      farmer_id
      delivery_date_id
      ordered_datetime
      estimated_delivery_datetime
      status
      received_datetime
      actual_total_cost
      final_total_cost
      delivery_remarks
      rating
      mode_of_transport
      delivery_location
      delivery_time
      delivery_date {
        id
        date
      }
      farmer_order_continuous_number {
        farmer_order_id
        id
      }
      receipt_url
      paid_amount
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.FarmerOrderState = {
  result: null,
  error: null,
  loading: false,
};

export const get_farmer_order_request = createAsyncThunk<
  TYPES.FarmerOrder,
  { filter?: TYPES.FarmerOrderFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "farmer_order/get_farmer_order",
  async (
    params: { filter?: TYPES.FarmerOrderFilterInput },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_farmer_order;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_farmer_order_slice = createSlice({
  name: "get_farmer_order",
  initialState: initial_state,
  reducers: {
    get_farmer_order_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_farmer_order_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_farmer_order_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_farmer_order_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_farmer_order_clear } = get_farmer_order_slice.actions;

export const get_farmer_order = createSelector(
  (state: RootState) => state.farmer_order.get_farmer_order.result,
  (state: RootState) => state.farmer_order.get_farmer_order.error,
  (state: RootState) => state.farmer_order.get_farmer_order.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_farmer_order_slice.reducer;
