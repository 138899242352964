import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  mutation update_packing_suggestion(
    $id: String!
    $data: update_packing_suggestion_input!
  ) {
    update_packing_suggestion(id: $id, data: $data) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result: null,
  error: null,
  loading: false,
};

export const update_packing_suggestion_request = createAsyncThunk<
  TYPES.MutationResponse,
  { id: String; data: TYPES.UpdatePackingSuggestionInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'packing_suggestion/update_packing_suggestion',
  async (
    params: { id: String; data: TYPES.UpdatePackingSuggestionInput },
    { extra: client },
  ) => {
    try {
      const response = await client.mutate({
        mutation: GRAPHQL,
        variables: params,
      });
      return response.data.update_packing_suggestion;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const update_packing_suggestion_slice = createSlice({
  name: 'update_packing_suggestion',
  initialState: initial_state,
  reducers: {
    update_packing_suggestion_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(update_packing_suggestion_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(update_packing_suggestion_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(update_packing_suggestion_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { update_packing_suggestion_clear } =
  update_packing_suggestion_slice.actions;

export const update_packing_suggestion = createSelector(
  (state: RootState) =>
    state.packing_suggestion.update_packing_suggestion.result,
  (state: RootState) =>
    state.packing_suggestion.update_packing_suggestion.error,
  (state: RootState) =>
    state.packing_suggestion.update_packing_suggestion.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default update_packing_suggestion_slice.reducer;
