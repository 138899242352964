import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_farmer_order_line_item(
    $filter: farmer_order_line_item_filter_input
  ) {
    get_farmer_order_line_item(filter: $filter) {
      id
      farmer_order_id
      product_id
      product_variant_id
      farmer_product_id
      quantity
      additional_quantity
      received_quantity
      wastage_quantity
      billing_quantity
      updated_datetime
      status
      grading_status
      actual_cost
      final_cost
      product {
        measurement_type
        name
        id
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.FarmerOrderLineItemState = {
  result: null,
  error: null,
  loading: false,
};

export const get_farmer_order_line_item_request = createAsyncThunk<
  TYPES.FarmerOrderLineItem,
  { filter?: TYPES.FarmerOrderLineItemFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'farmer_order_line_item/get_farmer_order_line_item',
  async (
    params: { filter?: TYPES.FarmerOrderLineItemFilterInput },
    { extra: client },
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_farmer_order_line_item;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_farmer_order_line_item_slice = createSlice({
  name: 'get_farmer_order_line_item',
  initialState: initial_state,
  reducers: {
    get_farmer_order_line_item_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(get_farmer_order_line_item_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        get_farmer_order_line_item_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        },
      )
      .addCase(get_farmer_order_line_item_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { get_farmer_order_line_item_clear } =
  get_farmer_order_line_item_slice.actions;

export const get_farmer_order_line_item = createSelector(
  (state: RootState) =>
    state.farmer_order_line_item.get_farmer_order_line_item.result,
  (state: RootState) =>
    state.farmer_order_line_item.get_farmer_order_line_item.error,
  (state: RootState) =>
    state.farmer_order_line_item.get_farmer_order_line_item.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_farmer_order_line_item_slice.reducer;
