import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  mutation update_testimonial($id: String!, $data: update_testimonial_input!) {
    update_testimonial(id: $id, data: $data) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result: null,
  error: null,
  loading: false,
};

export const update_testimonial_request = createAsyncThunk<
  TYPES.MutationResponse,
  { id: String; data: TYPES.UpdateTestimonialInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'testimonial/update_testimonial',
  async (
    params: { id: String; data: TYPES.UpdateTestimonialInput },
    { extra: client },
  ) => {
    try {
      const response = await client.mutate({
        mutation: GRAPHQL,
        variables: params,
      });
      return response.data.update_testimonial;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const update_testimonial_slice = createSlice({
  name: 'update_testimonial',
  initialState: initial_state,
  reducers: {
    update_testimonial_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(update_testimonial_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(update_testimonial_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(update_testimonial_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { update_testimonial_clear } = update_testimonial_slice.actions;

export const update_testimonial = createSelector(
  (state: RootState) => state.testimonial.update_testimonial.result,
  (state: RootState) => state.testimonial.update_testimonial.error,
  (state: RootState) => state.testimonial.update_testimonial.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default update_testimonial_slice.reducer;
