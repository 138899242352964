import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  mutation checkout($data: checkout_input) {
    checkout(data: $data) {
      items {
        product {
          id
          name
          ta_name
          image_list
          measurement_type
        }
        product_variant {
          id
          name
          quantity
          price
        }
        weight_quantity
        initiate_payment_code
        initiate_payment_enc
        placed_quantity
        customer_order_id
        available_quantity
        product_variant_id
        product_id
        status
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.UnavailableItemListState = {
  result: null,
  error: null,
  loading: false,
};

export const checkout_request = createAsyncThunk<
  TYPES.UnavailableItemList,
  { data?: TYPES.CheckoutInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'customer_cart/checkout',
  async (params: { data?: TYPES.CheckoutInput }, { extra: client }) => {
    try {
      const response = await client.mutate({
        mutation: GRAPHQL,
        variables: params,
      });
      return response.data.checkout;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const checkout_slice = createSlice({
  name: 'checkout',
  initialState: initial_state,
  reducers: {
    checkout_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(checkout_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(checkout_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(checkout_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { checkout_clear } = checkout_slice.actions;

export const checkout = createSelector(
  (state: RootState) => state.customer_cart.checkout.result,
  (state: RootState) => state.customer_cart.checkout.error,
  (state: RootState) => state.customer_cart.checkout.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default checkout_slice.reducer;
