import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_customer_return_order($filter: customer_return_order_filter_input) {
  get_customer_return_order(filter: $filter) {
    id
    customer_order_id
    customer_order{
      order_reference
    }
    return_order_reference
    return_delivery_date_id
    status
    remarks
    customer_return_order_line_item_list {
      id
      quantity
      customer_order_line_item {
        id
        actual_cost
        final_cost
        actual_cost_without_gst
        product_variant {
          id
          name
          product {
            name
            id
            price
            image_list
          }
        }
      }
      image_url
      remarks
    }
    error {
      message
      status_code
    }
  }
}
`;

const initial_state: TYPES.CustomerReturnOrderState = {
  result: null,
  error: null,
  loading: false,
};

export const get_customer_return_order_request = createAsyncThunk<
  TYPES.CustomerReturnOrder,
  { filter?: TYPES.CustomerReturnOrderFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'customer_return_order/get_customer_return_order',
  async (
    params: { filter?: TYPES.CustomerReturnOrderFilterInput },
    { extra: client },
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_customer_return_order;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_customer_return_order_slice = createSlice({
  name: 'get_customer_return_order',
  initialState: initial_state,
  reducers: {
    get_customer_return_order_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(get_customer_return_order_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_customer_return_order_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_customer_return_order_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { get_customer_return_order_clear } =
  get_customer_return_order_slice.actions;

export const get_customer_return_order = createSelector(
  (state: RootState) =>
    state.customer_return_order.get_customer_return_order.result,
  (state: RootState) =>
    state.customer_return_order.get_customer_return_order.error,
  (state: RootState) =>
    state.customer_return_order.get_customer_return_order.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_customer_return_order_slice.reducer;
