import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_warehouse_stock($data: get_warehouse_stock_input!) {
    get_warehouse_stock_list(data: $data) {
      item {
        product_variant {
          id
          name
          quantity
          measurement_type
        }
        total_secondary_quantity
        transfer_id
        total_assigned_secondary_quantity
        to_warehouse_id
        total_assigned_quantity
        total_available_quantity
        total_quantity
        warehouse_id
        product_variant_id
        product_id
      }
      error {
        status_code
        message
      }
      detail_items {
        transfer_id
        to_warehouse_id
        product_variant {
          id
          name
          quantity
          measurement_type
        }
        total_assigned_quantity
        total_quantity
        total_secondary_quantity
        warehouse_id
        product_variant_id
        product_id
      }
    }
  }
`;

const initial_state: TYPES.GetWarehouseStockListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_warehouse_stock_request = createAsyncThunk<
  TYPES.GetWarehouseStockList,
  { data: TYPES.GetWarehouseStockInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "stock_transfer_log/get_warehouse_stock",
  async (params: { data: TYPES.GetWarehouseStockInput }, { extra: client }) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_warehouse_stock_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_warehouse_stock_slice = createSlice({
  name: "get_warehouse_stock",
  initialState: initial_state,
  reducers: {
    get_warehouse_stock_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_warehouse_stock_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_warehouse_stock_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_warehouse_stock_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_warehouse_stock_clear } = get_warehouse_stock_slice.actions;

export const get_warehouse_stock = createSelector(
  (state: RootState) => state.stock_transfer_log.get_warehouse_stock.result,
  (state: RootState) => state.stock_transfer_log.get_warehouse_stock.error,
  (state: RootState) => state.stock_transfer_log.get_warehouse_stock.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_warehouse_stock_slice.reducer;
