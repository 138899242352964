import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_closing_delivery_date_product_stock_list(
    $filter: closing_delivery_date_product_stock_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_closing_delivery_date_product_stock_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        product {
          id
          name
          image_list
          is_ripening
          primary_measurement_type
          secondary_measurement_type
          category {
            id
            name
            type
          }
        }
        product_variant {
          id
          name
          measurement_type
        }
        sku_status
        remaining_unusable_quantity
        remaining_unusable_quantity_in_secondary_unit
        unusable_quantity
        unusable_quantity_in_secondary_unit
        closing_stock_quantity_in_secondary_unit
        closing_stock_quantity
        remaining_quantity_in_secondary_unit
        remaining_quantity
        physical_quantity_in_secondary_unit
        physical_quantity
        pest_wastage_quantity
        pest_wastage_quantity_in_secondary_unit
        non_trackable_wastage_quantity_in_secondary_unit
        non_trackable_wastage_quantity
        farmer_wastage_quantity_in_secondary_unit
        farmer_wastage_quantity
        perishable_wastage_quantity_in_secondary_unit
        perishable_wastage_quantity
        packing_wastage_quantity_in_secondary_unit
        packing_wastage_quantity
        expiry_wastage_quantity_in_secondary_unit
        expiry_wastage_quantity
        storage_wastage_quantity_in_secondary_unit
        storage_wastage_quantity
        company_wastage_quantity_in_secondary_unit
        company_wastage_quantity
        warehouse_id
        product_variant_id
        id
      }
      pagination {
        total_count
        page_limit
        page_number
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.ClosingDeliveryDateProductStockListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_closing_delivery_date_product_stock_list_request =
  createAsyncThunk<
    TYPES.ClosingDeliveryDateProductStockList,
    {
      filter?: TYPES.ClosingDeliveryDateProductStockFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: ApolloClient<NormalizedCacheObject> }
  >(
    "closing_delivery_date_product_stock/get_closing_delivery_date_product_stock_list",
    async (
      params: {
        filter?: TYPES.ClosingDeliveryDateProductStockFilterInput;
        pagination?: TYPES.PaginationInput;
        sort?: TYPES.SortInput[];
      },
      { extra: client }
    ) => {
      try {
        const response = await client.query({
          query: GRAPHQL,
          variables: params,
        });
        return response.data.get_closing_delivery_date_product_stock_list;
      } catch (error) {
        const err = error as { message?: String; status_code?: String };
        return Promise.reject({
          message: err.message || "An error occurred",
          status_code: err.status_code || "500",
        });
      }
    }
  );

const get_closing_delivery_date_product_stock_list_slice = createSlice({
  name: "get_closing_delivery_date_product_stock_list",
  initialState: initial_state,
  reducers: {
    get_closing_delivery_date_product_stock_list_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        get_closing_delivery_date_product_stock_list_request.pending,
        (state) => {
          state.result = null;
          state.error = null;
          state.loading = true;
        }
      )
      .addCase(
        get_closing_delivery_date_product_stock_list_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        get_closing_delivery_date_product_stock_list_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: "Failed to fetch",
            status_code: "500",
          };
          state.loading = false;
        }
      );
  },
});

export const { get_closing_delivery_date_product_stock_list_clear } =
  get_closing_delivery_date_product_stock_list_slice.actions;

export const get_closing_delivery_date_product_stock_list = createSelector(
  (state: RootState) =>
    state.closing_delivery_date_product_stock
      .get_closing_delivery_date_product_stock_list.result,
  (state: RootState) =>
    state.closing_delivery_date_product_stock
      .get_closing_delivery_date_product_stock_list.error,
  (state: RootState) =>
    state.closing_delivery_date_product_stock
      .get_closing_delivery_date_product_stock_list.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_closing_delivery_date_product_stock_list_slice.reducer;
