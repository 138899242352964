import { combineReducers } from '@reduxjs/toolkit';
import create_product_reducer from './create_product';
import delete_product_reducer from './delete_product';
import get_product_reducer from './get_product';
import get_product_list_reducer from './get_product_list';
import get_product_list_for_export_reducer from './get_product_list_for_export';
import get_product_list_for_select_reducer from './get_product_list_for_select';
import get_trending_product_list_reducer from './get_trending_product_list';
import search_result_product_list_reducer from './search_result_product_list';
import update_product_reducer from './update_product';
import update_product_list_iterative_reducer from './update_product_list_iterative';

const product_reducer = combineReducers({
  create_product: create_product_reducer,
  delete_product: delete_product_reducer,
  get_product: get_product_reducer,
  get_product_list: get_product_list_reducer,
  get_product_list_for_export: get_product_list_for_export_reducer,
  get_product_list_for_select: get_product_list_for_select_reducer,
  get_trending_product_list: get_trending_product_list_reducer,
  search_result_product_list: search_result_product_list_reducer,
  update_product: update_product_reducer,
  update_product_list_iterative: update_product_list_iterative_reducer,
});

export default product_reducer;
