import React, { useEffect } from "react";
import { Form, Input, Select, Button, Row, Col, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  create_sub_category_request,
  get_sub_category,
  get_sub_category_request,
  update_sub_category_request,
} from "@src/redux";
import { useParams } from "react-router-dom";
import {
  action,
  handle_before_upload,
  handle_file_changed,
  handle_upload,
} from "@src/helpers/file_upload";
import { UploadOutlined } from "@ant-design/icons";
import { isArray } from "lodash";
import PermaLink from "../products/form_fields/perma_link";
import SubcategoryPermaLink from "../products/form_fields/sub_category_perma_link";

interface SubCategoryCancelProps {
  handle_cancel: () => void;
  sub_category_id?: string;
}

const SubCategoryForm: React.FC<SubCategoryCancelProps> = ({
  handle_cancel,
  sub_category_id,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const profile = Form.useWatch("image", form);

  const { result: sub_category_data } = useSelector(get_sub_category);

  useEffect(() => {
    if (sub_category_id) {
      dispatch(
        get_sub_category_request({
          filter: {
            id: sub_category_id,
          },
        })
      );
    }
  }, [sub_category_id]);

  useEffect(() => {
    if (sub_category_data && sub_category_id) {
      form.setFieldsValue({
        name: sub_category_data.name,
        image: sub_category_data.image
          ? [{ uid: "234567890-", url: sub_category_data.image, name: "view" }]
          : [],
      });
    }
  }, [sub_category_data]);

  const handleFinish = (values: any) => {
    if (!sub_category_id) {
      dispatch(
        create_sub_category_request({
          data: {
            name: values?.name,
            category_id: id,
            perma_link: values?.perma_link,
            image: values?.image?.file?.url,
          },
        })
      );
    } else {
      dispatch(
        update_sub_category_request({
          id: sub_category_id || "",
          data: {
            name: values?.name,
            perma_link: values?.perma_link,
            image: values?.image?.file?.url,
          },
        })
      );
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please enter subcategory name!" }]}
      >
        <Input placeholder="Enter subcategory name" />
      </Form.Item>

      <SubcategoryPermaLink form={form} />

      <Form.Item
        name="image"
        label="Image"
        className="return-order-upload-wrapper"
      >
        <Upload
          listType="picture-card"
          customRequest={(e: any) => handle_upload(e)}
          onChange={handle_file_changed}
          beforeUpload={(file) =>
            handle_before_upload(file, ["image/jpeg", "image/png", "image/jpg"])
          }
          fileList={
            profile?.fileList?.length > 0
              ? profile?.fileList
              : isArray(profile?.fileList)
                ? profile?.fileList
                : profile
          }
        >
          {profile?.fileList?.length > 0 || profile?.length > 0 ? null : (
            <UploadOutlined />
          )}
        </Upload>
      </Form.Item>

      <Row gutter={10} justify="end">
        <Col>
          <Button className="add-button" htmlType="submit">
            {t("submit")}
          </Button>
        </Col>
        <Col>
          <Button htmlType="reset" danger onClick={handle_cancel}>
            {t("cancel")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SubCategoryForm;
