import { AppDispatch, delete_sub_category_request } from "@src/redux";
import { Button, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

interface SubCategoryDeleteProps {
  handle_cancel: () => void;
  name?: string;
  sub_category_id: string;
}

const SubCategoryDeleteConfirmation: React.FC<SubCategoryDeleteProps> = ({
  handle_cancel,
  name,
  sub_category_id,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const handle_delete_click = () => {
    dispatch(delete_sub_category_request({ id: sub_category_id }));
  };
  return (
    <div>
      <div className="customer_delete_confirmation_modal_text">
        <p>
          {t("are_you_sure_delete")}
          {name}
          {"?"}
        </p>
      </div>
      <Row gutter={10} justify="end">
        <Col>
          <Button
            className="add-button"
            htmlType="submit"
            onClick={handle_delete_click}
          >
            {t("ok")}
          </Button>
        </Col>
        <Col>
          <Button htmlType="reset" danger onClick={handle_cancel}>
            {t("no")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SubCategoryDeleteConfirmation;
