import React, { useEffect, useState } from "react";
import { Col, Empty, Row, Spin, Form, Rate } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CommonModal from "@src/views/components/common/modal/custom_modal";
import {
  AppDispatch,
  create_farmer_review,
  create_farmer_review_clear,
  create_farmer_review_request,
  get_farmer_review,
  get_farmer_review_list,
  get_farmer_review_request,
  update_farmer_review,
  update_farmer_review_clear,
  update_farmer_review_request,
} from "@src/redux";
import { retrieve_item } from "@src/helpers/functions";
import { rating_info } from "@src/helpers/functions";
import DriverRatingForm from "./driver_rating_form";
import DriverReviewCard from "./driver_review_card";
import DriverReviewHeader from "./driver_review_header";

interface ReviewDetails {
  review?: Array<string>;
  type?: string;
}

const DriverRatingTab: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const [farmer_review_modal, set_farmer_review_modal] =
    useState<boolean>(false);
  const [action_type, set_action_type] = useState<string | null>(null);
  const [review_details, set_review_details] = useState<ReviewDetails>({});
  const [value, setValue] = useState<number>(0);

  const { result: farmer_review, loading: farmer_rating_loading } =
    useSelector(get_farmer_review);

  const {
    result: create_farmer_review_result,
    error: create_farmer_review_error,
    loading: create_farmer_review_loading,
  } = useSelector(create_farmer_review);

  const {
    result: update_farmer_review_status,
    error: update_farmer_review_error,
    loading: update_farmer_review_loading,
  } = useSelector(update_farmer_review);

  const handle_add = () => {
    form.resetFields();
    set_review_details({});
    set_action_type("add");
    set_farmer_review_modal(true);
  };

  const handle_edit = (record: any) => {
    record[record.type] = record?.review?.[0];
    set_review_details(record);
    set_action_type("edit");
    set_farmer_review_modal(true);
    form.setFieldsValue(record);
  };

  const handle_delete = (record: any) => {
    set_action_type("delete");
  };

  const handle_close = () => {
    form.resetFields();
    set_review_details({});
    set_action_type(null);
    set_farmer_review_modal(false);
  };

  const handle_change = (value: number) => {
    setValue(value);
    if (farmer_review?.id) {
      // dispatch(
      //   update_farmer_review_request({
      //     data: {
      //       rating: value,
      //       farmer_id: id,
      //       procurement_officer_id: "a1a8d0bd-7c18-4a73-baca-f75912fc8f93",
      //     },
      //   })
      // );
      dispatch(
        create_farmer_review_request({
          data: {
            id: farmer_review?.id,
            rating: value,
            farmer_id: id,
            procurement_officer_id: retrieve_item("admin_user_id"),
          },
        })
      );
    } else {
      dispatch(
        create_farmer_review_request({
          data: {
            rating: value,
            farmer_id: id,
            procurement_officer_id: retrieve_item("admin_user_id"),
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(get_farmer_review_request({ filter: { farmer_id: id } }));
  }, [id]);

  useEffect(() => {
    if (farmer_review?.rating) {
      setValue(farmer_review?.rating);
    }
  }, [farmer_review]);

  useEffect(() => {
    if (create_farmer_review_result?.status === "success") {
      dispatch(get_farmer_review_request({ filter: { farmer_id: id } }));
      dispatch(create_farmer_review_clear());
    } else if (create_farmer_review_error?.message) {
      dispatch(create_farmer_review_clear());
    } else if (update_farmer_review_status?.status === "success") {
      dispatch(get_farmer_review_request({ filter: { farmer_id: id } }));
      dispatch(update_farmer_review_clear());
    } else if (update_farmer_review_error?.message) {
      dispatch(update_farmer_review_clear());
    }
  }, [
    create_farmer_review_result,
    create_farmer_review_error,
    update_farmer_review_status,
    update_farmer_review_error,
  ]);

  return (
    <div>
      <DriverReviewHeader
        handle_add={handle_add}
        // set_action_type={set_action_type}
      />
      <Row align={"middle"} justify={"space-between"}>
        <Col span={10}>
          <Rate
            onChange={handle_change}
            value={value}
            className="review_rating"
            tooltips={rating_info as string[]}
          />
        </Col>
      </Row>
      {farmer_rating_loading && (
        <Row align={"top"} justify={"center"}>
          <Spin size="default" />
        </Row>
      )}
      {!farmer_rating_loading &&
        (farmer_review?.cons || farmer_review?.pros) && (
          <Row align={"top"} justify={"space-between"}>
            <DriverReviewCard
              review={{
                type: "pros",
                review: farmer_review?.pros,
              }}
              handle_delete={handle_delete}
              handle_edit={handle_edit}
            />
            <DriverReviewCard
              review={{
                type: "cons",
                review: farmer_review?.cons,
              }}
              handle_delete={handle_delete}
              handle_edit={handle_edit}
            />
          </Row>
        )}
      {!farmer_rating_loading &&
        !(farmer_review?.cons || farmer_review?.pros) && (
          <Row align={"top"} justify={"center"}>
            <Empty description={t("no_reviews")} />
          </Row>
        )}
      <CommonModal
        title={t("update")}
        open={farmer_review_modal}
        onCancel={handle_close}
        width={800}
      >
        <DriverRatingForm
          form={form}
          close={handle_close}
          action_type={action_type || ""}
          review_details={review_details}
        />
      </CommonModal>
    </div>
  );
};

export default DriverRatingTab;
