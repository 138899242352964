import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_supply_demand_comparison(
    $filter: supply_demand_comparison_filter_input!
  ) {
    get_supply_demand_comparison(filter: $filter) {
      items {
        product {
          id
          name
          primary_measurement_type  
          category {
            id
            type
          }
        }
        product_variant {
          id
          name
          measurement_type
        }
        product_variant_id
        data
        product_id
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.SupplyDemandComparisonListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_supply_demand_comparison_request = createAsyncThunk<
  TYPES.SupplyDemandComparisonList,
  { filter: TYPES.SupplyDemandComparisonFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "warehouse/get_supply_demand_comparison",
  async (
    params: { filter: TYPES.SupplyDemandComparisonFilterInput },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_supply_demand_comparison;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_supply_demand_comparison_slice = createSlice({
  name: "get_supply_demand_comparison",
  initialState: initial_state,
  reducers: {
    get_supply_demand_comparison_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_supply_demand_comparison_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        get_supply_demand_comparison_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        get_supply_demand_comparison_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: "Failed to fetch",
            status_code: "500",
          };
          state.loading = false;
        }
      );
  },
});

export const { get_supply_demand_comparison_clear } =
  get_supply_demand_comparison_slice.actions;

export const get_supply_demand_comparison = createSelector(
  (state: RootState) => state.warehouse.get_supply_demand_comparison.result,
  (state: RootState) => state.warehouse.get_supply_demand_comparison.error,
  (state: RootState) => state.warehouse.get_supply_demand_comparison.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_supply_demand_comparison_slice.reducer;
