import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  mutation update_zone($id: String!, $data: update_zone_input!) {
    update_zone(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result: null,
  error: null,
  loading: false,
};

export const update_zone_request = createAsyncThunk<
  TYPES.MutationResponse,
  { id: String; data: TYPES.UpdateZoneInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'zone/update_zone',
  async (
    params: { id: String; data: TYPES.UpdateZoneInput },
    { extra: client },
  ) => {
    try {
      const response = await client.mutate({
        mutation: GRAPHQL,
        variables: params,
      });
      return response.data.update_zone;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const update_zone_slice = createSlice({
  name: 'update_zone',
  initialState: initial_state,
  reducers: {
    update_zone_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(update_zone_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(update_zone_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(update_zone_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { update_zone_clear } = update_zone_slice.actions;

export const update_zone = createSelector(
  (state: RootState) => state.zone.update_zone.result,
  (state: RootState) => state.zone.update_zone.error,
  (state: RootState) => state.zone.update_zone.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default update_zone_slice.reducer;
