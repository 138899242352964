import { combineReducers } from '@reduxjs/toolkit';
import create_bulk_payment_link_log_list_iterative_reducer from './create_bulk_payment_link_log_list_iterative';
import get_aggregated_bulk_payment_link_log_list_reducer from './get_aggregated_bulk_payment_link_log_list';
import get_bulk_payment_link_log_list_reducer from './get_bulk_payment_link_log_list';

const bulk_payment_link_log_reducer = combineReducers({
  create_bulk_payment_link_log_list_iterative:
    create_bulk_payment_link_log_list_iterative_reducer,
  get_aggregated_bulk_payment_link_log_list:
    get_aggregated_bulk_payment_link_log_list_reducer,
  get_bulk_payment_link_log_list: get_bulk_payment_link_log_list_reducer,
});

export default bulk_payment_link_log_reducer;
