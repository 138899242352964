import { Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface SearchBoxProps {
  set_search_value?: (value: string) => void;
  onChange?: (value: string) => void;
}

const SearchBox: React.FC<SearchBoxProps> = ({ set_search_value, onChange }) => {
  const { t } = useTranslation();

  const handle_change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (set_search_value) set_search_value(value);
    if (onChange) onChange(value);
  };

  return (
    <Input.Search
      className="search-box-container"
      placeholder={t("search")}
      allowClear={true}
      onChange={handle_change}
      style={{ width: "100%" }}
    />
  );
};

export default SearchBox;
