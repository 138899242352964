import { combineReducers } from '@reduxjs/toolkit';
import create_sub_category_reducer from './create_sub_category';
import delete_sub_category_reducer from './delete_sub_category';
import get_sub_category_reducer from './get_sub_category';
import get_sub_category_list_reducer from './get_sub_category_list';
import get_sub_category_name_reducer from './get_sub_category_name';
import update_sub_category_reducer from './update_sub_category';

const sub_category_reducer = combineReducers({
  create_sub_category: create_sub_category_reducer,
  delete_sub_category: delete_sub_category_reducer,
  get_sub_category: get_sub_category_reducer,
  get_sub_category_list: get_sub_category_list_reducer,
  get_sub_category_name: get_sub_category_name_reducer,
  update_sub_category: update_sub_category_reducer,
});

export default sub_category_reducer;
