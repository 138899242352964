import React from "react";
import { Route } from "@src/redux";
import TermsAndCondition from "@src/views/pages/terms_and_condition";
import DriverDetails from "@src/views/components/driver/driver_details";
import SubCategory from "@src/views/pages/sub_category";
import CustomerCategory from "@src/views/pages/customer/customer_category";
import RefundAndCancellation from "@src/views/pages/refund_and_cancellation";
import ShippingPolicy from "@src/views/pages/shipping_policy";
const PackingWastage = React.lazy(
  () => import("@src/views/pages/packing_wastage")
);
const OrderDetails = React.lazy(
  () => import("@src/views/components/customer/orders/order_details")
);
const ReturnOrderDetails = React.lazy(
  () =>
    import("@src/views/components/customer/order_return/return_order_details")
);
const CustomerOrderAssignList = React.lazy(
  () =>
    import(
      "@src/views/components/customer_order_assign/customer_order_assign_list"
    )
);
const CheckIn = React.lazy(() => import("@src/views/pages/check_in"));
const CheckInOrderDetail = React.lazy(
  () => import("@src/views/pages/check_in_order_details")
);
const DeliveryDay = React.lazy(() => import("@src/views/pages/delivery_day"));
const DeliveryDayOrderDetail = React.lazy(
  () => import("@src/views/components/customer_order/customer_order_detail")
);
const Testimonials = React.lazy(() => import("@src/views/pages/testimonials"));
const CustomerOrdersMapView = React.lazy(
  () => import("@src/views/pages/customer_orders_map_view")
);
const TrendingSlots = React.lazy(
  () => import("@src/views/pages/trending_slots")
);
const CustomerOrderReturnDetails = React.lazy(
  () =>
    import(
      "@src/views/components/customer_order_return/customer_order_return_detail"
    )
);
const OrderReturnDetails = React.lazy(
  () => import("@src/views/pages/customer/order_return_details")
);
const StockKeepingUnit = React.lazy(
  () => import("@src/views/pages/stock_keeping_unit")
);
const Discounts = React.lazy(() => import("@src/views/pages/discounts"));
const Instructor = React.lazy(() => import("@src/views/pages/instructor"));
const PackingTeamMember = React.lazy(
  () => import("@src/views/pages/packing_team_member")
);
const Checker = React.lazy(() => import("@src/views/pages/checker"));
const CouponDetail = React.lazy(
  () => import("@src/views/components/coupon/coupon_detail")
);
const ForgotPassword = React.lazy(
  () => import("@src/views/pages/customer/forgot_password")
);
const AdminForgotPassword = React.lazy(
  () => import("@src/views/pages/admin_forgot_password")
);
const OrderFailed = React.lazy(() => import("@src/views/pages/order_failed"));
const PaymentFailed = React.lazy(
  () => import("@src/views/pages/payment_failed")
);
const CustomerReturns = React.lazy(
  () => import("@src/views/pages/customer/customer_returns")
);

const CustomerOrderDetails = React.lazy(
  () => import("@src/views/pages/customers_order_details")
);
const DeliveryScreen = React.lazy(
  () => import("@src/views/pages/delivery_screen")
);
const SupplyDemandComparison = React.lazy(
  () => import("@src/views/pages/supply_demand_comparison")
);
const CustomerOrderReturnList = React.lazy(
  () =>
    import(
      "@src/views/components/customer_order_return/customer_order_return_list"
    )
);
const InventorySummary = React.lazy(
  () => import("@src/views/pages/inventory_summary")
);
const Admin = React.lazy(() => import("@src/views/pages/users"));
const CourierAndTransport = React.lazy(
  () => import("@src/views/pages/courier_and_transport")
);
const ComboProduct = React.lazy(
  () => import("@src/views/pages/combo_products")
);
const ComboProductDetails = React.lazy(
  () => import("@src/views/components/combo_products/combo_product_details")
);
const WarehouseSummary = React.lazy(
  () => import("@src/views/pages/warehouse_summary")
);
const ProductGST = React.lazy(() => import("@src/views/pages/product_gst"));
const State = React.lazy(() => import("@src/views/pages/state"));
const District = React.lazy(() => import("@src/views/pages/district"));
const OrderCancelled = React.lazy(
  () => import("@src/views/pages/order_cancelled")
);
const Coupon = React.lazy(() => import("@src/views/pages/coupon"));
const AdminLayout = React.lazy(() => import("@src/views/layouts/admin_layout"));
const CustomerLayout = React.lazy(
  () => import("@src/views/layouts/customer_layout")
);
const ProfileLayout = React.lazy(
  () => import("@src/views/layouts/profile_layout")
);

const DeliveryDates = React.lazy(
  () => import("@src/views/pages/delivery_dates")
);
const FarmerOrders = React.lazy(() => import("@src/views/pages/farmer_orders"));
const Farmers = React.lazy(() => import("@src/views/pages/farmers"));
const CustomerOrders = React.lazy(
  () => import("@src/views/pages/customer/customer_orders")
);
const Wishlist = React.lazy(() => import("@src/views/pages/wishlist"));
const Home = React.lazy(() => import("@src/views/pages/home"));
const CustomerProducts = React.lazy(
  () => import("@src/views/pages/customer/customer_products")
);
const Checkout = React.lazy(() => import("@src/views/pages/checkout"));
const OrderPlaced = React.lazy(() => import("@src/views/pages/order_placed"));
const ProcessingOrder = React.lazy(
  () => import("@src/views/pages/processing_order")
);
const FarmerOrderDetails = React.lazy(
  () => import("@src/views/components/farmer_order/farmer_order_details")
);
const FarmerDetails = React.lazy(
  () => import("@src/views/components/farmer/farmer_detail")
);
const CustomerLogin = React.lazy(
  () => import("@src/views/pages/customer/customer_login")
);
const AccessRights = React.lazy(() => import("@src/views/pages/access_rights"));
const CustomerProductDetails = React.lazy(
  () => import("@src/views/pages/customer/customer_product_details")
);
const Cart = React.lazy(() => import("@src/views/pages/cart"));
const OrderList = React.lazy(
  () => import("@src/views/components/customer/orders/order_list")
);
const PersonalInformationForm = React.lazy(
  () => import("@src/views/components/customer/profile/profile")
);
const ManageAddress = React.lazy(
  () => import("@src/views/components/customer/address/manage_address")
);
const Staff = React.lazy(() => import("@src/views/pages/staff"));
const OrderTracking = React.lazy(
  () => import("@src/views/pages/order_tracking")
);
const Vehicle = React.lazy(() => import("@src/views/pages/vehicle"));
const Cluster = React.lazy(() => import("@src/views/pages/cluster"));
const TrackingDetails = React.lazy(
  () => import("@src/views/layouts/admin_layout/tracking/tracking_details")
);

const AdminCustomerOrders = React.lazy(
  () => import("@src/views/components/customer_order/customer_order_list")
);
const CustomerOrderDetail = React.lazy(
  () => import("@src/views/components/customer_order/customer_order_detail")
);
const Zone = React.lazy(() => import("@src/views/pages/zone"));
const ZoneGroupList = React.lazy(
  () => import("@src/views/pages/zone_group_list")
);
const PackingTeamDetails = React.lazy(
  () => import("@src/views/components/packing_teams/packing_team_details")
);
const AdminCategory = React.lazy(
  () => import("@src/views/pages/product_category")
);
const CustomerList = React.lazy(() => import("@src/views/pages/customer"));
const CustomerProfilePage = React.lazy(
  () => import("@src/views/components/customer_list/customer_profile")
);
const Product = React.lazy(() => import("@src/views/pages/product"));
const ProductVariant = React.lazy(
  () => import("@src/views/pages/product_variant")
);
const PackingSuggestion = React.lazy(
  () => import("@src/views/pages/packing_suggestion")
);
const ProductDetails = React.lazy(
  () => import("@src/views/components/products/product_details")
);

const AdminLogin = React.lazy(() => import("@src/views/pages/admin_login"));
const ProductDemand = React.lazy(
  () => import("@src/views/pages/product_demand")
);
const Warehouse = React.lazy(() => import("@src/views/pages/warehouses"));
const WarehouseDemand = React.lazy(
  () => import("@src/views/pages/warehouse_transfer")
);

const SearchResults = React.lazy(
  () => import("@src/views/pages/customer/search_results")
);
const PiscesConversation = React.lazy(
  () => import("@src/views/pages/pieces_conversion")
);
const TransactionHistory = React.lazy(
  () => import("@src/views/pages/transaction_history")
);
const ChangePassword = React.lazy(
  () => import("@src/views/pages/customer/change_password/change_password")
);
const PaymentLink = React.lazy(() => import("@src/views/pages/payment_link"));

const AdminChangePassword = React.lazy(
  () => import("@src/views/pages/admin-change_password")
);
const DeliveryPartner = React.lazy(
  () => import("@src/views/pages/delivery_partner")
);
const DeliveryCost = React.lazy(() => import("@src/views/pages/delivery_cost"));
const FaqPage = React.lazy(() => import("@src/views/pages/faq_page"));
const my_routes: Route[] = [
  {
    path: "admin",
    name: "Login",
    authenticate: false,
    component: <AdminLogin />,
  },
  {
    path: "terms-and-condition",
    name: "Terms and Condition",
    authenticate: false,
    component: <TermsAndCondition />,
  },
  {
    path: "admin-forgot-password",
    name: "Admin Forgot Password",
    authenticate: false,
    component: <AdminForgotPassword />,
  },
  {
    path: "admin-change-password",
    name: "Admin Change Password",
    authenticate: false,
    component: <AdminChangePassword />,
    children: [],
  },
  {
    path: "/admin",
    name: "AdminLayout",
    authenticate: false,
    component: <AdminLayout />,
    children: [
      {
        path: "stock-keeping-unit",
        name: "Stock Keeping Unit",
        authenticate: true,
        component: <StockKeepingUnit />,
        children: [],
      },
      {
        path: "inventory-summary",
        name: "Inventory Summary",
        authenticate: true,
        component: <InventorySummary />,
        children: [],
      },
      {
        path: "product-demand",
        name: "Product Demand",
        authenticate: true,
        component: <ProductDemand />,
        children: [],
      },
      {
        path: "delivery-dates",
        name: "Delivery Date",
        authenticate: true,
        component: <DeliveryDates />,
        children: [],
      },
      {
        path: "farmer-orders",
        name: "Farmer Orders",
        authenticate: true,
        component: <FarmerOrders />,
        children: [],
      },
      {
        path: "payment-link",
        name: "Payment link",
        authenticate: true,
        component: <PaymentLink />,
        children: [],
      },
      {
        path: "farmer-order/:id/:order_id",
        name: "Farmer Orders",
        authenticate: true,
        component: <FarmerOrderDetails />,
        children: [],
      },
      {
        path: "farmers",
        name: "Farmers",
        authenticate: true,
        component: <Farmers />,
        children: [],
      },
      {
        path: "farmer/:id",
        name: "Farmer Details",
        authenticate: true,
        component: <FarmerDetails />,
        children: [],
      },
      {
        path: "access-rights",
        name: "Access Rights",
        authenticate: true,
        component: <AccessRights />,
        children: [],
      },
      {
        path: "staff",
        name: "Staff",
        authenticate: true,
        component: <Staff />,
        children: [],
      },
      {
        path: "gst",
        name: "GST",
        authenticate: true,
        component: <ProductGST />,
        children: [],
      },
      {
        path: "coupons",
        name: "Coupons",
        authenticate: true,
        component: <Coupon />,
        children: [],
      },
      {
        path: "coupon/:id?",
        name: "Coupon",
        authenticate: true,
        component: <CouponDetail />,
        children: [],
      },
      {
        path: "packing-reassgin",
        name: "Packing ReAssign",
        authenticate: true,
        component: <PackingTeamDetails />,
        children: [],
      },
      {
        path: "cluster",
        name: "Cluster",
        authenticate: true,
        component: <Cluster />,
        children: [],
      },
      {
        path: "cluster-orders",
        name: "Cluster Orders",
        authenticate: true,
        component: <OrderTracking />,
        children: [],
      },
      {
        path: "cluster-order/:id",
        name: "Cluster Order",
        authenticate: true,
        component: <TrackingDetails />,
      },
      {
        path: "vehicle",
        name: "Vehicle",
        authenticate: true,
        component: <Vehicle />,
      },
      {
        path: "categories",
        name: "Categories",
        authenticate: true,
        component: <AdminCategory />,
      },

      {
        path: "sub-category/:id?",
        name: "Sub Category",
        authenticate: true,
        component: <SubCategory />,
      },

      {
        path: "customers",
        name: "Customers",
        authenticate: true,
        component: <CustomerList />,
      },
      {
        path: "customer/:id?",
        name: "Customer Details",
        authenticate: true,
        component: <CustomerProfilePage />,
      },
      {
        path: "customer-orders-details",
        name: "Customer Orders Details",
        authenticate: true,
        component: <CustomerOrderDetails />,
      },
      {
        path: "packing-suggestion",
        name: "Packing Suggestion",
        authenticate: true,
        component: <PackingSuggestion />,
      },
      {
        path: "order-assign",
        name: "Customer Order Assign",
        authenticate: true,
        component: <CustomerOrderAssignList />,
      },
      {
        path: "packing-wastage",
        name: "Packing Wastage",
        authenticate: true,
        component: <PackingWastage />,
      },
      {
        path: "check-in",
        name: "Check In",
        authenticate: true,
        component: <CheckIn />,
      },
      {
        path: "check-in/:id?",
        name: "Check In Order Detail",
        authenticate: true,
        component: <CheckInOrderDetail />,
      },
      {
        path: "delivery-day",
        name: "Delivery Day",
        authenticate: true,
        component: <DeliveryDay />,
      },
      {
        path: "delivery-day/:id?",
        name: "Delivery Day Order Detail",
        authenticate: true,
        component: <DeliveryDayOrderDetail />,
      },
      {
        path: "customer-orders",
        name: "Customer Orders",
        authenticate: true,
        component: <AdminCustomerOrders />,
        children: [],
      },

      {
        path: "customer-orders-map",
        name: "Customer Orders Map View",
        authenticate: true,
        component: <CustomerOrdersMapView />,
        children: [],
      },
      {
        path: "zone",
        name: "Zones",
        authenticate: true,
        component: <Zone />,
        children: [],
      },
      {
        path: "warehouse-summary/:id?",
        name: "Warehouse Summary",
        authenticate: true,
        component: <WarehouseSummary />,
        children: [],
      },
      {
        path: "zone-group",
        name: "Zone Group",
        authenticate: true,
        component: <ZoneGroupList />,
        children: [],
      },
      {
        path: "product-variant/:id?",
        name: "Product Variant",
        authenticate: true,
        component: <ProductVariant />,
        children: [],
      },
      {
        path: "districts",
        name: "Districts",
        authenticate: true,
        component: <District />,
        children: [],
      },
      {
        path: "products",
        name: "Products",
        authenticate: true,
        component: <Product />,
        children: [],
      },
      {
        path: "state",
        name: "State",
        authenticate: true,
        component: <State />,
        children: [],
      },
      {
        path: "product/:id?",
        name: "Products",
        authenticate: true,
        component: <ProductDetails />,
        children: [],
      },
      {
        path: "combo-product/:id?",
        name: "Combo Products",
        authenticate: true,
        component: <ComboProductDetails />,
        children: [],
      },
      {
        path: "customer-order/:id",
        name: "Customer Order Details",
        authenticate: true,
        component: <DeliveryDayOrderDetail />,
        children: [],
      },
      {
        path: "warehouse",
        name: "Warehouses",
        authenticate: true,
        component: <Warehouse />,
        children: [],
      },
      {
        path: "stock-transfer",
        name: "Stock Transfer",
        authenticate: true,
        component: <WarehouseDemand />,
        children: [],
      },
      {
        path: "pisces-conversion",
        name: "Pieces Conversion",
        authenticate: true,
        component: <PiscesConversation />,
        children: [],
      },
      {
        path: "transaction-history",
        name: "Transaction History",
        authenticate: true,
        component: <TransactionHistory />,
        children: [],
      },
      {
        path: "supply-demand-comparison",
        name: "Supply Demand Comparison",
        authenticate: true,
        component: <SupplyDemandComparison />,
        children: [],
      },
      {
        path: "customer-order-return",
        name: "Customer Order Return",
        authenticate: true,
        component: <CustomerOrderReturnList />,
        children: [],
      },
      {
        path: "customer-order-return/:id",
        name: "Customer Order Return",
        authenticate: true,
        component: <CustomerOrderReturnDetails />,
        children: [],
      },
      {
        path: "users/:role",
        name: "Admin",
        authenticate: true,
        component: <Admin />,
        children: [],
      },
      {
        path: "courier_and_transport",
        name: "Courier Transport",
        authenticate: true,
        component: <CourierAndTransport />,
        children: [],
      },
      {
        path: "combo-products",
        name: "Combo Products",
        authenticate: true,
        component: <ComboProduct />,
        children: [],
      },
      {
        path: "discounts",
        name: "Discounts",
        authenticate: true,
        component: <Discounts />,
      },
      {
        path: "instructor",
        name: "Instructor",
        authenticate: true,
        component: <Instructor />,
      },
      {
        path: "packing_team_member",
        name: "Packing Team Member",
        authenticate: true,
        component: <PackingTeamMember />,
      },
      {
        path: "checker",
        name: "Checker",
        authenticate: true,
        component: <Checker />,
      },
      {
        path: "trending-slots",
        name: "Trending Slots",
        authenticate: true,
        component: <TrendingSlots />,
      },
      {
        path: "testimonials",
        name: "Testimonials",
        authenticate: true,
        component: <Testimonials />,
        children: [],
      },
      {
        path: "driver",
        name: "Delivery Partner",
        authenticate: true,
        component: <DeliveryPartner />,
      },
      {
        path: "driver/:id",
        name: "Driver Details",
        authenticate: true,
        component: <DriverDetails />,
        children: [],
      },
      {
        path: "delivery-cost",
        name: "Delivery Cost",
        authenticate: true,
        component: <DeliveryCost />,
      },
      // {
      //   path: "delivery-cost/:id?",
      //   name: "Delivery Cost Details",
      //   authenticate: true,
      //   component: <DeliveryCostDetails />,
      // },
    ],
  },
  {
    path: "order-failed",
    name: "Order Failed",
    authenticate: false,
    component: <OrderFailed />,
    children: [],
  },
  {
    path: "order-placed/:order_reference_no?",
    name: "Order Placed",
    authenticate: false,
    component: <OrderPlaced />,
    children: [],
  },
  {
    path: "payment-failed",
    name: "Payement failed",
    authenticate: false,
    component: <PaymentFailed />,
    children: [],
  },
  {
    path: "/",
    name: "CustomerLayout",
    authenticate: false,
    component: <CustomerLayout />,
    children: [
      {
        path: "profile",
        name: "ProfileLayout",
        authenticate: false,
        component: <ProfileLayout />,
        children: [
          {
            path: "information",
            name: "Profile",
            authenticate: false,
            component: <PersonalInformationForm />,
            children: [],
          },
          {
            path: "orders",
            name: "Order",
            authenticate: false,
            component: <CustomerOrders />,
            children: [],
          },
          {
            path: "orders_details/:id?",
            name: "Order Details",
            authenticate: false,
            component: <OrderDetails />,
            children: [],
          },
          {
            path: "return_orders_details/:id?",
            name: "Return Order Details",
            authenticate: false,
            component: <ReturnOrderDetails />,
            children: [],
          },
          {
            path: "returns",
            name: "Returns",
            authenticate: false,
            component: <CustomerReturns />,
            children: [],
          },
          {
            path: "order-return-details/:order_id??",
            name: "Order Return Details",
            authenticate: false,
            component: <OrderReturnDetails />,
            children: [],
          },
          {
            path: "wishlist",
            name: "Wishlist",
            authenticate: false,
            component: <Wishlist />,
            children: [],
          },
          {
            path: "address",
            name: "Address",
            authenticate: false,
            component: <ManageAddress />,
            children: [],
          },
        ],
      },
      {
        path: "",
        name: "Home",
        authenticate: false,
        component: <Home />,
        children: [],
      },
      {
        path: "categories/:id?",
        name: "Products",
        authenticate: false,
        component: <CustomerCategory />,
        children: [],
      },
      {
        path: "product-category/:category_id?/:sub_category_id?",
        name: "Products",
        authenticate: false,
        component: <CustomerProducts />,
        children: [],
      },
      {
        path: "product/:id",
        name: "Product Details",
        authenticate: false,
        component: <CustomerProductDetails />,
      },
      {
        path: "checkout",
        name: "Checkout",
        authenticate: false,
        component: <Checkout />,
        children: [],
      },

      {
        path: "order-cancelled/:id?",
        name: "Order Cancelled",
        authenticate: false,
        component: <OrderCancelled />,
        children: [],
      },

      {
        path: "customer-address",
        name: "Customer Address",
        authenticate: false,
        component: <ManageAddress />,
        children: [],
      },
      {
        path: "checkout",
        name: "CheckOut",
        authenticate: false,
        component: <Checkout />,
        children: [],
      },
      {
        path: "cart",
        name: "Cart",
        authenticate: false,
        component: <Cart />,
      },
      {
        path: "login",
        name: "Login",
        authenticate: false,
        component: <CustomerLogin />,
        children: [],
      },
      {
        path: "forgot-password",
        name: "Forgot Password",
        authenticate: false,
        component: <ForgotPassword />,
        children: [],
      },
      {
        path: "forgot-password/:id",
        name: "Forgot Password",
        authenticate: false,
        component: <ForgotPassword />,
        children: [],
      },
      {
        path: "change-password",
        name: "Change Password",
        authenticate: false,
        component: <ChangePassword />,
        children: [],
      },
      {
        path: "order-list",
        name: "Orders",
        authenticate: false,
        component: <OrderList />,
      },
      {
        path: "search-results/:value?",
        name: "Search Results",
        authenticate: false,
        component: <SearchResults />,
      },
      {
        path: "faq",
        name: "faq",
        authenticate: false,
        component: <FaqPage />,
      },
      {
        path: "refund-and-cancellation",
        name: "Refund And CancellationR",
        authenticate: false,
        component: <RefundAndCancellation />,
      },
      {
        path: "shipping-policy",
        name: "Shipping Policy",
        authenticate: false,
        component: <ShippingPolicy />,
      },
    ],
  },
  {
    path: "order-processing",
    name: "Order Processing",
    authenticate: false,
    component: <ProcessingOrder />,
    children: [],
  },
];

export default my_routes;
