import { combineReducers } from '@reduxjs/toolkit';
import create_trending_slot_list_reducer from './create_trending_slot_list';
import get_trending_slot_list_reducer from './get_trending_slot_list';
import update_trending_slot_list_reducer from './update_trending_slot_list';
import update_trending_slot_list_iterative_reducer from './update_trending_slot_list_iterative';

const trending_slot_reducer = combineReducers({
  create_trending_slot_list: create_trending_slot_list_reducer,
  get_trending_slot_list: get_trending_slot_list_reducer,
  update_trending_slot_list: update_trending_slot_list_reducer,
  update_trending_slot_list_iterative:
    update_trending_slot_list_iterative_reducer,
});

export default trending_slot_reducer;
