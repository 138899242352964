import { combineReducers } from '@reduxjs/toolkit';
import create_district_reducer from './create_district';
import delete_district_reducer from './delete_district';
import get_district_reducer from './get_district';
import get_district_list_reducer from './get_district_list';
import update_district_reducer from './update_district';

const district_reducer = combineReducers({
  create_district: create_district_reducer,
  delete_district: delete_district_reducer,
  get_district: get_district_reducer,
  get_district_list: get_district_list_reducer,
  update_district: update_district_reducer,
});

export default district_reducer;
