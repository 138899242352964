import React, { useEffect } from "react";
import { Space, Table } from "antd";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  AppDispatch,
  get_sub_category_list,
  get_sub_category_list_request,
} from "@src/redux";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
interface SubCategoryFilterProps {
  set_modal_open: (open: boolean) => void;
  set_delete_modal_open: (open: boolean) => void;
  set_sub_category_id: (id: string) => void;
  set_name: (name: string) => void;
}

const SubCategoryTable: React.FC<SubCategoryFilterProps> = ({
  set_modal_open,
  set_sub_category_id,
  set_delete_modal_open,
  set_name,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const { result: sub_category_list } = useSelector(get_sub_category_list);


  useEffect(() => {
    dispatch(
      get_sub_category_list_request({
        filter: {
          category_id: id,
        },
      })
    );
  }, []);

  const handle_edit = (record: any) => {
    set_sub_category_id(record?.id);
    set_modal_open(true);
  };

  const handle_delete = (record: any) => {
    set_name(record?.name);
    set_delete_modal_open(true);
    set_sub_category_id(record?.id);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img
            src={record?.image}
            alt={text}
            style={{ width: 40, height: 40, borderRadius: "50%" }}
          />
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <MdModeEdit
            onClick={() => handle_edit(record)}
            style={{ cursor: "pointer" }}
          />

          <MdDelete
            onClick={() => handle_delete(record)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={sub_category_list?.items}
        className="admin-table"
        rowClassName={(record, index) =>
          index % 2 === 0 ? "row-even" : "row-odd"
        }
      />
    </div>
  );
};

export default SubCategoryTable;
