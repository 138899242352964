import React from 'react';
import { Row, Col, Typography } from 'antd';

const { Text } = Typography;

interface LabelValuePairProps {
  label: React.ReactNode;
  value: React.ReactNode;
}

const LabelValuePair: React.FC<LabelValuePairProps> = ({ label, value }) => (
  <Row style={{ marginBottom: '8px' }}>
    <Col span={6}>
      <Text strong>{label}</Text>
    </Col>
    <Col span={1}>
      :
    </Col>
    <Col span={15}>
      <Text>{value}</Text>
    </Col>
  </Row>
);

export default LabelValuePair;
