import { combineReducers } from '@reduxjs/toolkit';
import create_discount_reducer from './create_discount';
import delete_discount_reducer from './delete_discount';
import get_discount_reducer from './get_discount';
import get_discount_list_reducer from './get_discount_list';
import update_discount_reducer from './update_discount';

const discount_reducer = combineReducers({
  create_discount: create_discount_reducer,
  delete_discount: delete_discount_reducer,
  get_discount: get_discount_reducer,
  get_discount_list: get_discount_list_reducer,
  update_discount: update_discount_reducer,
});

export default discount_reducer;
