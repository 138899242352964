import { combineReducers } from '@reduxjs/toolkit';
import create_farmer_order_list_iterative_reducer from './create_farmer_order_list_iterative';
import generate_farmer_order_po_reducer from './generate_farmer_order_po';
import get_farmer_order_reducer from './get_farmer_order';
import get_farmer_order_list_reducer from './get_farmer_order_list';
import get_previous_demand_list_reducer from './get_previous_demand_list';
import reschedule_farmer_order_reducer from './reschedule_farmer_order';
import update_farmer_order_reducer from './update_farmer_order';

const farmer_order_reducer = combineReducers({
  create_farmer_order_list_iterative:
    create_farmer_order_list_iterative_reducer,
  generate_farmer_order_po: generate_farmer_order_po_reducer,
  get_farmer_order: get_farmer_order_reducer,
  get_farmer_order_list: get_farmer_order_list_reducer,
  get_previous_demand_list: get_previous_demand_list_reducer,
  reschedule_farmer_order: reschedule_farmer_order_reducer,
  update_farmer_order: update_farmer_order_reducer,
});

export default farmer_order_reducer;
