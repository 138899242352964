import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  mutation auto_assign_picking_assignment_item(
    $data: auto_assign_picking_assignment_item_input!
  ) {
    auto_assign_picking_assignment_item(data: $data) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result: null,
  error: null,
  loading: false,
};

export const auto_assign_picking_assignment_item_request = createAsyncThunk<
  TYPES.MutationResponse,
  { data: TYPES.AutoAssignPickingAssignmentItemInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "picking_assignment_item/auto_assign_picking_assignment_item",
  async (
    params: { data: TYPES.AutoAssignPickingAssignmentItemInput },
    { extra: client }
  ) => {
    try {
      const response = await client.mutate({
        mutation: GRAPHQL,
        variables: params,
      });
      return response.data.auto_assign_picking_assignment_item;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const auto_assign_picking_assignment_item_slice = createSlice({
  name: "auto_assign_picking_assignment_item",
  initialState: initial_state,
  reducers: {
    auto_assign_picking_assignment_item_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(auto_assign_picking_assignment_item_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        auto_assign_picking_assignment_item_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        auto_assign_picking_assignment_item_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: "Failed to fetch",
            status_code: "500",
          };
          state.loading = false;
        }
      );
  },
});

export const { auto_assign_picking_assignment_item_clear } =
  auto_assign_picking_assignment_item_slice.actions;

export const auto_assign_picking_assignment_item = createSelector(
  (state: RootState) =>
    state.picking_assignment_item.auto_assign_picking_assignment_item.result,
  (state: RootState) =>
    state.picking_assignment_item.auto_assign_picking_assignment_item.error,
  (state: RootState) =>
    state.picking_assignment_item.auto_assign_picking_assignment_item.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default auto_assign_picking_assignment_item_slice.reducer;
