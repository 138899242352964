import { combineReducers } from '@reduxjs/toolkit';
import create_estimated_demand_reducer from './create_estimated_demand';
import get_previous_estimated_demand_list_reducer from './get_previous_estimated_demand_list';
import update_estimated_demand_reducer from './update_estimated_demand';

const estimated_demand_reducer = combineReducers({
  create_estimated_demand: create_estimated_demand_reducer,
  get_previous_estimated_demand_list:
    get_previous_estimated_demand_list_reducer,
  update_estimated_demand: update_estimated_demand_reducer,
});

export default estimated_demand_reducer;
