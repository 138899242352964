import { combineReducers } from '@reduxjs/toolkit';
import create_farmer_review_reducer from './create_farmer_review';
import get_farmer_review_reducer from './get_farmer_review';
import get_farmer_review_list_reducer from './get_farmer_review_list';
import update_farmer_review_reducer from './update_farmer_review';

const farmer_review_reducer = combineReducers({
  create_farmer_review: create_farmer_review_reducer,
  get_farmer_review: get_farmer_review_reducer,
  get_farmer_review_list: get_farmer_review_list_reducer,
  update_farmer_review: update_farmer_review_reducer,
});

export default farmer_review_reducer;
