import { Modal } from "antd";
import React, { ReactNode } from "react";
import ModalBody from "./modal_body";
import ModalTitle from "./modal_title";
import { AiFillCloseCircle } from "react-icons/ai";

interface CommonModalProps {
  title?: string;
  open: boolean;
  children?: ReactNode;
  onCancel?: any;
  footer?: ReactNode;
  width?: string | number;
  height?: string | number;
  style?: any;
}

const CommonModal: React.FC<CommonModalProps> = ({
  title,
  open,
  children,
  onCancel,
  footer = null,
  width,
  height,
  style,
}) => {
  return (
    <Modal
      title={<ModalTitle title={title} />}
      open={open}
      onCancel={onCancel}
      footer={footer}
      closeIcon={<AiFillCloseCircle color="white" />}
      destroyOnClose={true}
      width={width}
      style={style}
      height={"300px"}
      
    >
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default CommonModal;
