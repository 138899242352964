import { combineReducers } from "@reduxjs/toolkit";
import check_customer_cart_line_item_list_reducer from "./check_customer_cart_line_item_list";
import create_customer_cart_line_item_reducer from "./create_customer_cart_line_item";
import create_customer_cart_line_item_list_iterative_reducer from "./create_customer_cart_line_item_list_iterative";
import delete_customer_cart_line_item_reducer from "./delete_customer_cart_line_item";
import delete_customer_cart_line_item_list_reducer from "./delete_customer_cart_line_item_list";
import get_customer_cart_line_item_list_reducer from "./get_customer_cart_line_item_list";
import update_customer_cart_line_item_reducer from "./update_customer_cart_line_item";
import update_customer_cart_line_item_list_reducer from "./update_customer_cart_line_item_list";

const customer_cart_line_item_reducer = combineReducers({
  check_customer_cart_line_item_list:
    check_customer_cart_line_item_list_reducer,
  create_customer_cart_line_item: create_customer_cart_line_item_reducer,
  create_customer_cart_line_item_list_iterative:
    create_customer_cart_line_item_list_iterative_reducer,
  delete_customer_cart_line_item: delete_customer_cart_line_item_reducer,
  delete_customer_cart_line_item_list:
    delete_customer_cart_line_item_list_reducer,
  get_customer_cart_line_item_list: get_customer_cart_line_item_list_reducer,
  update_customer_cart_line_item: update_customer_cart_line_item_reducer,
  update_customer_cart_line_item_list:
    update_customer_cart_line_item_list_reducer,
});

export default customer_cart_line_item_reducer;
