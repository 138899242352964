import { get_farmer_review } from "@src/redux";
import { Button, Col, Row } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiFillPlusCircle } from "react-icons/ai";
import { useSelector } from "react-redux";

interface FarmerReviewHeaderProps {
  handle_add: () => void;
}

const DriverReviewHeader: React.FC<FarmerReviewHeaderProps> = ({
  handle_add,
}) => {
  const { t } = useTranslation();
  const { result: farmer_rating } = useSelector(get_farmer_review);

  return (
    <Row align={"middle"} justify={"space-between"}>
      <Col span={10}>
        <Title level={4} className="review_rating">
          {t("reviews")}
        </Title>
      </Col>
      <Col span={3}>
        {(!farmer_rating?.cons || !farmer_rating?.pros) && (
          <Button
            // icon={<AiFillPlusCircle color="white" />}
            className="add-button"
            onClick={handle_add}
          >
            {t("add")}
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default DriverReviewHeader;
