import { useEffect, useState } from "react";
import CommonModal from "../components/common/modal/custom_modal";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import {
  AppDispatch,
  create_sub_category,
  create_sub_category_clear,
  delete_sub_category,
  delete_sub_category_clear,
  get_category_request,
  get_sub_category_list_request,
  update_sub_category,
  update_sub_category_clear,
} from "@src/redux";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SubCategoryFilter from "../components/sub_category_table.tsx/sub_category_filter";
import SubCategoryTable from "../components/sub_category_table.tsx/sub_category_table";
import SubCategoryForm from "../components/sub_category_table.tsx/sub_category_form";
import SubCategoryDeleteConfirmation from "../components/sub_category_table.tsx/sub_category_delete_confirmation";

type Props = {};

const SubCategory = (props: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [modal_open, set_modal_open] = useState<boolean>(false);
  const [delete_modal_open, set_delete_modal_open] = useState<boolean>(false);
  const [sub_category_id, set_sub_category_id] = useState<string>("");
  const [name, set_name] = useState<string | undefined>(undefined);

  const { result: sub_category_create_result } =
    useSelector(create_sub_category);
  const { result: sub_category_update_result } =
    useSelector(update_sub_category);
  const { result: sub_category_delete_result } =
    useSelector(delete_sub_category);

  useEffect(() => {
    dispatch(
      get_category_request({
        filter: {
          id: id,
        },
      })
    );
  }, []);

  useEffect(() => {
    if (sub_category_create_result?.status === "success") {
      toast.success(t("sub_category_created_successfully"));
      dispatch(create_sub_category_clear());
      handle_cancel();
      sub_category_list_request();
    } else if (sub_category_update_result?.status === "success") {
      toast.success(t("sub_category_update_successfully"));
      dispatch(update_sub_category_clear());
      handle_cancel();
      sub_category_list_request();
    } else if (sub_category_delete_result?.status === "success") {
      toast.success(t("sub_category_delete_successfully"));
      dispatch(delete_sub_category_clear());
      handle_cancel();
      sub_category_list_request();
    } else if (sub_category_create_result?.status === "failure") {
      toast.success(t("sub_category_created_failure"));
      dispatch(create_sub_category_clear());
      handle_cancel();
      sub_category_list_request();
    } else if (sub_category_update_result?.status === "failure") {
      toast.success(t("sub_category_update_failure"));
      dispatch(update_sub_category_clear());
      handle_cancel();
      sub_category_list_request();
    } else if (sub_category_delete_result?.status === "failure") {
      toast.success(t("sub_category_delete_failure"));
      dispatch(delete_sub_category_clear());
      handle_cancel();
      sub_category_list_request();
    }
  }, [
    sub_category_create_result,
    sub_category_update_result,
    sub_category_delete_result,
  ]);

  const sub_category_list_request = () => {
    dispatch(
      get_sub_category_list_request({
        filter: {
          category_id: id,
        },
      })
    );
  };

  const handle_cancel = () => {
    set_modal_open(false);
    set_delete_modal_open(false);
    set_sub_category_id("");
  };

  return (
    <div style={{ padding: "10px" }}>
      <SubCategoryFilter set_modal_open={set_modal_open} />
      <SubCategoryTable
        set_modal_open={set_modal_open}
        set_delete_modal_open={set_delete_modal_open}
        set_name={set_name}
        set_sub_category_id={set_sub_category_id}
      />

      <CommonModal
        title={t(sub_category_id ? "edit_sub_category" : "add_sub_category")}
        open={modal_open}
        onCancel={handle_cancel}
        footer={null}
      >
        <SubCategoryForm
          handle_cancel={handle_cancel}
          sub_category_id={sub_category_id}
        />
      </CommonModal>

      <CommonModal
        title={t("delete_sub_category")}
        open={delete_modal_open}
        onCancel={handle_cancel}
        footer={null}
      >
        <SubCategoryDeleteConfirmation
          handle_cancel={handle_cancel}
          name={name}
          sub_category_id={sub_category_id}
        />
      </CommonModal>
    </div>
  );
};

export default SubCategory;
