import { get_product } from "@src/redux";
import { Breadcrumb } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export interface BreadCrumbItem {
  path?: string;
  title?: string;
}

export interface BreadCrumbProps {
  items: BreadCrumbItem[];
  apply_theme?: boolean;
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({
  items,
  apply_theme = false,
}) => {
  const { result: product } = useSelector(get_product);

  const generateBreadcrumbs = () => {
    return items.map((item, index) => {
      const isLast = index === items.length - 1;
      return (
        <Breadcrumb.Item
          className={!apply_theme ? "breadcrumb_content" : "without_theme"}
          key={item.path}
        >
          {isLast ? (
            item.title
          ) : (
            <Link
              className={!apply_theme ? "breadcrumb_link" : "without_link"}
              to={item?.path || ""}
            >
              {item.title}
            </Link>
          )}
        </Breadcrumb.Item>
      );
    });
  };

  return (
    <Breadcrumb className={!apply_theme ? "bread-crumb" : "admin-bread-crumb"}>
      {generateBreadcrumbs()}
    </Breadcrumb>
  );
};

export default BreadCrumb;
