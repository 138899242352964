import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_packing_suggestion_summary_list(
    $filter: packing_suggestion_filter_input!
  ) {
    get_packing_suggestion_summary_list(filter: $filter) {
      items {
        total_packed_quantity
        total_ordered_quantity
        product {
          id
          name
        }
        product_variant {
          id
          name
        }
      }
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.GetPackingSuggestionSummaryListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_packing_suggestion_summary_list_request = createAsyncThunk<
  TYPES.GetPackingSuggestionSummaryList,
  { filter: TYPES.PackingSuggestionFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'packing_suggestion/get_packing_suggestion_summary_list',
  async (
    params: { filter: TYPES.PackingSuggestionFilterInput },
    { extra: client },
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_packing_suggestion_summary_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_packing_suggestion_summary_list_slice = createSlice({
  name: 'get_packing_suggestion_summary_list',
  initialState: initial_state,
  reducers: {
    get_packing_suggestion_summary_list_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(get_packing_suggestion_summary_list_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        get_packing_suggestion_summary_list_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        get_packing_suggestion_summary_list_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: 'Failed to fetch',
            status_code: '500',
          };
          state.loading = false;
        },
      );
  },
});

export const { get_packing_suggestion_summary_list_clear } =
  get_packing_suggestion_summary_list_slice.actions;

export const get_packing_suggestion_summary_list = createSelector(
  (state: RootState) =>
    state.packing_suggestion.get_packing_suggestion_summary_list.result,
  (state: RootState) =>
    state.packing_suggestion.get_packing_suggestion_summary_list.error,
  (state: RootState) =>
    state.packing_suggestion.get_packing_suggestion_summary_list.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_packing_suggestion_summary_list_slice.reducer;
