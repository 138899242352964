import { Button, Col, Form, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import {
  AppDispatch,
  create_farmer_review,
  create_farmer_review_clear,
  create_farmer_review_request,
  get_farmer_review,
  get_farmer_review_list_request,
  update_farmer_review,
  update_farmer_review_clear,
} from "@src/redux";
import { get_uuid, retrieve_item } from "@src/helpers/functions";
import DriverReviewFormType from "./driver_form_review_type";
import DriverReviewFormReview from "./driver_review_form_review";

interface FarmerRatingFormProps {
  close: () => void;
  action_type: String;
  review_details: Record<string, any>;
  form: FormInstance;
}

const DriverRatingForm: React.FC<FarmerRatingFormProps> = ({
  close,
  action_type,
  form,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();

  const {
    result: create_farmer_review_result,
    error: create_farmer_review_error,
    loading: create_farmer_review_loading,
  } = useSelector(create_farmer_review);

  const {
    result: update_farmer_review_result,
    error: update_farmer_review_error,
    loading: update_farmer_review_loading,
  } = useSelector(update_farmer_review);

  const { result: farmer_rating } = useSelector(get_farmer_review);

  const on_finish = (values: any) => {
    delete values.type;
    if (action_type === "add" && !farmer_rating?.id) {
      dispatch(
        create_farmer_review_request({
          data: {
            ...values,
            farmer_id: id,
            id: get_uuid(),
            procurement_officer_id: retrieve_item("admin_user_id"),
          },
        })
      );
    } else {
      dispatch(
        create_farmer_review_request({
          data: {
            ...values,
            id: farmer_rating?.id,
            farmer_id: id,
            procurement_officer_id: retrieve_item("admin_user_id"),
          },
        })
      );
    }
  };

  useEffect(() => {
    if (create_farmer_review_result?.status === "success") {
      close();
      toast.success(t("Review updated successfully"));
      dispatch(get_farmer_review_list_request({ filter: { farmer_id: id } }));
      dispatch(create_farmer_review_clear());
    } else if (create_farmer_review_error?.message) {
      toast.error(create_farmer_review_error?.message);
      dispatch(create_farmer_review_clear());
    } else if (update_farmer_review_result?.status === "success") {
      close();
      toast.success(t("Review updated successfully"));
      dispatch(get_farmer_review_list_request({ filter: { farmer_id: id } }));
      dispatch(update_farmer_review_clear());
    } else if (update_farmer_review_error?.message) {
      toast.error(update_farmer_review_error?.message);
      dispatch(update_farmer_review_clear());
    }
  }, [
    create_farmer_review_result,
    create_farmer_review_error,
    update_farmer_review_result,
    update_farmer_review_error,
  ]);

  return (
    <Form layout="vertical" form={form} onFinish={on_finish}>
      <Row gutter={10} align="top">
        <Col span={24}>
          <DriverReviewFormType action_type={action_type || ""} />
        </Col>
        <Col span={24}>
          <DriverReviewFormReview form={form} />
        </Col>
      </Row>
      <Row align="top" justify="end" gutter={10}>
        <Col span={3}>
          <Button
            className="add-button"
            htmlType="submit"
            loading={
              create_farmer_review_loading || update_farmer_review_loading
            }
          >
            {action_type === "add" ? t("submit") : t("update")}
          </Button>
        </Col>
        <Col span={3}>
          <Button danger onClick={close}>
            {t("close")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default DriverRatingForm;
