import { combineReducers } from '@reduxjs/toolkit';
import create_state_reducer from './create_state';
import delete_state_reducer from './delete_state';
import get_state_reducer from './get_state';
import get_state_list_reducer from './get_state_list';
import update_state_reducer from './update_state';

const state_reducer = combineReducers({
  create_state: create_state_reducer,
  delete_state: delete_state_reducer,
  get_state: get_state_reducer,
  get_state_list: get_state_list_reducer,
  update_state: update_state_reducer,
});

export default state_reducer;
