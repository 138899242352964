import React from "react";
import { Button, Row, Typography } from "antd";
import { TiEdit } from "react-icons/ti";
import LabelValuePair from "@src/views/components/common/label_value_pair";
import CommonModal from "@src/views/components/common/modal/custom_modal";
import { useTranslation } from "react-i18next";
import { get_farmer, get_staff } from "@src/redux";
import { useSelector } from "react-redux";
import { useCheckPageComponentAccess } from "@src/hooks";
import { startCase } from "lodash";

const { Title, Text } = Typography;

interface FarmerAboutTabProps {
  farmerUpdateModal: boolean;
  setFarmerUpdateModal: (visible: boolean) => void;
}

const DriverAboutTab: React.FC<FarmerAboutTabProps> = ({
  farmerUpdateModal,
  setFarmerUpdateModal,
}) => {
  const { t } = useTranslation();
  const { result: farmer } = useSelector(get_staff);

  const handleUpdate = () => {
    setFarmerUpdateModal(true);
  };

  const handleClose = () => {
    setFarmerUpdateModal(false);
  };

  return (
    <div>
      <Row align={"top"} justify={"end"}>
        {useCheckPageComponentAccess("update_farmer_action") && (
          <Button
            // icon={<TiEdit color="white" />}
            className="add-button"
            onClick={handleUpdate}
          >
            {t("update")}
          </Button>
        )}
      </Row>
      <div className="farmer-about-main-container">
        <LabelValuePair label={t("name")} value={farmer?.name} />
        <LabelValuePair label={t("mobile_number")} value={farmer?.mobile} />
        <LabelValuePair label={t("mail_id")} value={farmer?.email} />
        {/* <LabelValuePair label={t("state")} value={farmer?.state?.name} />
        <LabelValuePair label={t("district")} value={farmer?.district?.name} />
        <LabelValuePair label={t("address")} value={farmer?.address} />
        <LabelValuePair
          label={t("logistic_mode")}
          value={startCase(farmer?.logistic_mode)}
        />
        <LabelValuePair
          label={t("bank_name")}
          value={farmer?.bank_name || "-"}
        />
        <LabelValuePair
          label={t("account_number")}
          value={farmer?.account_number || "-"}
        />
        <LabelValuePair
          label={t("ifsc_code")}
          value={farmer?.ifsc_code || "-"}
        />
        <LabelValuePair label={t("gst_no")} value={farmer?.gst_number || "-"} /> */}
      </div>
    </div>
  );
};

export default DriverAboutTab;
