import { combineReducers } from '@reduxjs/toolkit';
import create_customer_return_order_reducer from './create_customer_return_order';
import get_customer_return_order_reducer from './get_customer_return_order';
import get_customer_return_order_list_reducer from './get_customer_return_order_list';

const customer_return_order_reducer = combineReducers({
  create_customer_return_order: create_customer_return_order_reducer,
  get_customer_return_order: get_customer_return_order_reducer,
  get_customer_return_order_list: get_customer_return_order_list_reducer,
});

export default customer_return_order_reducer;
