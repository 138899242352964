import { combineReducers } from '@reduxjs/toolkit';
import create_closing_delivery_date_product_stock_list_reducer from './create_closing_delivery_date_product_stock_list';
import create_closing_delivery_date_product_stock_list_iterative_reducer from './create_closing_delivery_date_product_stock_list_iterative';
import get_closing_delivery_date_product_stock_list_reducer from './get_closing_delivery_date_product_stock_list';

const closing_delivery_date_product_stock_reducer = combineReducers({
  create_closing_delivery_date_product_stock_list:
    create_closing_delivery_date_product_stock_list_reducer,
  create_closing_delivery_date_product_stock_list_iterative:
    create_closing_delivery_date_product_stock_list_iterative_reducer,
  get_closing_delivery_date_product_stock_list:
    get_closing_delivery_date_product_stock_list_reducer,
});

export default closing_delivery_date_product_stock_reducer;
