import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_warehouse_summary_list($data: get_warehouse_summary_input!) {
    get_warehouse_summary_list(data: $data) {
      item {
        closing_stock
        physical_quantity
        remaining_quantity
        total_farmer_ordered_converted_quantity
        total_farmer_ordered_quantity
        packing_wastage_quantity
        perishable_wastage_quantity
        non_trackable_wastage_quantity
        company_wastage_quantity
        farmer_wastage_quantity
        total_packed_quantity
        total_ordered_quantity
        total_packed_count
        unique_customer_count
        total_available_quantity
        total_stock_quantity
        warehouse_id
        product_variant_id
        product_id
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.GetWarehouseSummaryListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_warehouse_summary_list_request = createAsyncThunk<
  TYPES.GetWarehouseSummaryList,
  { data: TYPES.GetWarehouseSummaryInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'warehouse/get_warehouse_summary_list',
  async (
    params: { data: TYPES.GetWarehouseSummaryInput },
    { extra: client },
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_warehouse_summary_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_warehouse_summary_list_slice = createSlice({
  name: 'get_warehouse_summary_list',
  initialState: initial_state,
  reducers: {
    get_warehouse_summary_list_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(get_warehouse_summary_list_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        get_warehouse_summary_list_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        },
      )
      .addCase(get_warehouse_summary_list_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { get_warehouse_summary_list_clear } =
  get_warehouse_summary_list_slice.actions;

export const get_warehouse_summary_list = createSelector(
  (state: RootState) => state.warehouse.get_warehouse_summary_list.result,
  (state: RootState) => state.warehouse.get_warehouse_summary_list.error,
  (state: RootState) => state.warehouse.get_warehouse_summary_list.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_warehouse_summary_list_slice.reducer;
