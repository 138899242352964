import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  mutation create_customer_address($data: create_customer_address_input!) {
    create_customer_address(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result: null,
  error: null,
  loading: false,
};

export const create_customer_address_request = createAsyncThunk<
  TYPES.MutationResponse,
  { data: TYPES.CreateCustomerAddressInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "customer_address/create_customer_address",
  async (
    params: { data: TYPES.CreateCustomerAddressInput },
    { extra: client }
  ) => {
    try {
      const response = await client.mutate({
        mutation: GRAPHQL,
        variables: params,
      });
      return response.data.create_customer_address;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const create_customer_address_slice = createSlice({
  name: "create_customer_address",
  initialState: initial_state,
  reducers: {
    create_customer_address_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(create_customer_address_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(create_customer_address_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(create_customer_address_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { create_customer_address_clear } =
  create_customer_address_slice.actions;

export const create_customer_address = createSelector(
  (state: RootState) => state.customer_address.create_customer_address.result,
  (state: RootState) => state.customer_address.create_customer_address.error,
  (state: RootState) => state.customer_address.create_customer_address.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default create_customer_address_slice.reducer;
