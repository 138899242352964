import { combineReducers } from '@reduxjs/toolkit';
import create_customer_review_reducer from './create_customer_review';
import get_customer_review_list_reducer from './get_customer_review_list';
import update_customer_review_reducer from './update_customer_review';

const customer_review_reducer = combineReducers({
  create_customer_review: create_customer_review_reducer,
  get_customer_review_list: get_customer_review_list_reducer,
  update_customer_review: update_customer_review_reducer,
});

export default customer_review_reducer;
