export const PROJECT_NAME = "myHarvest";
// export const BASE_URL = process.env.REACT_APP_API_URL + "graphql";
// export const BASE_URL = "https://shop.myharvestfarms.com/api/" + "graphql";
export const BASE_URL = "https://myharvest.vertace.org/api/graphql";

export const roles = {
  packing_team: "Packing/Picking Team",
  procurement: "Procurement",
  warehouse_hub: "Warehouse Hub",
  warehouse: "Warehouse",
  driver: "driver",
};

export const predefined_cancel_reasons = [
  "I selected the wrong quantity, so I need to cancel.",
  "I no longer need these items, please cancel my order.",
  "Sorry, wrong order. I ordered by mistake.",
  "I don’t like this product, so I want to cancel.",
  "Others",
];

export const MOBILE = 7200606516;
