import { Col, Row, Tag, Tooltip } from "antd";
import { startCase } from "lodash";

export const formatStatus: React.FC = (
  value: any,
  show_text: "dot" | "dot with text" | "text"
) => {
  switch (value) {
    case "partially_received":
      return show_text === "dot" ? (
        <Tooltip title={startCase(value)}>
          <Tag className="status-tag status-demand status-dot" />
        </Tooltip>
      ) : show_text === "dot with text" ? (
        <Row align={"middle"} justify={"center"}>
          <Col className="status-tag-column">
            <Tag className="status-tag status-demand status-dot" />
          </Col>
          <Col>
            <b>{startCase(value)}</b>
          </Col>
        </Row>
      ) : show_text === "text" ? (
        <Tag className="status-tag status-demand">{startCase(value)}</Tag>
      ) : (
        ""
      );
    case "received":
      return show_text === "dot" ? (
        <Tooltip title={startCase(value)}>
          <Tag className="status-tag status-received status-dot" />
        </Tooltip>
      ) : show_text === "dot with text" ? (
        <Row align={"middle"} justify={"center"}>
          <Col className="status-tag-column">
            <Tag className="status-tag status-received status-dot" />
          </Col>
          <Col>
            <b>{startCase(value)}</b>
          </Col>
        </Row>
      ) : show_text === "text" ? (
        <Tag className="status-tag status-received">{startCase(value)}</Tag>
      ) : (
        ""
      );
    case "po_generated":
    case "marked_as_read":
      return show_text === "dot" ? (
        <Tooltip title={startCase(value)}>
          <Tag className="status-tag status-po-generated status-dot" />
        </Tooltip>
      ) : show_text === "dot with text" ? (
        <Row align={"middle"} justify={"center"}>
          <Col className="status-tag-column">
            <Tag className="status-tag status-po-generated status-dot" />
          </Col>
          <Col>
            <b>{startCase(value)}</b>
          </Col>
        </Row>
      ) : show_text === "text" ? (
        <Tag className="status-tag status-po-generated">{startCase(value)}</Tag>
      ) : (
        ""
      );
    case "paid":
      return show_text === "dot" ? (
        <Tooltip title={startCase(value)}>
          <Tag className="status-tag status-paid status-dot" />
        </Tooltip>
      ) : show_text === "dot with text" ? (
        <Row align={"middle"} justify={"center"}>
          <Col className="status-tag-column">
            <Tag className="status-tag status-paid status-dot" />
          </Col>
          <Col>
            <b>{startCase(value)}</b>
          </Col>
        </Row>
      ) : show_text === "text" ? (
        <Tag className="status-tag status-paid">{startCase(value)}</Tag>
      ) : (
        ""
      );
    case "active":
    case "Completed":
    case "completed":
    case "Placed":
    case "placed":
    case "success":
    case "Approved":
    case "delivered":
      return show_text === "dot" ? (
        <Tooltip title={startCase(value)}>
          <Tag className="status-tag status-active status-dot" />
        </Tooltip>
      ) : show_text === "dot with text" ? (
        <Row align={"middle"} justify={"center"}>
          <Col className="status-tag-column">
            <Tag className="status-tag status-active status-dot" />
          </Col>
          <Col>
            <b>{startCase(value)}</b>
          </Col>
        </Row>
      ) : show_text === "text" ? (
        <Tag className="status-tag status-active">{startCase(value)}</Tag>
      ) : (
        ""
      );
    case "ordered":
      return show_text === "dot" ? (
        <Tooltip title={startCase(value)}>
          <Tag className="status-tag status-active status-dot" />
        </Tooltip>
      ) : show_text === "dot with text" ? (
        <Row align={"middle"} justify={"center"}>
          <Col className="status-tag-column">
            <Tag className="status-tag status-active status-dot" />
          </Col>
          <Col>
            <b>{startCase(value)}</b>
          </Col>
        </Row>
      ) : show_text === "text" ? (
        <Tag className="status-tag status-active">{startCase(value)}</Tag>
      ) : (
        ""
      );

    case "unpaid":
      return show_text === "dot" ? (
        <Tooltip title={startCase(value)}>
          <Tag className="status-tag status-un-paid status-dot" />
        </Tooltip>
      ) : show_text === "dot with text" ? (
        <Row align={"middle"} justify={"center"}>
          <Col className="status-tag-column">
            <Tag className="status-tag status-un-paid status-dot" />
          </Col>
          <Col>
            <b>{startCase(value)}</b>
          </Col>
        </Row>
      ) : show_text === "text" ? (
        <Tag className="status-tag status-un-paid">{startCase(value)}</Tag>
      ) : (
        ""
      );
    case "Failure":
    case "Rejected":
    case "closed":
    case "not_yet":
    case "rescheduled":
      return show_text === "dot" ? (
        <Tooltip title={startCase(value)}>
          <Tag className="status-tag status-closed status-dot" />
        </Tooltip>
      ) : show_text === "dot with text" ? (
        <Row align={"middle"} justify={"center"}>
          <Col className="status-tag-column">
            <Tag className="status-tag status-closed status-dot" />
          </Col>
          <Col>
            <b>{startCase(value)}</b>
          </Col>
        </Row>
      ) : show_text === "text" ? (
        <Tag className="status-tag status-closed">{startCase(value)}</Tag>
      ) : (
        ""
      );
    case "not_received":
    case "not_picked":
      return show_text === "dot" ? (
        <Tooltip title={startCase(value)}>
          <Tag className="status-tag status-not-received status-dot" />
        </Tooltip>
      ) : show_text === "dot with text" ? (
        <Row align={"middle"} justify={"center"}>
          <Col className="status-tag-column">
            <Tag className="status-tag status-not-received status-dot" />
          </Col>
          <Col>
            <b>{startCase(value)}</b>
          </Col>
        </Row>
      ) : show_text === "text" ? (
        <Tag className="status-tag status-not-received">{startCase(value)}</Tag>
      ) : (
        ""
      );
    case "not_updated":
    case "picked":
      return show_text === "dot" ? (
        <Tooltip title={startCase(value)}>
          <Tag className="status-tag status-not-updated status-dot" />
        </Tooltip>
      ) : show_text === "dot with text" ? (
        <Row align={"middle"} justify={"center"}>
          <Col className="status-tag-column">
            <Tag className="status-tag status-not-updated status-dot" />
          </Col>
          <Col>
            <b>{startCase(value)}</b>
          </Col>
        </Row>
      ) : show_text === "text" ? (
        <Tag className="status-tag status-not-updated">{startCase(value)}</Tag>
      ) : (
        ""
      );
    case "Pending":
    case "on_queue":
    case "extra_received":
    case "initiated":
    case "processing":
    case "skip":
    case "packed":
      return show_text === "dot" ? (
        <Tooltip title={startCase(value)}>
          <Tag className="status-tag status-pending status-dot" />
        </Tooltip>
      ) : show_text === "dot with text" ? (
        <Row align={"middle"} justify={"center"}>
          <Col className="status-tag-column">
            <Tag className="status-tag status-pending status-dot" />
          </Col>
          <Col>
            <b>{startCase(value)}</b>
          </Col>
        </Row>
      ) : show_text === "text" ? (
        <Tag className="status-tag status-pending">{startCase(value)}</Tag>
      ) : (
        ""
      );
    case "Reverted":
    case "failed":
    case "cancelled_by_firm":
    case "cancelled_by_farmer":
    case "cancelled_by_customer":
    case "awaiting_approval_for_cancellation":
    case "cancelled":
    case "duplicate":
    case "processed_with_error":
    case "partially_paid":
      return show_text === "dot" ? (
        <Tooltip title={startCase(value)}>
          <Tag className="status-tag status-revert status-dot" />
        </Tooltip>
      ) : show_text === "dot with text" ? (
        <Row align={"middle"} justify={"center"}>
          <Col className="status-tag-column">
            <Tag className="status-tag status-revert status-dot" />
          </Col>
          <Col>
            <b>{startCase(value)}</b>
          </Col>
        </Row>
      ) : show_text === "text" ? (
        <Tag className="status-tag status-revert">{startCase(value)}</Tag>
      ) : (
        ""
      );

    case "already_paid":
    case "placed_not_paid":
    case "waste_updated":
    case "not_packed":
      return show_text === "dot" ? (
        <Tooltip title={startCase(value)}>
          <Tag className="status-tag status-maturity status-dot" />
        </Tooltip>
      ) : show_text === "dot with text" ? (
        <Row align={"middle"} justify={"center"}>
          <Col className="status-tag-column">
            <Tag className="status-tag status-maturity status-dot" />
          </Col>
          <Col>
            <b>{startCase(value)}</b>
          </Col>
        </Row>
      ) : show_text === "text" ? (
        <Tag className="status-tag status-maturity">{startCase(value)}</Tag>
      ) : (
        ""
      );
    default:
      return value ? show_text && startCase(value) : "-";
  }
};
