import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "antd";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import animation from "@assets/lotte_files/fruit_404_animation.json";

const { Text } = Typography;

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: "center", padding: "2rem" }}>
      <div style={{ maxWidth: 400, margin: "0 auto" }}>
        <Lottie animationData={animation} loop autoplay />
      </div>
      <Text className="order-placed-text">{t("not_exist")}</Text>
      <br />
      <Link to="/">
        <Button type="primary" style={{ marginTop: "1rem" }}>
          {t("home")}
        </Button>
      </Link>
    </div>
  );
};

export default NotFound;
