import { combineReducers } from '@reduxjs/toolkit';
import create_coupon_reducer from './create_coupon';
import delete_coupon_reducer from './delete_coupon';
import get_coupon_reducer from './get_coupon';
import get_coupon_list_reducer from './get_coupon_list';
import get_validate_coupon_reducer from './get_validate_coupon';
import update_coupon_reducer from './update_coupon';

const coupon_reducer = combineReducers({
  create_coupon: create_coupon_reducer,
  delete_coupon: delete_coupon_reducer,
  get_coupon: get_coupon_reducer,
  get_coupon_list: get_coupon_list_reducer,
  get_validate_coupon: get_validate_coupon_reducer,
  update_coupon: update_coupon_reducer,
});

export default coupon_reducer;
