import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_warehouse_suggestion_required_quantity_(
    $filter: product_stock_transfer_request_filter_input!
  ) {
    get_warehouse_required_quantity(filter: $filter) {
      items {
        id
        product_id
        product_variant_id
        quantity
        product {
          id
          name
          image_list
        }
        product_variant {
          id
          name
        }
        current_stock_quantity
      }
      error {
        message
      }
    }
  }
`;

const initial_state: TYPES.ProductStockTransferRequestListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_warehouse_suggestion_required_quantity__request =
  createAsyncThunk<
    TYPES.ProductStockTransferRequestList,
    { filter: TYPES.ProductStockTransferRequestFilterInput },
    { extra: ApolloClient<NormalizedCacheObject> }
  >(
    "product_stock_transfer_request/get_warehouse_suggestion_required_quantity_",
    async (
      params: { filter: TYPES.ProductStockTransferRequestFilterInput },
      { extra: client }
    ) => {
      try {
        const response = await client.query({
          query: GRAPHQL,
          variables: params,
        });
        return response.data.get_warehouse_required_quantity;
      } catch (error) {
        const err = error as { message?: String; status_code?: String };
        return Promise.reject({
          message: err.message || "An error occurred",
          status_code: err.status_code || "500",
        });
      }
    }
  );

const get_warehouse_suggestion_required_quantity__slice = createSlice({
  name: "get_warehouse_suggestion_required_quantity_",
  initialState: initial_state,
  reducers: {
    get_warehouse_suggestion_required_quantity__clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        get_warehouse_suggestion_required_quantity__request.pending,
        (state) => {
          state.result = null;
          state.error = null;
          state.loading = true;
        }
      )
      .addCase(
        get_warehouse_suggestion_required_quantity__request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        get_warehouse_suggestion_required_quantity__request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: "Failed to fetch",
            status_code: "500",
          };
          state.loading = false;
        }
      );
  },
});

export const { get_warehouse_suggestion_required_quantity__clear } =
  get_warehouse_suggestion_required_quantity__slice.actions;

export const get_warehouse_suggestion_required_quantity_ = createSelector(
  (state: RootState) =>
    state.product_stock_transfer_request
      .get_warehouse_suggestion_required_quantity_.result,
  (state: RootState) =>
    state.product_stock_transfer_request
      .get_warehouse_suggestion_required_quantity_.error,
  (state: RootState) =>
    state.product_stock_transfer_request
      .get_warehouse_suggestion_required_quantity_.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_warehouse_suggestion_required_quantity__slice.reducer;
