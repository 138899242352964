import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  mutation create_farmer_product_list_iterative(
    $data: [create_farmer_product_input]
  ) {
    create_farmer_product_list_iterative(data: $data) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result: null,
  error: null,
  loading: false,
};

export const create_farmer_product_list_iterative_request = createAsyncThunk<
  TYPES.MutationResponse,
  { data?: TYPES.CreateFarmerProductInput[] },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'farmer_product/create_farmer_product_list_iterative',
  async (
    params: { data?: TYPES.CreateFarmerProductInput[] },
    { extra: client },
  ) => {
    try {
      const response = await client.mutate({
        mutation: GRAPHQL,
        variables: params,
      });
      return response.data.create_farmer_product_list_iterative;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const create_farmer_product_list_iterative_slice = createSlice({
  name: 'create_farmer_product_list_iterative',
  initialState: initial_state,
  reducers: {
    create_farmer_product_list_iterative_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(create_farmer_product_list_iterative_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        create_farmer_product_list_iterative_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        create_farmer_product_list_iterative_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: 'Failed to fetch',
            status_code: '500',
          };
          state.loading = false;
        },
      );
  },
});

export const { create_farmer_product_list_iterative_clear } =
  create_farmer_product_list_iterative_slice.actions;

export const create_farmer_product_list_iterative = createSelector(
  (state: RootState) =>
    state.farmer_product.create_farmer_product_list_iterative.result,
  (state: RootState) =>
    state.farmer_product.create_farmer_product_list_iterative.error,
  (state: RootState) =>
    state.farmer_product.create_farmer_product_list_iterative.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default create_farmer_product_list_iterative_slice.reducer;
