import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_customer_order_details(
    $filter: customer_order_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_customer_order_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        customer {
          name
        }
        delivery_date {
          id
          date
        }
        cluster {
          driver {
            id
            name
            instructor_packing_assignment_list {
              pack_by {
                system_user {
                  name
                }
              }
            }
          }
        }
        picking_assignment_item {
          packing_assignment_id
          status
          packing_assignment {
            pack_by {
              system_user_id
              name
            }
          }
        }
        delivery_instruction
        return_status
        status
        coupon_id
        delivery_date_id
        ordered_datetime
        customer_id
        order_reference
        id
      }
      pagination {
        total_count
        page_limit
        page_number
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.CustomerOrderListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_customer_order_details_request = createAsyncThunk<
  TYPES.CustomerOrderList,
  {
    filter?: TYPES.CustomerOrderFilterInput;
    pagination?: TYPES.PaginationInput;
    sort?: TYPES.SortInput[];
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "customer_order/get_customer_order_details",
  async (
    params: {
      filter?: TYPES.CustomerOrderFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_customer_order_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_customer_order_details_slice = createSlice({
  name: "get_customer_order_details",
  initialState: initial_state,
  reducers: {
    get_customer_order_details_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_customer_order_details_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        get_customer_order_details_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        }
      )
      .addCase(get_customer_order_details_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_customer_order_details_clear } =
  get_customer_order_details_slice.actions;

export const get_customer_order_details = createSelector(
  (state: RootState) => state.customer_order.get_customer_order_details.result,
  (state: RootState) => state.customer_order.get_customer_order_details.error,
  (state: RootState) => state.customer_order.get_customer_order_details.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_customer_order_details_slice.reducer;
