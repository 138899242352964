import { combineReducers } from "@reduxjs/toolkit";
import create_packing_suggestion_reducer from "./create_packing_suggestion";
import create_packing_suggestion_list_reducer from "./create_packing_suggestion_list";
import delete_packing_suggestion_list_reducer from "./delete_packing_suggestion_list";
import get_packing_suggestion_reducer from "./get_packing_suggestion";
import get_packing_suggestion_list_reducer from "./get_packing_suggestion_list";
import get_packing_suggestion_summary_list_reducer from "./get_packing_suggestion_summary_list";
import update_packing_suggestion_reducer from "./update_packing_suggestion";
import update_packing_suggestion_list_reducer from "./update_packing_suggestion_list";

const packing_suggestion_reducer = combineReducers({
  create_packing_suggestion: create_packing_suggestion_reducer,
  create_packing_suggestion_list: create_packing_suggestion_list_reducer,
  delete_packing_suggestion_list: delete_packing_suggestion_list_reducer,
  get_packing_suggestion: get_packing_suggestion_reducer,
  get_packing_suggestion_list: get_packing_suggestion_list_reducer,
  get_packing_suggestion_summary_list:
    get_packing_suggestion_summary_list_reducer,
  update_packing_suggestion: update_packing_suggestion_reducer,
  update_packing_suggestion_list: update_packing_suggestion_list_reducer,
});

export default packing_suggestion_reducer;
