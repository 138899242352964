import React from "react";
import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";
import {
  Coupon,
  CustomerAddress,
  CustomerCartLineItem,
  CustomerOrder,
  CustomerOrderPayment,
  FarmerOrder,
  FarmerOrderLineItem,
  FarmerOrderLineItemList,
  GetWarehouseStock,
  Product,
} from "@src/redux";
import toast from "react-hot-toast";
import { upperCase } from "lodash";
import { ToWords } from "to-words";
import {
  FACEBOOK,
  INSTAGRAM,
  LOGO_WIDTH_TITLE,
  WHATSAPP,
} from "./images_constants";
import { mukta_bold, mukta_normal } from "./font_constants";
import jsPDF from "jspdf";
import moment, { Moment } from "moment";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import dayjs from "dayjs";
import "jspdf-autotable";
import { saveAs } from "file-saver";

interface OrderValues {
  [key: string]: {
    total_wastage_primary_unit?: number;
    company_wastage_quantity?: number;
    farmer_wastage_quantity?: number;
    received_quantity?: number;
    received_quantity_in_secondary_unit?: number;
    total_wastage_secondary_unit?: number;
    company_wastage_quantity_in_secondary_unit?: number;
    farmer_wastage_quantity_in_secondary_unit?: number;
  };
} // Helper functions for encryption and decryption
export const encrypt_data = (data: string, salt: string): string =>
  CryptoJS.AES.encrypt(data, salt).toString();

export const order_status = (status: string) => {
  const status_map = {
    placed: "Placed",
    placed_not_paid: "Placed",
    packed: "Packing",
    dispatched: "Dispatched",
    delivered: "Delivered",
    cancelled_by_firm: "Cancelled",
    cancelled_by_customer: "Cancelled",
    awaiting_approval_for_cancellation: "Cancelled Initiated",
    returned_by_customer: "Returned",
    partially_returned_by_customer: "Partially Returned",
  };
  return status_map[status] || status;
};
export const decrypt_data = (
  cipher_text: string | null,
  salt: string
): string | null => {
  if (!cipher_text) return null;
  const bytes = CryptoJS.AES.decrypt(cipher_text, salt);
  try {
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch {
    return null;
  }
};

// Store item in local storage
export function store_item(key: string, data: any): void {
  const salt = process.env.REACT_APP_SALT || "";
  const value = JSON.stringify(data);
  const encryptedKey = window.btoa(key);
  const encryptedValue = encrypt_data(value, salt);
  window.localStorage.setItem(encryptedKey, encryptedValue);
  window.dispatchEvent(new Event(`${key}`));
}

// Retrieve item from local storage
export function retrieve_item(key: string): any | null {
  const salt = process.env.REACT_APP_SALT || "";
  try {
    const encryptedKey = window.btoa(key);
    const value = window.localStorage.getItem(encryptedKey);
    const decryptedValue = decrypt_data(value, salt);
    return decryptedValue ? JSON.parse(decryptedValue) : null;
  } catch {
    return null;
  }
}

// Clear all items in local storage
export function clear_local_storage(): void {
  try {
    window.localStorage.clear();
  } catch {
    // Handle errors if needed
  }
}

export function get_uuid(): string {
  return uuidv4();
}
export function calculate_total_warehouse(
  variant: number,
  quantity: number,
  type: string
) {
  switch (type) {
    case "piece":
      return {
        qty: quantity,
        type: "pcs",
      };
    case "bunch":
      return {
        qty: quantity,
        type: "bch",
      };
    case "gram":
      if (variant >= 1000) {
        const totalVariant = (variant / 1000).toFixed(2); // Convert to kg
        return {
          qty: totalVariant,
          type: "kg",
        };
      }
      return {
        qty: variant,
        type: "g",
      };
    case "litre":
      return {
        qty: variant,
        type: "L",
      };
    case "millilitre":
      if (variant >= 1000) {
        const totalVariant = (variant / 1000).toFixed(2); // Convert to l
        return {
          qty: totalVariant,
          type: "L",
        };
      }
      return {
        qty: quantity,
        type: "ml",
      };
    case "kilogram":
      return {
        qty: quantity,
        type: "kg",
      };
    default:
      return {
        qty: quantity,
        type: "",
      };
  }
}

export function calculate_total_quantities(
  variant: number,
  quantity: number,
  type: string
) {
  const calculate_weight = calculateWeight(variant, quantity);
  console.log("calculate_weight", calculate_weight, variant, quantity);
  switch (type) {
    case "piece":
      return `${variant * quantity} pcs`;
    case "bunch":
      return `${quantity} bch`;
    case "gram":
    case "kilogram":
      // variant === 1 ? variant : Number(variant || 0) / 1000 || 0;
      if (calculate_weight >= 1000) {
        const totalVariant = Number(calculate_weight / 1000).toFixed(2); // Convert to kg
        return `${totalVariant} kg`;
      } else {
        return `${calculate_weight} gram`;
      }
    case "litre":
    case "millilitre":
      // variant === 1 ? variant : Number(variant || 0) / 1000 || 0;
      if (calculate_weight >= 1000) {
        const totalVariant = Number(calculate_weight / 1000).toFixed(2); // Convert to kg
        return `${totalVariant} L`;
      } else {
        return `${calculate_weight} ml`;
      }
    default:
      return "Invalid type"; // Added to handle unrecognized types
  }
}

export function show_unit(type: string) {
  switch (type) {
    case "piece":
      return "pcs";

    case "bunch":
      return "bch";
    case "gram":
      return "g";

    case "litre":
      return "L";
    case "millilitre":
      return "ml";
    case "kilogram":
      return "kg";
    default:
      return "N/A";
  }
}

export const format_type_text = (value) => {
  return value
    .replace(/-/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const calculate_farmer_and_company_wastage = (
  received_quantity: number,
  company_wastage: number,
  total_wastage: number
) => {
  return {
    company_wastage: Number(received_quantity * 0.05),
    farmer_wastage: Number(company_wastage || 0) - total_wastage,
  };
};
export const calculate_total_wastage = (company_wastage, farmer_wastage) => {
  return Number(company_wastage) + Number(farmer_wastage);
};

export const farmer_order_line_item_billing_quantity_calculation = (
  orderValues?: OrderValues,
  record?: FarmerOrderLineItem
) => {
  const receivedPrimary = Number(
    orderValues?.[record?.id || ""]?.received_quantity || 0
  );
  const wastagePrimary = Number(
    orderValues?.[record?.id || ""]?.farmer_wastage_quantity || 0
  );
  const receivedSecondary = Number(
    orderValues?.[record?.id || ""]?.received_quantity_in_secondary_unit || 0
  );
  const wastageSecondary = Number(
    orderValues?.[record?.id || ""]
      ?.farmer_wastage_quantity_in_secondary_unit || 0
  );

  return {
    primary: receivedPrimary - wastagePrimary,
    secondary: receivedSecondary - wastageSecondary,
    total_price: !record?.product?.secondary_measurement_type
      ? Number(receivedPrimary - wastagePrimary) *
        Number(record?.farmer_product?.price || 0)
      : Number(receivedSecondary - wastageSecondary) *
        Number(record?.farmer_product?.price || 0),
  };
};

export const coupon_validation = (
  coupon: Coupon,
  cart_line_item: CustomerCartLineItem[],
  total_cart_amount: number = 0,
  address?: CustomerAddress
) => {
  const product_ids = coupon?.product_list?.map((product) => product?.id) || [];
  const appliedProducts = cart_line_item.filter((item) =>
    product_ids.includes(item?.product_id || "")
  );
  const zone_list = coupon.zone_group_list?.flatMap(
    (group) => group.zone_list?.map((zone) => zone.id) || []
  );

  const totalAppliedProductAmount =
    appliedProducts?.length > 0
      ? appliedProducts?.reduce(
          (total, item) =>
            total +
            Number(item?.product_variant?.price || 0) * Number(item?.quantity),
          0
        )
      : 0;
  const selected_delivery_location = retrieve_item("get_location_details");
  if (coupon?.type === "product") {
    if (appliedProducts?.length > 0) {
      if (
        coupon?.discount_type &&
        zone_list?.includes(selected_delivery_location?.id)
      ) {
        if (totalAppliedProductAmount >= Number(coupon?.minimum_amount)) {
          return {
            product: appliedProducts,
            coupon,
            status: "success",
          };
        } else {
          toast.error("Coupon minimum amount not met!");
          return { status: "failed" };
        }
      } else {
        toast.error("This Delivery location not applicable this coupon");
        return { status: "failed" };
      }
    } else {
      toast.error("Invalid Coupon !");
      return { status: "failed" };
    }
  } else if (coupon?.type === "cart") {
    if (
      coupon?.discount_type
      //  &&
      // coupon.discount_type==="chennai"
    ) {
      if (total_cart_amount >= Number(coupon?.minimum_amount)) {
        return {
          product: null,
          coupon,
          status: "success",
        };
      } else {
        toast.error("Coupon minimum amount not met!");
        return { status: "failed" };
      }
    } else {
      toast.error("this Delivery location not applicable this coupon");
      return { status: "failed" };
    }
  } else if (coupon.type === "shipping") {
    if (coupon.district_type === "outside_chennai") {
      if (
        total_cart_amount >= Number(coupon?.minimum_amount) &&
        !upperCase(selected_delivery_location?.district?.name)?.includes(
          "CHENNAI"
        )
      ) {
        return {
          product: appliedProducts,
          coupon,
          status: "success",
        };
      } else if (
        upperCase(selected_delivery_location?.district?.name)?.includes(
          "CHENNAI"
        )
      ) {
        toast.error("This delivery location is not applicable for this coupon");
        return { status: "failed" };
      } else {
        toast.error("Coupon minimum amount not met!");
        return { status: "failed" };
      }
    }
    toast.error("Coupon minimum amount not met!");
    return { status: "failed" };
  }
};

export const disable_date = (current: Moment | null): boolean => {
  return !!(current && current < moment().startOf("day"));
};

export const measurement_type: Record<string, string> = {
  piece: "pcs",
  bunch: "bch",
  gram: "g",
  kilogram: "kg",
  litre: "L",
  millilitre: "ml",
  "": "",
};

export const delivery_date_format = (
  date: string | Date | undefined
): string => {
  if (!date) return "";
  return moment(new Date(date)).format("DD MMM, dddd");
};

export const format_date = (date: string | Date | undefined): string => {
  if (!date) return "";
  return moment(new Date(date)).format("DD MMM, YYYY");
};

export const delivery_date_disable_condition = (
  current: any,
  validDates: String[] | undefined
): boolean => {
  const today = moment().startOf("day");
  const formatted_date = moment(new Date(current)).format("YYYY-MM-DD");
  return (
    moment(new Date(current)).isBefore(today) ||
    !validDates?.includes(formatted_date)
  );
};
export const disabled_date = (current: dayjs.Dayjs, filter_date) => {
  const formattedDate = current.format("YYYY-MM-DD");
  return !filter_date.includes(formattedDate); // Disable if the date is not in filter_date
};
export function indian_currency_format(
  amount: number = 0,
  show_zero: boolean = true
): string {
  if (amount > 0) {
    let amountStr = amount.toFixed(2);
    let [integerPart, fractionalPart] = amountStr.split(".");

    let lastThreeDigits = integerPart.slice(-3);
    let otherDigits = integerPart.slice(0, -3);
    if (otherDigits) {
      lastThreeDigits = "," + lastThreeDigits;
    }
    otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    let formattedInt = otherDigits + lastThreeDigits;

    if (show_zero) {
      return (
        "₹" +
        formattedInt +
        (fractionalPart ? "." + fractionalPart : show_zero ? ".00" : "")
      );
    } else {
      return "₹" + formattedInt;
    }
  } else return "";
}
export const format_indian_currency = (amount: number = 0): string => {
  if (amount <= 0) return "";

  const [integerPart, fractionalPart] = amount.toFixed(2).split(".");
  const formattedInt =
    Number(integerPart) < 10000
      ? integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : integerPart.replace(/\B(?=(\d{2})+(?!\d))/g, ".");

  return fractionalPart !== "00"
    ? `₹${formattedInt}.${fractionalPart}`
    : `₹${formattedInt}`;
};

export function indian_currency_format_pdf(
  amount: number = 0,
  show_zero: boolean = true
): string {
  if (!show_zero && amount === 0) return "";

  // Convert the amount to a string with two decimal places
  let amountStr = amount.toFixed(2);
  let [integerPart, fractionalPart] = amountStr.split(".");

  // Manually format using Indian numbering system
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);
  if (otherDigits) {
    lastThreeDigits = "," + lastThreeDigits;
  }
  otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");

  const formattedAmount = otherDigits + lastThreeDigits + "." + fractionalPart;

  // Manually add the currency symbol to avoid issues with React-PDF
  return "Rs. " + formattedAmount;
}

export function count_format(amount: number = 0): string {
  let amountStr = amount.toFixed(2);
  let [integerPart, fractionalPart] = amountStr.split(".");

  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);
  if (otherDigits) {
    lastThreeDigits = "," + lastThreeDigits;
  }
  otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  let formattedInt = otherDigits + lastThreeDigits;

  return fractionalPart ? formattedInt + "." + fractionalPart : formattedInt;
}
export function count_format_without_zero(amount: number = 0): string {
  let amountStr = amount.toFixed(2);
  let [integerPart, fractionalPart] = amountStr.split(".");

  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);
  if (otherDigits) {
    lastThreeDigits = "," + lastThreeDigits;
  }
  otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  let formattedInt = otherDigits + lastThreeDigits;

  return formattedInt;
}

export const product_variant_unit: Record<string, string> = {
  piece: "pcs",
  bunch: "bch",
  gram: "g",
  kilogram: "kg",
  litre: "l",
  millilitre: "ml",
};

export const pickup_building_address: Record<string, string> = {
  Independent: "independent",
  Apartment: "apartment",
};

export const logistics_staff_role: Record<string, string> = {
  PackingTeam: "packing team",
  Driver: "driver",
};
export var pluralize = require("pluralize");
export const bag_options = [
  {
    label: "Plastic Bag",
    value: "Plastic Bag",
  },
  {
    label: "Cloth Bag",
    value: "Cloth Bag",
  },
];

export const tag_status_options = [
  {
    label: "New Customer",
    value: "new_customer",
  },
  {
    label: "Important Customer",
    value: "important_customer",
  },
  {
    label: "Remark Customer",
    value: "remark_customer",
  },
];

export const measurement_type_options = [
  { label: "Piece", value: "piece" },
  { label: "Bunch", value: "bunch" },
  { label: "Gram", value: "gram" },
  { label: "Kilogram", value: "kilogram" },
  { label: "Litre", value: "litre" },
  { label: "Millilitre", value: "millilitre" },
];
export const payment_options = [
  { label: "Cash on delivery", value: "cod" },
  { label: "Pay on Delivery", value: "pay_later" },
  { label: "Online", value: "online" },
];
export const logistic_mode = [
  { label: "Courier", value: "courier" },
  { label: "Public Transport", value: "public_transport" },
];
export const vendor_type = [
  { label: "Farmer", value: "farmer" },
  { label: "Farmer group", value: "farmer_group" },
  { label: "FPO", value: "fpo" },
  { label: "SHG / Vendor", value: "shg_vendor" },
];
export const payment_cycle = [
  { label: "Weekly", value: "weekly" },
  { label: "On Payment", value: "on_payment" },
  { label: "Monthly", value: "monthly" },
  { label: "Advance", value: "advance" },
];
export const preferrer_payment_method = [
  { label: "IMPS", value: "imps" },
  { label: "NEFT", value: "neft" },
];

export const farmer_order_line_item_status = {
  warehouse_update: [
    {
      label: "Received",
      value: "received",
    },
    {
      label: "Partially Received",
      value: "partially_received",
    },
    {
      label: "Not Received",
      value: "not_received",
    },
    {
      label: "Extra Received",
      value: "extra_received",
    },
  ],
  procurement_admin: [
    {
      label: "Cancelled by Firm",
      value: "cancelled_by_firm",
    },
    {
      label: "Cancelled by Farmer",
      value: "cancelled_by_farmer",
    },
  ],
  warehouse_reject: [
    {
      label: "Not Received",
      value: "not_received",
    },
  ],
  procurement_admin_reject: [
    {
      label: "Cancelled by Firm",
      value: "cancelled_by_firm",
    },
    {
      label: "Cancelled by Farmer",
      value: "cancelled_by_farmer",
    },
    {
      label: "Not Received",
      value: "not_received",
    },
  ],
  location_list: [
    { id: "chennai", name: "Chennai" },
    { id: "outside_chennai", name: "Outside Chennai" },
  ],

  procurement_approve: [
    {
      label: "Paid",
      value: "paid",
    },
    {
      label: "Unpaid",
      value: "unpaid",
    },
  ],
  farmer_order_status: [
    { label: "Demand", value: "demand" },
    { label: "Placed", value: "placed" },
    { label: "Ordered", value: "ordered" },
    { label: "Received", value: "received" },
    { label: "Partially Received", value: "partially_received" },
    { label: "Not Received", value: "not_received" },
    { label: "Extra Received", value: "extra_received" },
    { label: "Cancelled by Firm", value: "cancelled_by_firm" },
    { label: "Cancelled by Farmer", value: "cancelled_by_farmer" },
    { label: "Not Updated", value: "not_updated" },
    { label: "Waste Updated", value: "waste_updated" },
    { label: "Reconciled", value: "reconciled" },
    { label: "Paid", value: "paid" },
    { label: "Unpaid", value: "unpaid" },
    { label: "Po Generated", value: "po_generated" },
  ],
};

export const status_container = {
  farmer_order_status: [
    { label: "Placed", value: "placed" },
    { label: "Ordered", value: "ordered" },
    { label: "Received", value: "received" },
    { label: "Partially Received", value: "partially_received" },
    { label: "Not Received", value: "not_received" },
    { label: "Extra Received", value: "extra_received" },
    { label: "Cancelled by Firm", value: "cancelled_by_firm" },
    { label: "Cancelled by Farmer", value: "cancelled_by_farmer" },
    { label: "Not Updated", value: "not_updated" },
    { label: "Reconciled", value: "reconciled" },
    { label: "Paid", value: "paid" },
    { label: "Unpaid", value: "unpaid" },
    { label: "Po Generated", value: "po_generated" },
    { label: "Rescheduled", value: "rescheduled" },
  ],
  farmer_order_wastage_status: [
    { label: "Not Updated", value: "not_updated" },
    { label: "Waste Updated", value: "waste_updated" },
  ],
  customer_order_status: [
    { label: "Not Packed", value: "not_packed" },
    { label: "Picked", value: "picked" },
    { label: "Not Picked", value: "not_picked" },
    { label: "Packed", value: "packed" },
  ],
  customer_order_return_status: [
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
    {
      label: "Returned",
      value: "returned",
    },
    {
      label: "Closed",
      value: "closed",
    },
  ],
};
export const determine_order_status = (
  wastage_quantity: number,
  received_quantity: number,
  ordered_quantity: number
) => {
  if (received_quantity < ordered_quantity) {
    if (received_quantity - wastage_quantity > 0) {
      return "partially_received"; // Received but less than ordered
    } else {
      return "not_received"; // No goods received or only wastage
    }
  } else if (
    (received_quantity === ordered_quantity && wastage_quantity === 0) ||
    received_quantity === ordered_quantity
  ) {
    return "received"; // no wastage
  } else if (received_quantity > ordered_quantity) {
    return "extra_received"; // More received than ordered
  }
};

export const customer_orders_payment_export = (
  orders: CustomerOrderPayment[]
) => {
  console.log("orders", orders);

  let serialNumber = 1;

  const data = orders.map((order) => {
    return {
      S_NO: serialNumber++,
      "Order Reference": order?.customer_order?.order_reference,
      "Customer Name": order?.customer_order?.customer?.name,
      "Date & Time": moment(order.created_datetime).format(
        "DD-MMM-YYYY HH:mm:ss"
      ),
      Amount: order.amount,
    };
  });

  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

  XLSX.writeFile(
    workbook,
    `CustomerOrdersPayment-${moment().format("DD-MMM-YYYY")}.xlsx`
  );
};

export const customer_orders_export = (orders: CustomerOrder[]) => {
  let serialNumber = 1; // Initialize a global serial counter

  const data = orders
    .map((order) => {
      const {
        customer,
        order_line_item_list,
        ordered_datetime,
        order_reference,
        customer_address,
      } = order;

      // Assuming customer name is "First Last" format
      const name = customer?.name;

      return order_line_item_list?.map((item) => {
        const row = {
          S_NO: serialNumber++, // Increment serial number correctly
          "Order ID": order_reference,
          Date: moment(ordered_datetime).format("DD-MMM-YYYY"),
          Name: name,
          "Phone Number": customer?.mobile,
          "Email ID": customer?.email, // Assuming no email in the data
          District: item?.customer_order?.customer_address?.district?.name,
          State: item?.customer_order?.customer_address?.state?.name,
          Pincode:
            item?.customer_order?.customer_address?.zone?.pincode ||
            item?.customer_order?.customer_address?.pincode,
          Address:
            (item?.customer_order?.customer_address?.address_line_1 || "") +
            (item?.customer_order?.customer_address?.address_line_2
              ? ", " + item?.customer_order?.customer_address?.address_line_2
              : ""),
          Zone: item?.customer_order?.customer_address?.zone?.name,
          "Zone Group":
            item?.customer_order?.customer_address?.zone?.zone_group_list
              ?.map((e) => e.name)
              .join(", "),
          "Lat Long": item?.customer_order?.customer_address?.lat_lng
            ?.map((item) => item)
            .join(", "),
          Product: item?.product?.name,
          Variant: item?.product_variant?.name,
          Quantity: item?.quantity,
          Rate: item.actual_cost,
          "Product Total": Number(item?.quantity) * Number(item?.actual_cost),
          "Payment Status": "Placed",
          Remarks: order?.customer_remarks,
        };

        return row;
      });
    })
    .flat();

  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

  XLSX.writeFile(
    workbook,
    `CustomerOrders-${moment().format("DD-MMM-YYYY")}.xlsx`
  );
};

export const Bulk_payment_link_export = (orders: CustomerOrder[]) => {
  if (!Array.isArray(orders) || orders.length === 0) {
    console.error("No orders available for export");
    return;
  }
  const filtered_data = orders.filter(
    (order) => Number(order?.final_total_cost || 0) > 0
  );
  const data = filtered_data.map((order, index) => {
    return {
      "S NO": index + 1,
      "Invoice Date": order?.delivery_date?.date
        ? moment(order.delivery_date.date).format("DD-MMM-YYYY")
        : "N/A",
      "Order Reference": order?.order_reference || "-",
      "Total Amount": Number(order?.final_total_cost),
      "Link Method": "whatsapp",
    };
  });

  if (data.length === 0) {
    console.error("No valid data to export");
    return;
  }

  try {
    const worksheet = XLSX.utils.json_to_sheet(data);

    // 🔹 Set column widths
    worksheet["!cols"] = [
      { wch: 6 }, // S NO
      { wch: 15 }, // Order Reference
      { wch: 18 }, // Final Total Cost
      { wch: 15 }, // Delivery Date
      { wch: 10 }, // Delivery Date
    ];

    // 🔹 Style the header row (bold, background color)
    const headerStyle = {
      font: { bold: true, color: { rgb: "FFFFFF" } },
      fill: { fgColor: { rgb: "4F81BD" } }, // Blue background
      alignment: { horizontal: "center", vertical: "center" },
    };

    // Apply styles to headers
    const headerRow = ["A1", "B1", "C1", "D1", "E1"];
    headerRow.forEach((cell) => {
      worksheet[cell] = worksheet[cell] || {}; // Ensure the cell exists
      worksheet[cell].s = headerStyle;
    });

    // 🔹 Apply number format for "Final Total Cost"
    data.forEach((_, index) => {
      const rowNumber = index + 2; // Row starts from 2 (1 is header)
      const costCell = `D${rowNumber}`;
      worksheet[costCell] = worksheet[costCell] || {};
      worksheet[costCell].z = "₹#,##0.00"; // Indian Rupees format
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

    // 🔹 Save the file with current date
    XLSX.writeFile(
      workbook,
      `CustomerOrders-${moment().format("DD-MMM-YYYY")}.xlsx`
    );
  } catch (error) {
    console.error("Excel export failed:", error);
  }
};

export const payment_status = [
  { value: "initiated", label: "Initiated" },
  { value: "cancelled", label: "Cancelled" },
  { value: "success", label: "Success" },
  { value: "failed", label: "Failed" },
  { value: "already_paid", label: "Already Paid" },
];

export const delivery_status = [
  { value: "not_packed", label: "Not Packed" },
  { value: "packed", label: "Packed" },
  { value: "not_picked", label: "Not Picked" },
  { value: "picked", label: "Picked" },
];

export const generatePDF = (
  cart?: CustomerCartLineItem[],
  address?: CustomerAddress,
  invoice_number?: string
) => {
  const toWords = new ToWords();
  const total_amount = cart?.reduce(
    (acc, item) => {
      const quantity = Number(item?.quantity) || 0;
      const price = Number(item?.product_variant?.price) || 0;
      const itemTotal = quantity * price;

      // Calculate GST if there's a percentage defined
      const gstPercentage = Number(item?.product?.gst?.percentage) || 0;
      const gstAmount = itemTotal * (gstPercentage / 100);

      return {
        subtotal: acc.subtotal + itemTotal,
        gst: acc.gst + gstAmount,
      };
    },
    { subtotal: 0, gst: 0 }
  );
  const grandTotal =
    Math.round(
      (Number(total_amount?.subtotal || 0) + Number(total_amount?.gst || 0)) *
        100
    ) / 100;

  const doc = new jsPDF("p", "mm", "a4");

  // Add header image (Logo) - adjust width and height as needed
  const imgWidth = 60; // Adjust the width of the logo
  const imgHeight = 20; // Adjust the height of the logo
  doc.addImage(LOGO_WIDTH_TITLE, "PNG", 14, 10, imgWidth, imgHeight); // Add logo at position (x, y) with size (width, height)
  doc.addFileToVFS("Mukta-Bold.ttf", mukta_bold);
  doc.addFont("Mukta-Bold.ttf", "Mukta", "bold");
  doc.addFileToVFS("Mukta-normal.ttf", mukta_normal);
  doc.addFont("Mukta-normal.ttf", "Mukta", "normal");

  // Add title and other header details
  doc.setFont("Mukta", "bold");
  doc.setFontSize(15);
  doc.text("Invoice # " + invoice_number, 125, 20, { align: "left" }); // Title below the logo
  doc.setFontSize(10);
  doc.text("Invoice Amount", 160, 30, { align: "left" }); // Title below the logo
  doc.setFontSize(13);
  doc.text(
    indian_currency_format(
      Number(total_amount?.subtotal || 0) + Number(total_amount?.gst || 0),
      false
    ),
    170,
    35,
    {
      align: "left",
    }
  ); // Title below the logo

  // Add invoice details
  doc.setFontSize(12);

  doc.text("Bhoominalam myHarvest Farms Private Limited", 14, 50);
  doc.setFont("Mukta", "normal");
  const company_address = doc.splitTextToSize(
    "22A, Subashree Nagar Extension 3, Mugalivakkam, Chennai, Tamil Nadu 600116",
    80
  );
  doc.text(company_address, 14, 57);
  doc.text("GSTIN 33AAHCB8618F1ZR", 14, 68);

  doc.text("Bill To", 14, 80);
  doc.setFont("Mukta", "bold");
  doc.text(`${invoice_number}`, 14, 90);
  doc.setFont("Mukta", "normal");
  doc.text(address?.customer?.name || "", 14, 95);
  const addressText =
    `${address?.address_line_1 ? address?.address_line_1 + "," : ""} ${
      address?.address_line_2 ? address?.address_line_2 + "," : ""
    } ${address?.zone?.name ? address?.zone?.name + "," : ""} ${
      address?.district?.name ? address?.district?.name + "," : ""
    } ${address?.state?.name ? address?.state?.name + "," : ""}- ${
      address?.zone?.pincode ? address?.zone?.pincode + "," : ""
    }` || "-";

  const maxWidth = 80; // Set the width you want for the text wrapping
  const wrappedText = doc.splitTextToSize(addressText, maxWidth);
  doc.text(wrappedText, 14, 100);
  doc.setFontSize(10);
  doc.text("Invoice Date :", 150, 100);
  doc.text("  " + moment().format("DD/MM/YYYY"), 170, 100);
  doc.text("Due Date      :", 150, 106);
  doc.text("  " + moment().format("DD/MM/YYYY"), 170, 106);
  // Add table for items
  (doc as any).autoTable({
    startY: 115,
    head: [["#", "Item", "Packed Qty", "Unit Rate", "GST", "Billed Amount"]],
    body: cart?.map((line_item, index) => {
      const gst_amount = line_item?.product?.gst?.percentage
        ? Number(
            Number(line_item?.quantity) *
              Number(line_item?.product_variant?.price)
          ) *
          (Number(line_item?.product?.gst?.percentage) / 100)
        : 0;
      return [
        index + 1,
        line_item?.product?.name,
        count_format(line_item?.quantity),
        count_format(line_item?.product_variant?.price),
        `${line_item?.product?.gst?.percentage} %`,
        count_format(
          Number(line_item?.quantity) *
            Number(line_item?.product_variant?.price) +
            gst_amount
        ),
      ];
    }),
    theme: "grid",
    styles: {
      fontSize: 10,
      textColor: "black", // Set body text color to black
      lineWidth: 0.5, // Set the line width for borders
      lineColor: "black", // Set the border color to black
      cellPadding: 3.5, // Set cell padding (roughly 10px in mm)
    },
    headStyles: {
      fillColor: "#30ad63", // Header background color
      textColor: "white", // Header text color (optional)
      lineWidth: 0.5, // Header border width
      lineColor: "white", // Header border color
      cellPadding: 3.5, // Set cell padding (roughly 10px in mm)
    },
    columnStyles: {
      0: { cellWidth: 10 }, // Item #
      1: { cellWidth: 70 }, // Item Name
      2: { cellWidth: 20 }, // Quantity
      3: { cellWidth: 30 }, // Unit Price
      4: { cellWidth: 30 }, // Unit Price
      5: { cellWidth: 30 }, // Unit Price
      6: { cellWidth: 35, halign: "right" }, // Right-align Billed Amount column
    },
    didParseCell: function (data) {
      // Apply bottom border to all cells
      if (data.row.section === "body" || data.row.section === "head") {
        data.cell.styles.lineWidth = {
          top: 0,
          right: 0,
          bottom: 0.5,
          left: 0,
        };
        data.cell.styles.lineColor = "black"; // Bottom border color
      }
    },
    didDrawPage: (data: any) => {
      // Add the footer on each page
      let pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      let footerY = pageHeight - 10;
      doc.setFont("Mukta", "normal");
      doc.text(
        "Kindly pay the bills within 2 days maximum to help us pay to our farmers without delay.",
        14,
        footerY - 35
      );
      doc.text(
        "Thanks for supporting myHarvest Farmers. Continue to Eat Safe Food.",
        14,
        footerY - 30
      );
      doc.setFont("Mukta", "normal");
      doc.text(
        "For NEFT/IMPS: Bhoominalam myHarvest Farms Private Limited",
        14,
        footerY - 20
      );
      doc.text(
        "Account Number: 10023278921 , Account Type: Current Account",
        14,
        footerY - 15
      );
      doc.text(
        "Bank: IDFC Bank , IFSC Code: IDFB0080102, Branch: Nungambakkam",
        14,
        footerY - 10
      );

      // Add Payment details to the footer
      doc.setDrawColor(128, 128, 128); // RGB value for gray

      // Optional: Add a gray line if you need a divider above the footer
      doc.line(14, footerY - 5, 196, footerY - 5);
      doc.setTextColor(128, 128, 128); // RGB value for gray

      doc.text(
        "Together let's create Healthy Families, Happier Farmers & a Sustainable Future.",
        14,
        footerY
      );
      doc.text(Number(data?.pageNumber || 1).toString(), 190, footerY);
    },
  });

  // Add total amount
  doc.text("Rounding", 160, (doc as any).lastAutoTable.finalY + 10, {
    align: "left",
  });

  doc.text(grandTotal.toFixed(2), 192, (doc as any).lastAutoTable.finalY + 10, {
    align: "left",
  });

  doc.setFont("Mukta", "bold");
  doc.setFontSize(10);
  doc.text("Total", 160, (doc as any).lastAutoTable.finalY + 17, {
    align: "left",
  });
  doc.text(
    indian_currency_format(
      Number(total_amount?.subtotal || 0) + Number(total_amount?.gst || 0)
    ),
    180,
    (doc as any).lastAutoTable.finalY + 17,
    {
      align: "left",
    }
  );
  doc.setFont("Mukta", "normal");
  doc.text("Total In Words : ", 130, (doc as any).lastAutoTable.finalY + 25, {
    align: "left",
  });
  const amount_towards_text = doc.splitTextToSize(
    toWords
      .convert(
        Number(total_amount?.subtotal || 0) + Number(total_amount?.gst || 0)
      )
      .toString(),
    50
  );
  doc.setFont("Mukta", "bold");
  doc.text(amount_towards_text, 155, (doc as any).lastAutoTable.finalY + 25, {
    align: "left",
  });

  return doc.output("blob");
  // return doc.save("blob.pdf");
};
export const get_stock_color = (
  remaining: number = 0
): { background: string; color: string } => {
  if (remaining == 0) {
    return { background: "white", color: "black" };
  } else if (remaining <= 10) {
    return { background: "#ff000085", color: "white" };
  } else if (remaining <= 30) {
    return { background: "#ffa5009e", color: "white" };
  } else {
    return { background: "#0080008c", color: "white" };
  }
};

function current_date_time() {
  const now = new Date();
  return now.toISOString().replace(/[:.]/g, "-").slice(0, 19);
}

export async function export_to_excel(data: any[], file_name: string) {
  if (!data || data.length === 0) return;

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  const headerKeys = Object.keys(data[0]);

  // Define header style
  const headerStyle = {
    font: { bold: true, color: { argb: "FFFFFFFF" } },
    fill: { type: "pattern", pattern: "solid", fgColor: { argb: "007D00" } },
    alignment: { horizontal: "center", vertical: "middle" },
  };

  // Define data row style
  const dataStyle = {
    alignment: { horizontal: "left", vertical: "middle" },
  };

  // Add headers with styles
  worksheet.addRow(headerKeys);
  const headerRow = worksheet.getRow(1);
  headerRow.eachCell((cell) => {
    Object.assign(cell, headerStyle);
  });

  // Add data rows with styles
  data.forEach((row) => {
    const rowData = headerKeys.map((key) => row[key] || "");
    const newRow = worksheet.addRow(rowData);
    newRow.eachCell((cell) => {
      Object.assign(cell, dataStyle);
    });
  });

  // Set column widths dynamically
  worksheet.columns = headerKeys.map((key) => ({
    header: key,
    key: key,
    width: 20,
  }));

  // Generate Excel file and trigger download
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(
    new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
    `${file_name}-${current_date_time()}.xlsx`
  );
}
export const generate_wastage_pdf = (
  farmer_order_product_list?: FarmerOrderLineItemList,
  address?: string,
  reference_number?: FarmerOrder
) => {
  const toWords = new ToWords();
  const total_amount = farmer_order_product_list?.items?.reduce(
    (acc, item) => {
      const quantity = Number(item?.quantity) || 0;
      const price = Number(item?.farmer_product?.price) || 0;
      const itemTotal = quantity * price;

      const gstPercentage =
        Number(item?.farmer_product?.product?.gst?.percentage) || 0;
      const gstAmount = itemTotal * (gstPercentage / 100);

      return {
        subtotal: acc.subtotal + itemTotal,
        gst: acc.gst + gstAmount,
      };
    },
    { subtotal: 0, gst: 0 }
  );
  const grandTotal =
    Math.round(
      (Number(total_amount?.subtotal || 0) + Number(total_amount?.gst || 0)) *
        100
    ) / 100;

  const name =
    farmer_order_product_list?.items?.[0]?.farmer_product?.product?.name;
  const variant =
    farmer_order_product_list?.items?.[0]?.farmer_product?.product_variant
      ?.name || "";

  const doc = new jsPDF("p", "mm", "a4");

  const imgWidth = 60;
  const imgHeight = 20;
  doc.addImage(LOGO_WIDTH_TITLE, "PNG", 14, 10, imgWidth, imgHeight);
  doc.addFileToVFS("Mukta-Bold.ttf", mukta_bold);
  doc.addFont("Mukta-Bold.ttf", "Mukta", "bold");
  doc.addFileToVFS("Mukta-normal.ttf", mukta_normal);
  doc.addFont("Mukta-normal.ttf", "Mukta", "normal");

  doc.setFont("Mukta", "bold");
  doc.setFontSize(15);
  doc.text("# " + reference_number, 125, 20, { align: "left" });
  doc.setFontSize(10);

  doc.setFontSize(12);

  doc.text("Bhoominalam myHarvest Farms Private Limited", 14, 50);
  doc.setFont("Mukta", "normal");
  const company_address = doc.splitTextToSize(
    "22A, Subashree Nagar Extension 3, Mugalivakkam, Chennai, Tamil Nadu 600116",
    80
  );
  doc.text(company_address, 14, 57);
  doc.text("GSTIN 33AAHCB8618F1ZR", 14, 68);

  doc.text("Bill To", 14, 80);
  doc.setFont("Mukta", "bold");
  doc.setFont("Mukta", "normal");
  const addressText = `${address ? address : "-"}`;

  const maxWidth = 80;
  const wrappedText = doc.splitTextToSize(addressText, maxWidth);
  doc.text(wrappedText, 14, 100);
  doc.setFontSize(10);
  doc.text("Date      :", 150, 106);
  doc.text("  " + moment().format("DD/MM/YYYY"), 170, 106);
  let y = 120;
  farmer_order_product_list?.items?.forEach((item) => {
    const name = item?.farmer_product?.product?.name;
    const price = item?.farmer_product?.price;
    const farmerWastage = item?.farmer_wastage_quantity;
    const companyWastage = item?.company_wastage_quantity;
    const ordered_quantity = item?.ordered_quantity;
    const final_quantity = item?.quantity;
    const billing_quantity = item?.billing_quantity;
    const received_quantity = item?.received_quantity;
    const totalAmount =
      Number(item?.farmer_product?.price) * Number(item.received_quantity);

    (doc as any).autoTable({
      startY: y,
      head: [["#", `${name}`, "Wastage", "Amount"]],
      body: [
        [
          "",
          "Farmer Wastage",
          farmerWastage,
          (farmerWastage || 0) * (item?.farmer_product?.price || 0),
        ],
        [
          "",
          "Company Wastage",
          companyWastage,
          (companyWastage || 0) * (item?.farmer_product?.price || 0),
        ],
        [
          "",
          "Ordered Quantity",
          ordered_quantity,
          Number(ordered_quantity) * Number(price),
        ],
        [
          "",
          "Recieved Quantity",
          received_quantity,
          Number(received_quantity) * Number(price),
        ],
        [
          "",
          "Final Quantity",
          final_quantity,
          Number(final_quantity) * Number(price),
        ],
        [
          "",
          "Billing Quantity",
          billing_quantity,
          Number(billing_quantity) * Number(price),
        ],

        ["", "Final Cost", "", totalAmount],
      ],
      theme: "grid",
      styles: {
        fontSize: 10,
        textColor: "black",
        lineWidth: 0.5,
        lineColor: "black",
        cellPadding: 3.5,
      },
      headStyles: {
        fillColor: "#30ad63",
        textColor: "white",
        lineWidth: 0.5,
        lineColor: "white",
        cellPadding: 3.5,
      },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 70 },
        2: { cellWidth: 40 },
        3: { cellWidth: 40 },
      },
      didParseCell: function (data) {
        if (data.row.section === "body" || data.row.section === "head") {
          data.cell.styles.lineWidth = {
            top: 0,
            right: 0,
            bottom: 0.5,
            left: 0,
          };
          data.cell.styles.lineColor = "black";
        }
      },
      didDrawPage: (data) => {
        let pageHeight =
          doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        let footerY = pageHeight - 10;
        doc.setFont("Mukta", "normal");
        doc.setDrawColor(128, 128, 128);
        doc.line(14, footerY - 5, 196, footerY - 5);
        doc.setTextColor(128, 128, 128);
        doc.text(
          "Together let's create Healthy Families, Happier Farmers & a Sustainable Future.",
          14,
          footerY
        );
        doc.text(Number(data?.pageNumber || 1).toString(), 190, footerY);
      },
    });
    y = y + 100;
  });
  const wastage_image_list =
    farmer_order_product_list?.items?.[0]?.wastage_image_list || [];
  if (wastage_image_list.length > 0) {
    doc.addPage();
    doc.setFont("Mukta", "bold");
    doc.setFontSize(14);
    doc.text("Wastage Images", 14, 20);

    const imageWidth = 180;
    let currentY = 30;
    wastage_image_list.forEach((imageUrl, index) => {
      doc.addImage(imageUrl, "JPEG", 14, currentY, imageWidth, 60);
      currentY += 70;
      if (currentY > doc.internal.pageSize.height - 40) {
        doc.addPage();
        currentY = 20;
      }
    });
  }
  return doc.output("blob");
};

export interface settings_input {
  show_product_image: boolean;
}

export const rating_info: string[] = [
  "Terrible",
  "Bad",
  "Normal",
  "Good",
  "Wonderful",
];

export const find_path = (
  routes: any[],
  targetKey: string,
  parentPath: string = ""
): string | null => {
  for (const route of routes) {
    const fullPath = `${parentPath}/${route.path}`.replace(/\/+/g, "/");

    if (route?.name === targetKey) {
      return fullPath;
    }

    if (route.children) {
      const childPath = find_path(route.children, targetKey, fullPath);
      if (childPath) {
        return childPath;
      }
    }
  }
  return null;
};

// helpers/shareHelpers.ts

export function isMobileDevice(): boolean {
  return /Android|iPhone/i.test(navigator.userAgent);
}

export function getShareLink(
  platform: "whatsapp" | "facebook" | "instagram",
  title: string,
  shareUrl: string,
  product: Product
): string {
  const encodedTitle = encodeURIComponent(title);
  const encodedUrl = encodeURIComponent(shareUrl);

  switch (platform) {
    case "whatsapp":
      return isMobileDevice()
        ? `whatsapp://send?text=${encodedTitle}%0A${encodedUrl}`
        : `https://web.whatsapp.com/send?text=${encodedTitle}%0A${encodedUrl}`;

    case "facebook":
      return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&quote=${encodedTitle}`;

    case "instagram":
      return `https://www.instagram.com/?url=${encodedUrl}`;

    default:
      return "#";
  }
}

export const available_day_options = [
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
  { label: "Sunday", value: "sunday" },
];

export const handle_calculate_subtotal = (
  cart_items: CustomerCartLineItem[]
): {
  total: number;
  min_total: number;
  max_total: number;
} => {
  const calculate_total = (
    items: CustomerCartLineItem[]
  ): { total: number; min_total: number; max_total: number } => {
    return items.reduce(
      (
        acc: { total: number; min_total: number; max_total: number },
        item: CustomerCartLineItem
      ) => {
        const base_price =
          Number(item?.quantity || 0) *
          Number(item?.product_variant?.price || 0);

        const common_factor = item?.product_variant?.common_factor || 0;
        const discount_percentage =
          item?.product?.discount_list?.[0]?.percentage || 0;
        const gst_percentage = item?.product?.gst?.percentage || 0;
        const discount_amount = base_price * (1 - discount_percentage / 100);
        // Calculate min and max prices based on common factor
        const min_price = base_price * (1 - common_factor / 100);
        const max_price = base_price * (1 + common_factor / 100);

        // Determine total amount after discount (if any)
        const total_amount =
          discount_percentage > 0 ? discount_amount : base_price;

        // Calculate GST amounts
        const total_gst_amount = total_amount * (gst_percentage / 100);
        const min_gst_amount = min_price * (gst_percentage / 100);
        const max_gst_amount = max_price * (gst_percentage / 100);

        // Update totals, excluding GST
        acc.total += total_amount - total_gst_amount;
        acc.min_total += min_price - min_gst_amount;
        acc.max_total += max_price - max_gst_amount;

        return acc;
      },
      { total: 0, min_total: 0, max_total: 0 }
    );
  };

  return cart_items && cart_items.length > 0
    ? calculate_total(cart_items)
    : { total: 0, min_total: 0, max_total: 0 };
};

export const handle_calculate_gst = (cart_items: CustomerCartLineItem[]) => {
  return cart_items?.length > 0
    ? cart_items.reduce((total, item) => {
        const product_total_amount = Number(
          Number(item?.product_variant?.price || 0) *
            Number(item?.quantity || 0)
        );
        const item_total = item?.product?.discount_list?.[0]?.percentage
          ? product_total_amount *
            (1 - item.product.discount_list[0].percentage / 100)
          : product_total_amount;
        const gst_amount = item?.product?.gst?.percentage
          ? item_total * (Number(item?.product?.gst.percentage) / 100)
          : 0;
        return total + gst_amount;
      }, 0)
    : 0;
};

export const handle_calculate_order_subtotal = (
  cart_items: CustomerCartLineItem[]
): {
  total: number;
  min_total: number;
  max_total: number;
} => {
  const calculate_total = (
    items: CustomerCartLineItem[]
  ): { total: number; min_total: number; max_total: number } => {
    return items.reduce(
      (
        acc: { total: number; min_total: number; max_total: number },
        item: any
      ) => {
        const base_price =
          // Number(item?.quantity || 0) *
          Number(item?.actual_cost_without_gst || 0);
        const common_factor = item?.common_factor || 0;
        // Calculate min and max prices based on common factor
        const min_price = base_price * (1 - common_factor / 100);
        const max_price = base_price * (1 + common_factor / 100);

        // Determine total amount after discount (if any)
        const total_amount = base_price;

        // Update totals, excluding GST
        acc.total += total_amount;
        acc.min_total += min_price;
        acc.max_total += max_price;

        return acc;
      },
      { total: 0, min_total: 0, max_total: 0 }
    );
  };

  return cart_items && cart_items.length > 0
    ? calculate_total(cart_items)
    : { total: 0, min_total: 0, max_total: 0 };
};

export const handle_calculate_order_gst = (cart_items: any[]) => {
  return cart_items?.length > 0
    ? cart_items.reduce((total, item) => {
        const product_total_amount = Number(
          Number(item?.actual_cost || 0) * Number(item?.quantity || 0)
        );
        const item_total = item?.discount_percentage
          ? product_total_amount * (1 - item?.discount_percentage / 100)
          : product_total_amount;
        const gst_amount = item?.product_variant?.product?.gst?.percentage
          ? item_total *
            (Number(item?.product_variant?.product?.gst.percentage) / 100)
          : 0;
        return total + gst_amount;
      }, 0)
    : 0;
};

export const calculateWeight = (weight, quantity, base = 1000) => {
  return quantity * weight;
};

export const prefil_date = (date: any) => {
  return date
    ? dayjs(date).isValid()
      ? dayjs(date)
          .utc() // Convert to UTC first
          .add(5, "hour") // Add 5 hours
          .add(30, "minute") // Add 30 minutes
          .tz("Asia/Kolkata", true) // Convert to IST correctly
      : undefined
    : undefined;
};

export const handle_logout_un_authorize = async () => {
  window.localStorage.clear();
  showToastOnce("You were not authorized to proceed further");
  if (window.location.pathname.includes("admin")) {
    window.location.href = "/admin";
  } else {
    window.location.href = "/";
  }

  console.log("logout");
};

const displayedMessages = new Set<string>();

export const showToastOnce = (
  message: string,
  options?: Parameters<typeof toast>[1]
) => {
  if (!displayedMessages.has(message)) {
    toast.error(message, options);
    displayedMessages.add(message);

    setTimeout(() => {
      displayedMessages.delete(message);
    }, 3000); // Duration for which the toast is shown
  }
};

export const cancelToastTracking = (message: string) => {
  if (displayedMessages.has(message)) {
    displayedMessages.delete(message);
  }
};
