import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_aggregated_bulk_payment_link_log_list(
    $pagination: pagination_input!
    $filter: bulk_payment_link_log_filter_input!
  ) {
    get_aggregated_bulk_payment_link_log_list(
      pagination: $pagination
      filter: $filter
    ) {
      items {
        reference_id
        invoice_raised_datetime
        member_count
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.GetAggregatedBulkPaymentLinkLogListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_aggregated_bulk_payment_link_log_list_request =
  createAsyncThunk<
    TYPES.GetAggregatedBulkPaymentLinkLogList,
    {
      pagination: TYPES.PaginationInput;
      filter: TYPES.BulkPaymentLinkLogFilterInput;
    },
    { extra: ApolloClient<NormalizedCacheObject> }
  >(
    'bulk_payment_link_log/get_aggregated_bulk_payment_link_log_list',
    async (
      params: {
        pagination: TYPES.PaginationInput;
        filter: TYPES.BulkPaymentLinkLogFilterInput;
      },
      { extra: client },
    ) => {
      try {
        const response = await client.query({
          query: GRAPHQL,
          variables: params,
        });
        return response.data.get_aggregated_bulk_payment_link_log_list;
      } catch (error) {
        const err = error as { message?: String; status_code?: String };
        return Promise.reject({
          message: err.message || 'An error occurred',
          status_code: err.status_code || '500',
        });
      }
    },
  );

const get_aggregated_bulk_payment_link_log_list_slice = createSlice({
  name: 'get_aggregated_bulk_payment_link_log_list',
  initialState: initial_state,
  reducers: {
    get_aggregated_bulk_payment_link_log_list_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        get_aggregated_bulk_payment_link_log_list_request.pending,
        state => {
          state.result = null;
          state.error = null;
          state.loading = true;
        },
      )
      .addCase(
        get_aggregated_bulk_payment_link_log_list_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        get_aggregated_bulk_payment_link_log_list_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: 'Failed to fetch',
            status_code: '500',
          };
          state.loading = false;
        },
      );
  },
});

export const { get_aggregated_bulk_payment_link_log_list_clear } =
  get_aggregated_bulk_payment_link_log_list_slice.actions;

export const get_aggregated_bulk_payment_link_log_list = createSelector(
  (state: RootState) =>
    state.bulk_payment_link_log.get_aggregated_bulk_payment_link_log_list
      .result,
  (state: RootState) =>
    state.bulk_payment_link_log.get_aggregated_bulk_payment_link_log_list.error,
  (state: RootState) =>
    state.bulk_payment_link_log.get_aggregated_bulk_payment_link_log_list
      .loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_aggregated_bulk_payment_link_log_list_slice.reducer;
