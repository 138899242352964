import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  mutation create_estimated_demand($data: create_estimated_demand_input!) {
    create_estimated_demand(data: $data) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result: null,
  error: null,
  loading: false,
};

export const create_estimated_demand_request = createAsyncThunk<
  TYPES.MutationResponse,
  { data: TYPES.CreateEstimatedDemandInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'estimated_demand/create_estimated_demand',
  async (
    params: { data: TYPES.CreateEstimatedDemandInput },
    { extra: client },
  ) => {
    try {
      const response = await client.mutate({
        mutation: GRAPHQL,
        variables: params,
      });
      return response.data.create_estimated_demand;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const create_estimated_demand_slice = createSlice({
  name: 'create_estimated_demand',
  initialState: initial_state,
  reducers: {
    create_estimated_demand_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(create_estimated_demand_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(create_estimated_demand_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(create_estimated_demand_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { create_estimated_demand_clear } =
  create_estimated_demand_slice.actions;

export const create_estimated_demand = createSelector(
  (state: RootState) => state.estimated_demand.create_estimated_demand.result,
  (state: RootState) => state.estimated_demand.create_estimated_demand.error,
  (state: RootState) => state.estimated_demand.create_estimated_demand.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default create_estimated_demand_slice.reducer;
