import React, { useState } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import SearchBox from "../common/search_box";

interface SubCategoryFilterProps {
  set_modal_open: (open: boolean) => void;
}

const SubCategoryFilter: React.FC<SubCategoryFilterProps> = ({
  set_modal_open,
}) => {
  const [search_value, set_search_value] = useState("");
  const { t } = useTranslation();

  const handle_search_change = () => {};
  const handle_sub_category = () => {
    set_modal_open(true);
  };

  return (
    <div className="sub_category_filter_container">
      <div style={{ width: "250px" }}>
        <SearchBox
          set_search_value={set_search_value}
          onChange={handle_search_change}
        />
      </div>

      <Button
        type="primary"
        className="add-button"
        // loading={export_products_loading}
        onClick={handle_sub_category}
      >
        {t("add_sub_category")}
      </Button>
    </div>
  );
};

export default SubCategoryFilter;
